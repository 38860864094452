/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 */

/**
 * Wireframes.
 */

.with-wireframes {
  #header, #main, #content, #navigation, .region-sidebar-first, .region-sidebar-second, #footer, .region-bottom {
    outline: 1px solid #ccc;
  }
}

.lt-ie8 .with-wireframes {
  #header, #main, #content, #navigation, .region-sidebar-first, .region-sidebar-second, #footer, .region-bottom {
    /* IE6/7 do not support the outline property. */
    border: 1px solid #ccc;
  }
}

/**
 * Accessibility features.
 */

/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */

.element-invisible, .element-focusable {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

#navigation {
  .block-menu .block__title, .block-menu-block .block__title {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
  }
}

/* Turns off the element-invisible effect. */

.element-focusable {
  &:active, &:focus {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
  }
}

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */

#skip-link {
  margin: 0;
  a {
    display: block;
    width: 100%;
    padding: 2px 0 3px 0;
    text-align: center;
    background-color: #666;
    color: #fff;
    &:visited {
      display: block;
      width: 100%;
      padding: 2px 0 3px 0;
      text-align: center;
      background-color: #666;
      color: #fff;
    }
  }
}

/**
 * Branding header.
 */

/* Wrapping link for logo. */

.header__logo {
  float: left;
  /* LTR */
  margin: 0;
  padding: 0;
}

/* Logo image. */

.header__logo-image {
  vertical-align: bottom;
}

/* Wrapper for website name and slogan. */

.header__name-and-slogan {
  float: left;
}

/* The name of the website. */

.header__site-name {
  margin: 0;
  font-size: 2em;
  line-height: 1.5em;
}

/* The link around the name of the website. */

.header__site-link {
  &:link, &:visited {
    color: #000;
    text-decoration: none;
  }
  &:hover, &:focus {
    text-decoration: underline;
  }
}

/* The slogan (or tagline) of a website. */

.header__site-slogan {
  margin: 0;
}

/* The secondary menu (login, etc.) */

.header__secondary-menu {
  float: right;
  /* LTR */
}

/* Wrapper for any blocks placed in the header region. */

.header__region {
  /* Clear the logo. */
  clear: both;
}

/**
 * Navigation bar.
 */

#navigation {
  /* Sometimes you want to prevent overlapping with main div. */
  /* overflow: hidden; */
  .block {
    margin-bottom: 0;
  }
  .links, .menu {
    margin: 0;
    padding: 0;
    text-align: left;
    /* LTR */
  }
  .links li, .menu li {
    /* A simple method to get navigation links to appear in one line. */
    float: left;
    /* LTR */
    padding: 0 10px 0 0;
    /* LTR */
    list-style-type: none;
    list-style-image: none;
  }
}

/* Main menu and secondary menu links and menu block links. */

/**
 * Breadcrumb navigation.
 */

.breadcrumb {
  ol {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

/**
 * Titles.
 */

.page__title, .node__title, .block__title, .comments__title, .comments__form-title, .comment__title {
  /* Comment title. */
  margin: 0;
}

/**
 * Messages.
 */

.messages, .messages--status, .messages--warning, .messages--error {
  margin: 1.5em 0;
  padding: 10px;
  background: #43AC6A;
  color: #fff;
  border: 0;
}

.messages *, .messages--status *, .messages--warning *, .messages--error * {
  color: #fff;
  padding: 0;
  margin: 0;
}

.messages a, .messages--status a, .messages--warning a, .messages--error a {
  color: #fff;
  text-decoration: underline;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */

.messages--error p.error {
  color: #333;
}

/* System status report. */

.ok, .messages--status {
  background-color: #43AC6A;
}

.warning, .messages--warning {
  background-color: #f08a24;
}

.error, .messages--error {
  background-color: #f04124;
}

/**
 * Tabs.
 */

/* Basic positioning styles shared by primary and secondary tabs. */

.tabs-primary, .tabs-secondary {
  overflow: hidden;
  *zoom: 1;
  // background-image: -webkit-gradient(linear, 50% 100%, 50% 0%, color-stop(100%, #bbbbbb), color-stop(100%, transparent));
  // background-image: -webkit-linear-gradient(bottom, #bbbbbb 1px, transparent 1px);
  // background-image: -moz-linear-gradient(bottom, #bbbbbb 1px, transparent 1px);
  // background-image: -o-linear-gradient(bottom, #bbbbbb 1px, transparent 1px);
  // background-image: linear-gradient(bottom, #bbbbbb 1px, transparent 1px);
  background-color: #bbb;
  /* IE 9 and earlier don't understand gradients. */
  list-style: none;
  border-bottom: 1px solid #bbbbbb \0/ie;
  margin: 1.5em 0;
  padding: 0 2px;
  white-space: nowrap;
}

.tabs-primary__tab {
  float: left;
  /* LTR */
  margin: 0 3px;
}

.tabs-secondary__tab {
  float: left;
  /* LTR */
  margin: 0 3px;
  &.is-active {
    float: left;
    /* LTR */
    margin: 0 3px;
  }
}

a {
  &.tabs-primary__tab-link, &.tabs-secondary__tab-link {
    border: 1px solid #e9e9e9;
    border-right: 0;
    border-bottom: 0;
    display: block;
    line-height: 1.5em;
    text-decoration: none;
  }
}

/* Primary tabs. */

.tabs-primary__tab {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  text-shadow: 1px 1px 0 white;
  border: 1px solid #bbbbbb;
  border-bottom-color: transparent;
  /* IE 9 and earlier don't understand gradients. */
  border-bottom: 0 \0/ie;
  &.is-active {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    text-shadow: 1px 1px 0 white;
    border: 1px solid #bbbbbb;
    border-bottom-color: transparent;
    /* IE 9 and earlier don't understand gradients. */
    border-bottom: 0 \0/ie;
    border-bottom-color: white;
  }
}

a.tabs-primary__tab-link {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  color: #333;
  background-color: #dedede;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center;
  &.is-active {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    color: #333;
    background-color: #dedede;
    letter-spacing: 1px;
    padding: 0 1em;
    text-align: center;
  }
  &:hover, &:focus {
    background-color: #e9e9e9;
    border-color: #f2f2f2;
  }
  &:active, &.is-active {
    background-color: transparent;
    *zoom: 1;
    // filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFE9E9E9', endColorstr='#00E9E9E9');
    // background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #e9e9e9), color-stop(100%, rgba(233, 233, 233, 0)));
    // background-image: -webkit-linear-gradient(#e9e9e9, rgba(233, 233, 233, 0));
    // background-image: -moz-linear-gradient(#e9e9e9, rgba(233, 233, 233, 0));
    // background-image: -o-linear-gradient(#e9e9e9, rgba(233, 233, 233, 0));
    // background-image: linear-gradient(#e9e9e9, rgba(233, 233, 233, 0));
    background-color: #e9e9e9;
    border-color: #fff;
  }
}

/* Secondary tabs. */

.tabs-secondary {
  font-size: .9em;
  /* Collapse bottom margin of ul.primary. */
  margin-top: -1.5em;
}

.tabs-secondary__tab {
  margin: 0.75em 3px;
  &.is-active {
    margin: 0.75em 3px;
  }
}

a.tabs-secondary__tab-link {
  -webkit-border-radius: 0.75em;
  -moz-border-radius: 0.75em;
  -ms-border-radius: 0.75em;
  -o-border-radius: 0.75em;
  border-radius: 0.75em;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  text-shadow: 1px 1px 0 white;
  background-color: #f2f2f2;
  color: #666;
  padding: 0 .5em;
  &.is-active {
    -webkit-border-radius: 0.75em;
    -moz-border-radius: 0.75em;
    -ms-border-radius: 0.75em;
    -o-border-radius: 0.75em;
    border-radius: 0.75em;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    text-shadow: 1px 1px 0 white;
    background-color: #f2f2f2;
    color: #666;
    padding: 0 .5em;
  }
  &:hover, &:focus {
    background-color: #dedede;
    border-color: #999;
    color: #333;
  }
  &:active, &.is-active {
    text-shadow: 1px 1px 0 #333333;
    background-color: #666;
    border-color: #000;
    color: #fff;
  }
}

/**
 * Inline styles.
 */

/* List of links generated by theme_links(). */

.inline {
  display: inline;
  padding: 0;
  li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0;
    /* LTR */
  }
}

/* The inline field label used by the Fences module. */

span.field-label {
  padding: 0 1em 0 0;
  /* LTR */
}

/**
 * 'More' links.
 */

.more-link {
  text-align: right;
  /* LTR */
}

.more-help-link {
  text-align: right;
  /* LTR */
  a {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA7UlEQVR42qWTPQqDQBCFcwSPkCNITpAj5AjeIm1uYpkyR7Cy2Mot7OwsBAsRwUKwmOwLGRle3EIy8PyBfZ/z3J2TiPylz8VWWZZpUB40BonRKyizaxkA88MYYiqCEgv4MTvnZJom0VqWRbz3FlJZgLYtqmEY1Lg9r+sKsIXcLSC3AC019H0vqLquLeC5AfiHYSGkcdAJimKIBQiJ4+CO92OAtm0FNc8zOjkMwE5Q63FAtbeg6zpAYvG8BWR7i5qmQYwY4MIHqYhE2DOPQWcGJBQF2XU72ZzyUeZ5GCNt5/hybJgYdAXsq5sOEE/jG6dC5IOqCXTmAAAAAElFTkSuQmCC');
    *background-image: url('../../images/help.png');
    background-position: 0 50%;
    /* LTR */
    background-repeat: no-repeat;
    padding: 1px 0 1px 20px;
    /* LTR */
  }
}

/**
 * Pager.
 */

/* A list of page numbers when more than 1 page of content is available. */

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

.pager-item, .pager-first, .pager-previous, .pager-next, .pager-last, .pager-ellipsis {
  /* The current page's list item. */
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none;
}

.pager-current {
  /* The current page's list item. */
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none;
  font-weight: bold;
}

/**
 * Blocks.
 */

/* Block wrapper. */

.block {}

/**
 * Menus.
 */

.menu__item {
  &.is-leaf {
    list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHBAMAAAA2fErgAAAAD1BMVEX///+/v7+Li4sAAADAwMBFvsw8AAAAAXRSTlMAQObYZgAAAB1JREFUCFtjYAADYwMGBmYVZSDhKAwkFJWhYiAAAB2+Aa/9ugeaAAAAAElFTkSuQmCC');
    *list-style-image: url('../../images/menu-leaf.png');
    list-style-type: square;
  }
  &.is-expanded {
    list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==');
    *list-style-image: url('../../images/menu-expanded.png');
    list-style-type: circle;
  }
  &.is-collapsed {
    list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC');
    /* LTR */
    *list-style-image: url('../../images/menu-collapsed.png');
    /* LTR */
    list-style-type: disc;
  }
}

/* The active item in a Drupal menu. */

.menu a.active {
  color: #000;
}

/**
 * Marker.
 */

/* The 'new' or 'updated' marker. */

.new, .update {
  color: #c00;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
}

/**
 * Unpublished note.
 */

/* The word 'Unpublished' displayed underneath the content. */

.unpublished {
  height: 0;
  overflow: visible;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, 'Arial Narrow', Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  /* A very nice CSS3 property. */
  word-wrap: break-word;
}

.lt-ie8 {
  .node-unpublished > *, .comment-unpublished > * {
    /* Otherwise these elements will appear below the 'Unpublished' text. */
    position: relative;
  }
}

/**
 * Comments.
 */

/* Wrapper for the list of comments and its title. */

.comments {
  margin: 1.5em 0;
}

/* Preview of the comment before submitting new or updated comment. */

.comment-preview {
  /* Drupal core will use a #ffffea background. See #1110842. */
  background-color: #ffffea;
}

/* Wrapper for a single comment. */

.comment {
  /* Comment's permalink wrapper. */
  ul {
    margin: 0;
  }
  .permalink {
    text-transform: uppercase;
    font-size: 75%;
  }
}

/* Nested comments are indented. */

.indented {
  /* Drupal core uses a 25px left margin. */
  margin-left: 30px;
  /* LTR */
}

/**
 * Forms.
 */

/* Wrapper for a form element (or group of form elements) and its label. */

form .form-item {
  margin: 1.5em 0;
}

.block.zoekveld .form-item {
  margin: 0;
}

/* Pack groups of checkboxes and radio buttons closer together. */

.form-checkboxes .form-item, .form-radios .form-item {
  /* Drupal core uses '0.4em 0'. */
  margin: 0;
}

/* Form items in a table. */

tr {
  &.odd .form-item, &.even .form-item {
    margin: 0;
  }
}

/* Highlight the form elements that caused a form submission error. */

.form-item {
  input.error, textarea.error, select.error {
    border: 1px solid #c00;
  }
  .description {
    font-size: 0.85em;
  }
}

/* The descriptive help text (separate from the label). */

.form-type-radio .description, .form-type-checkbox .description {
  margin-left: 2.4em;
}

/* The part of the label that indicates a required field. */

.form-required {
  color: #c00;
}

/* Labels for radios and checkboxes. */

label.option {
  display: inline;
  font-weight: normal;
}

/* Buttons used by contrib modules like Media. */

a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

/* Password confirmation. */

.password-parent, .confirm-parent {
  margin: 0;
}

/* Drupal's default login form block. */

#user-login-form {
  text-align: left;
  /* LTR */
}

/**
 * OpenID
 *
 * The default styling for the OpenID login link seems to assume Garland's
 * styling of list items.
 */

/* OpenID creates a new ul above the login form's links. */

.openid-links {
  /* Position OpenID's ul next to the rest of the links. */
  margin-bottom: 0;
}

/* The 'Log in using OpenID' and 'Cancel OpenID login' links. */

.openid-link, .user-link {
  margin-top: 1.5em;
}

html.js #user-login-form li.openid-link, #user-login-form li.openid-link {
  /* Un-do some of the padding on the ul list. */
  margin-left: -20px;
  /* LTR */
}

#user-login ul {
  margin: 1.5em 0;
}

/**
 * Drupal admin tables.
 */

form {
  th {
    text-align: left;
    /* LTR */
    padding-right: 1em;
    /* LTR */
    border-bottom: 3px solid #ccc;
  }
  tbody {
    border-top: 1px solid #ccc;
  }
  table ul {
    margin: 0;
  }
}

tr {
  &.even, &.odd {
    background-color: #eee;
    border-bottom: 1px solid #ccc;
    padding: 0.1em 0.6em;
  }
  &.even {
    background-color: #fff;
  }
}

.lt-ie8 tr {
  &.even {
    th, td {
      /* IE doesn't display borders on table rows. */
      border-bottom: 1px solid #ccc;
    }
  }
  &.odd {
    th, td {
      /* IE doesn't display borders on table rows. */
      border-bottom: 1px solid #ccc;
    }
  }
}

/* Markup generated by theme_tablesort_indicator(). */

td {
  &.active {
    background-color: #ddd;
  }
  &.checkbox {
    text-align: center;
  }
}

/* Center checkboxes inside table cell. */

th.checkbox {
  text-align: center;
}

/* Drupal core wrongly puts this in system.menus.css. Since we override that, add it back. */

td.menu-disabled {
  background: #ccc;
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */

/* Suggestion list. */

#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */

html.js {
  .collapsible .fieldset-legend {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==');
    *background-image: url('../../images/menu-expanded.png');
    background-position: 5px 65%;
    /* LTR */
    background-repeat: no-repeat;
    padding-left: 15px;
    /* LTR */
  }
  .collapsed .fieldset-legend {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC');
    /* LTR */
    *background-image: url('../../images/menu-collapsed.png');
    /* LTR */
    background-position: 5px 50%;
    /* LTR */
  }
}

.fieldset-legend .summary {
  color: #999;
  font-size: 0.9em;
  margin-left: 0.5em;
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */

tr {
  &.drag {
    background-color: #fffff0;
  }
  &.drag-previous {
    background-color: #ffd;
  }
}

.tabledrag-toggle-weight {
  font-size: 0.9em;
}

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */

tr.selected td {
  background: #ffc;
}

/**
 * Progress bar.
 *
 * @see progress.js
 */

.progress {
  font-weight: bold;
  .bar {
    background: #ccc;
    border-color: #666;
    margin: 0 0.2em;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
  }
  .filled {
    background-color: #0072b9;
    background-image: url('../../images/progress.gif');
  }
}
