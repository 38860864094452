//================================================
//=== PERSONEN ===================================
//================================================

/* OVERVIEW */
.persoon {
  margin: 0 -15px;

  .view-header {
    padding: 0 15px;

    p {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .columns {
    float: left!important;
    &:nth-child(1) {
      .person {
        background: url('../images/teambg1.jpg') no-repeat top center;
        background-size: 100%;
      }
    }
    &:nth-child(2) {
      .person {
        background: url('../images/teambg2.jpg') no-repeat top center;
        background-size: 100%;
      }
    }
    &:nth-child(3) {
      .person {
        background: url('../images/teambg3.jpg') no-repeat top center;
        background-size: 100%;
      }
    }
    &:nth-child(4) {
      .person {
        background: url('../images/teambg4.jpg') no-repeat top center;
        background-size: 100%;
      }
    }
    &:nth-child(5) {
      .person {
        background: url('../images/teambg5.jpg') no-repeat top center;
        background-size: 100%;
      }
    }
  }
  .person{
    min-height: 350px;
    padding: 100px 60px;

    .thumb {
      float: left;
      width: 100%;
      text-align: center;
      img{
        // width: 100%;
        width: inherit;
      }
    }

    .title {
      a{
        color: $blue-light;
        font-size: 18px;
        line-height: 21px;
        padding: 15px ($column-gutter / 2);
        display: block;
        text-align: center;
        font-family: 'black_coffee_shadowbold';
        font-size: 23px;
        color: $blue-dark;
      }
    }
    &:hover{
      .title a{
        color: $red;
      }
    }
  }
  @include breakpoint(large) {
    .person{
      min-height: 270px;
      padding: 60px 40px 30px 40px;
    }
  }
  @include breakpoint(medium) {
    .columns{
      width: 33.333%;
    }
    .person{
      min-height: 250px;
      padding: 50px 40px 30px;
    }
  }
  @include breakpoint(small) {
    .columns{
      width: 100% !important;
      .person {
        background-size: 90% !important;
      }
    }
    .person{
      min-height: 300px;
      padding: 60px 40px 30px;
    }
  }
  @media screen and (max-width: 320px) {
    .columns{
      .person {
        background-size: 100% !important;
      }
    }
  }
}

/* DETAIL */
.personen-detail {
  .profile-img{
    min-height: 1px;
  }
  .person-info{
    min-height: 375px;
    background: url('/sites/all/themes/front/images/about-us_background_slider.jpg') no-repeat;
    background-size: 100%;
    position: relative;

    .inside.panels-flexible-row-inside-first{
      position: absolute;
      top:50px;
      left: 50%;
      min-height: 265px;
      transform: translateX(-50%);
      width: $row-width;
      margin: 0 auto;
      background: url('/sites/default/files/afbeeldingen/slider/person-info-bg.png') no-repeat;
      background-size: 100%;
      padding: 10px;
    }

    .profile-picture{
      background: url('/sites/default/files/afbeeldingen/slider/profile-picture-bg.png') no-repeat;
      background-size: 100%;
      min-height: 250px;
      padding: 75px 40px;
      top:-40px;
      position: absolute;
      width: 100%;
      img{

        width: 100%;
      }
    }
    .persoon{
      &.info{
        margin-top: 20px;
        padding-bottom: 30px;
        padding-right: 30px;
        padding-left: 30px;
        .title{
          font-size: 24px;
          font-family: 'bpreplayregular';
          margin-bottom: 0;
        }
        .department{
          color: $blue-light;
          font-size: 13px;
          font-family: 'bpreplayregular';
          margin-bottom: 15px;
          display: block;
          text-transform: uppercase;
        }
        // p{
        //   font-size: 11px;
        //   line-height: 13px;
        // }
      }
    }
    .social-media-list{
      ul{
        margin: 0 !important;

        .social-media{
          text-align: center;
          margin-top: 20px;
          margin-bottom: 0px !important;
          img{
            width: 30px;
            height: 30px;
          }
        }
      }
      @include breakpoint(medium){
        ul{
          .social-media{
            margin-bottom: 10px !important;
          }
        }
      }
    }

    .contact-info{
      margin-top: 20px;
      text-align: center;
    }
  }
  .contact-info{
    .email,
    .slogan,
    .phonenumber{
      display: block;
      color: $red;
      font-family: "alpine_scriptmedium";
      font-size: 21px;
    }
    .slogan,
    .phonenumber{
      font-size: 14px;
    }
  }
  .persoon{
    margin-top: 50px;
    .person{
      padding:60px 20px;
      min-height: 180px;
      text-align: center;

      img{
        width: auto;
      }
      .title{
        a{
          font-size: 18px;
        }
      }
    }
  }
  @include breakpoint(large) {
    .person-info{
      min-height: 375px;
      background-size: auto;

      .inside.panels-flexible-row-inside-first{
        width: 90%;
        background-size: auto;
      }
      .persoon.info {
        padding-right: 10px;
        .email {
          font-size: 16px;
        }
      }
    }
    .persoon{
      margin-top: 20px;
      .person{
        min-height: 210px;
        padding: 40px 25px 30px;
        .thumb img {
          width: 75%;
        }
        .title a{
          padding: 15px 0;
        }
      }
    }
  }
  @include breakpoint(medium) {
    .person-info{
      min-height: 500px;
      // background: none;
      background-size: auto;
      .columns{
        width: 100% !important;
      }

      .inside.panels-flexible-row-inside-first{
        width: 95%;
        margin: 0 auto;
        padding: 0;
        background: $white;
        background-size: auto;
        top:20px;
      }

      .profile-picture{
        width: 30%;
        position: relative;
        left:50%;
        transform: translateX(-50%);
        padding: 0;
        min-height: 0px;
        top:0px;
        background:none;
      }
      .persoon.info{
        margin-top: 0;
        padding-bottom: 10px;
        text-align: center;
        h1{
          margin-top: 0;
        }
      }

      .contact-info{
        margin-top: 0px;
      }
    }
    .persoon{
      margin-top: 20px;
      .columns{
        width: 33.3333%;
      }
      .person{
        min-height: 250px;
        padding: 50px 25px 30px;
        .title a{
          padding: 15px 0;
        }
      }
    }
  }
  @include breakpoint(small) {
    .persoon{
      margin-top: 20px;
      .person{
        min-height: 250px;
        padding:  50px 25px 30px;
        background-size: 70%;
        .title a{
          padding: 15px 0;
        }
      }
    }
  }
}
