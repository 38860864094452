// entity-paragraphs-item

.entity-paragraphs-item {
  padding: 40px 0;
}

// Afbeeldingen
.paragraphs-item-afbeeldingen {
  .field-item {
    width: 50%;
    float: left;
    padding: 0 20px 20px;

    img {
      float: left;
      width: 100%;
    }
  }
}
