//================================================
////=== FOOTER =====================================
//================================================
.footer {
  margin: 20px 0 0;
  padding: 40px 0 50px;
  border-top: 1px solid #e6edf2;
  background: url('/sites/default/files/afbeeldingen/slider/footer-bg.gif') no-repeat;
  background-size: cover;


  .footerblok {
    vertical-align: bottom;
    display: inline-block;
    float: none;
  }

  .footerblok1{
    width: 13% !important;
    padding: 0 !important;
    p{
      padding-top: 30px;
      text-align: center;
      padding-bottom: 0;
    }
  }
  .footerblok2{
    width: 22% !important;
    p:last-child{
      padding: 0;
    }

    h4{
      font-size: 24px;
      margin-bottom: 0px;
    }
  }
  .footerblok3{
    width: 38%;
    padding: 0 5% 0 10px !important;
    form{margin: 0;}
    .webform-client-form .form-item{
      display: inline-block;
      width: 85%;
      margin-bottom: 0;
      input{
        border-top-left-radius:5px;
        border-bottom-left-radius:5px;
        border: 0px;
      }
    }
    .form-actions{
      display: inline-block;
      width: 15%;
    }
    .webform-client-form .btn, .webform-client-form .form-submit{
      margin: 0;
    }
    .btn, .form-submit{
      padding: 8px 10px;
      font-size: 14px;
    }
    .form-submit{
      border-top-left-radius:0px;
      border-bottom-left-radius:0px;
    }
    h3{
      font-family: 'bpreplaybold';
      margin-bottom: 0;
      font-size: 24px;
    }
    .intro{
      font-size: 24px;
      padding-bottom: 15px !important;
    }

  }
  .footerblok4{
    padding: 0 5px !important;
    h2{
      font-size: 24px;
      margin-bottom: 20px;
    }
    p{
      padding: 0;
    }
    a {
      img {
        @include transition(transform);
      }
      &:hover {
        img {
          transform: rotate(25deg);
        }
      }
    }
  }
  @include breakpoint(large) {
    background-size: cover !important;
    .footerblok1,
    .footerblok2,
    .footerblok3,
    .footerblok4 {
      float: left !important;
      padding: 0 15px !important;
      .columns {
        padding: 0 !important;
      }
    }
    .footerblok1 {
      width: 33.333% !important;
      float: left !important;
    }
    .footerblok3 {
      .webform-client-form .form-item{
        width: calc(100% - 53px);
      }
      .form-actions{
        width: 53px;
      }
    }
    .footerblok2,
    .footerblok3,
    .footerblok4 {
      width: 66.666% !important;
      float: right !important;
    }
  }

  @include breakpoint(medium) {
    background: url('/sites/default/files/afbeeldingen/slider/footer-bg.gif');
    .footerblok{
      padding: 0 $column-gutter !important;
      width: 100% !important;
    }
    .footerblok3{
      .columns{
        padding: 0!important;
      }
    }
  }

  a,
  p {
    color: $white;
    padding-bottom: 2px;
    font-weight: bold;
  }
  a{
    padding: 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
    font-size: 16px;
  }

  ul {
    list-style-position: inside;
    list-style-type: none;

    li {
      color: $white;

      a {
        color: $white;
      }
    }
  }
  //.region-footerblok-1 ul li{float:left; width:50%;}

  //Social Media
  .social-media {
    margin: 0 0 20px;
    float: left;
    width: 100%;

    ul {
      li {
        a {
          float: left;
          margin: 0 0 5px;

          &:before {
            font-family: $icon-font;
            float: left;
            margin-right: 5px;
            font-size: 22px;
            line-height: 22px;
          }

          &.facebook:before {
            content: '\e007';
            color: #3b5998;
          }

          &.twitter:before {
            content: '\e018';
            color: #55acee;
          }

          &.linkedin:before {
            content: '\e005';
            color: #0077b5;
          }

          &.googleplus:before {
            content: '\e008';
            color: #dd4b39;
          }

          &.pinterest:before {
            content: '\e006';
            color: #cc2127;
          }

          &.youtube:before {
            content: '\e043';
            color: #cd201f;
          }

        }
      }
    }
  }
}

//Footer bottom
.footer-bottom {
  padding: 5px 0 10px;

  .block {
    margin: 0;
  }

  ul {
    margin: 7px 0 0;
    list-style-position: inside;

    li {
      list-style-type: none;
      display: inline;
      margin-left: 15px;
      padding-left: 15px;
      border-left: 1px solid #73838f;
      line-height: 10px;
      display: block;
      float: left;

      &:first-child {
        border: 0;
        margin: 0;
        padding: 0;
      }

      a,
      a.active {
        color: $blue-light;
        font-size: 10px;
      }

      a:hover {
        // text-decoration: underline;
      }

    }
  }
}

//Home only
.front .footer {
  border: none;
}

//React logo
span.icon-rnm-react {
  font-size: 20px;
  float: left;
  margin: 0;
}

.react-online {
  color: #73838f;
  font-size: 11px;
  float: right;
}
@include breakpoint(large) {
  .footer{
    background-size: auto;
  }
}

@include breakpoint(medium) {
  .footer {
    h3,
    h4 {
      padding-bottom: 0;
    }

    .region-footerblok-1 ul li {
      width: 100%;
    }

    .footerblok1,
    .footerblok2,
    .footerblok3,
    .footerblok4{
      width: 50%;
    }
    .footerblok3{
      .columns{
        padding: 0;
      }
    }
  }

  .footer-bottom {
    .columns.left {
      float: left;
    }

    .columns.right {
      float: right;
    }

    ul {
      li {
        // float: none;
        padding: 0;
        margin: 0 0 0 20px;
        border: 0;

        a {
          line-height: 24px;
        }
      }
    }
  }

  //React logo
  .react-online-media {
    text-indent: -9999px;
    line-height: 30px;
  }

  span.icon-rnm-react {
    text-indent: 0;
  }
}
@include breakpoint(small) {
  .footer{
    padding-top: 20px;
    padding-bottom: 0;
    .footerblok1{display: none;}
  }

}

/*Standaard styling Drupal overwrite*/
.footer-bottom .menu__item.is-leaf,
.footer-bottom ul li a {
  list-style-image: none;
  list-style-type: none;
}
