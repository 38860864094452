//=================================================
//== MENU =========================================
//=================================================
.scrolled {
  .region-top-menu {
    display: none;
  }
  #logo img {
    width: 80px;
  }
  .hoofdmenu {
    padding-top: 15px;
  }
}
// TOPMENU
.region-top-menu {
  float: right;
  margin: 30px 0 0;

  .block {
    margin: 0;
    float: left;
  }

  ul {
    list-style: none!important;
    margin-bottom: 0;

    li {
      list-style: none!important;
      float: left;

      a {
        color: #333333;
        padding: 0 10px;
        font-size: 10px;
      }
    }
  }

  @include breakpoint(medium){
    display: none;
  }

  .language-switcher-locale-url {
        padding: 7px 15px;
    li {
      a{
        font-size: 16px;
        line-height: 19px;
        &.active {
          color: $blue-light;
        }
      }
    }
  }

}

//html.mm-opened .header {position: absolute!important;}

//HOOFDMENU
.hoofdmenu {
  @include transition(padding);
  z-index: 1;
  position: relative;
  // margin: 20px 0 0;
  background: $white;
  float: right;
  width: 100%;

  ul {
    float: right;
    margin: 0;
    //display:table-row;

    li {
      list-style: none!important;
      //display:table-cell;
      float: left;
      //width: 1%;
      position: relative;

      &:hover ul {
        display: block;
      }

      a {
        display: block;
        float: none;
        color: $text-color;
        font-weight: normal;
        border-right: 0;
        padding: 10px 16px;
        font-size: 15px;
        text-align: center;
        white-space: nowrap;
        text-decoration: none;

        &.active,
        &.is-active-trail {
          color: $red;
        }

        &:hover {
          color: $blue-light;
        }


      }
      &:last-child a{
        padding-right: 0;
      }

      //Submenu
      ul {
        width: auto;
        display: none;
        position: absolute;
        left: 0;
        top: 44px;
        background: $white;

        li {
          display: block;
          float: none;
          width: auto;
          white-space: nowrap;
          border: none;

          a {
            border-radius: 0px!important;
            border: none;
            padding: 10px 15px;
            text-align: center;
            width: 100%;
            text-align: left;

          }
        }
      }
    }
  }
}

.mobile-menu {
  display: none;
}

.mobile-menu-button {
  display: none;
  border: none;
  float: right;
  margin: 20px 0 0;
  line-height: 40px;
  background: $blue-light;
  //padding:10px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  padding: 0;

  &:after {
    content: '@';
    font-family: 'basis';
    font-size: 26px;
    color: $white;
    margin: 0 auto;
    display: inline-block;

  }
}

.mobile-menu-button:hover {
  text-decoration: none;
}

//RESPONSIVE MOBIEL
@include breakpoint(medium) {
  .hoofdmenu {
    display: none;
  }

  //Toon mobile menu button
  .mobile-menu-button {
    display: block;
  }

  .mm-menu.mm-right {
    background: $white!important;
  }

  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    margin: 0 !important;
    border-color: #EFEFEF!important;
  }

  .mm-list > li > a,
  .mm-list > li > span {
    color: #000!important;
    &.active {
      color: $blue-light !important;
    }
  }

  .search-mobile form {
    padding: 0 20px !important;
  }

  .search-mobile input[type='text'] {
    background: rgba(0, 0, 0, 0.15);
    border: none;
    color: $white;
  }

  .search-mobile input[type='submit'] {
    position: absolute;
    top: 5px;
    right: 5px;
    font-family: 'basis';
    background: none;
    color: $white;
    border: none;
    font-size: 24px;
  }

  .mm-list > li > a,
  .mm-list > li > span {
    color: #333;
  }
}
