//================================================
//=== SLIDER =====================================
//================================================
.flexslider,
.flexslider-external {
  margin: 0 0 20px;
  box-shadow: none;
  border:none;
  max-height: 600px;
  overflow: hidden;

  ol,
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    padding-left: 0;
    -webkit-backface-visibility: visible!important;
  }

  .row {
    position: relative;
    margin: 0 auto;
    max-width: $row-width;
  }

  .slides > li {
    background-color: #696969;

    img {
      opacity: .9;
    }
  }

  //Caption
  .flex-caption {
    position: absolute;
    border: none;
    bottom: 30px;
    background: rgba(255, 255, 255,0.8);
    left: 20px;
    font-size: 24px;
    line-height: 32px;
    padding: 10px 20px;
    border-radius: 5px;

    //Caption posities
    &.linksboven {
      left: 20px;
      top: 30px;
      bottom: auto;
    }

    &.rechtsonder {
      right: 20px;
      left: auto;
    }

    &.rechtsboven {
      right: 20px;
      top: 30px;
      bottom: auto;
      left: auto;
    }

    //Caption styling
    .caption-title {
      font-size: 18px;
      line-height: 24px;
    }

    .caption-text {
      font-size: 12px;
      line-height: 16px;
    }
  }

  //Bolletjes navigatie
  .flex-control-nav {
    z-index: 99;
    bottom:20px;

    li {
      margin: 0 4px;

      a {
        width: 20px;
        height: 20px;
        background: #ccc;

        &.flex-active {
          background: #999999;
        }
      }
    }

  }

  // Pijltjes navigatie
  .flex-direction-nav {
    a {
      color: $white;
      top: 0;
      height: 100%;
      margin: 0;
      width: 60px;

      &:before {
        top: 50%;
        position: absolute;
        margin-top: -32px;
      }
    }

    .flex-next {
      right: 0;

      &:before {
        right: 0;
      }
    }

    .flex-prev {
      left: 0;

      &:before {
        left: 0;
      }
    }
  }

  .bg-caption,
  .color-caption {
    display: none;
  }
}

//Tablet staand
@include breakpoint(large) {
  .flexslider {
    .flex-caption {
      font-size: 20px;
      line-height: 20px;
      padding: 15px;
    }
  }
}

//Telefoon
@include breakpoint(medium) {
  .flexslider {
    .flex-caption {
      width: 100%;
      left: auto;
      bottom: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 15px;
      right: auto!important;
      left: auto!important;
      border-radius: 0!important;
    }

    .slides img {
      width: 100%;
      max-width: none;
    }

    .flex-direction-nav a:before {
      top: 70%;
    }
  }
}
