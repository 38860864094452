/*================================================
=== BLOG --=======================================
================================================*/

.home-news{
  padding-bottom: 50px;
  .blog-link:hover{
    .title a{
      color:$red;
    }
    p:after{
      // text-decoration: underline;
    }
  }
  .blog-item{
    border: 1px solid rgba(73, 98, 114, 0.07);
    padding-bottom: 20px;

    .title a{
      color: $blue-light;
      font-size: 24px;
      line-height: 27px;
      padding: 25px;
      display: block;

      &:hover{
        color: $red;
      }
    }
    p{
      font-size: 14px;
      line-height: 21px;
      padding: 0 25px;
      &:after{
        content: ">";
      }
    }
    img{
      width: 100%;
    }
  }

  .six.columns:first-child{
    padding:0 20px 0 0;
  }
  .six.columns:last-child{
    padding:0 0 0 20px;
  }
  @include breakpoint(small){

    .six{
      width: 100%;
    }
    .six.columns:first-child{
      padding:0 0 20px !important;
    }
    .six.columns:last-child{
      padding:0 0 20px !important;
    }
  }
}
.news-items  .three{
  padding-left: 0 !important;
}

@include breakpoint(medium){


  .news-items  .columns{
    width: 100% !important;
    float: left !important;
  }
}

/* Blog overview */
.blog-overview{

  .blog-item {
    overflow: hidden;
    position: relative;
    float: left;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-bottom: 30px;
    z-index: 1;
    .blog-hanger{
      // animation: bounce1 1.5s;
      box-shadow: 2px 2px 10px rgba(73, 98, 114, 0.4);
      margin-top: 80px;
      margin-bottom: 5px;
      padding: 20px 10px 30px;
      position: relative;

      .title,
      .datum,
      .content p,
      .lees-meer a{
        padding-left: 15px;
        padding-right: 15px;
        line-height: 16px;
      }
      .title{
        padding-top: 20px;
      }

      .block-string{
        // animation: bounce2 1.5s;
        position: absolute;
        left:50%;
        transform: translateX(-50%);
        top:-120px;
      }

    }
    &:hover .blog-hanger {
      box-shadow: 1px 1px 2px rgba(73, 98, 114, 0.4);
    }
    &:hover .blog-hanger,
    &.bounce .blog-hanger{
      animation: bounce1 .7s alternate;
      animation-iteration-count: 2;
      z-index: 100;
      .block-string{
        // animation: bounce2 1.5s alternate;
      }


    }

    .title {
      display:inline-block;
      margin-top: 5px;
      padding-top: 10px;
      a{
        color: $blue-light;
        font-size: 18px;
        line-height: 21px;
        display: block;

        &:hover{
          color: $red;
        }
      }
    }
    .datum{
      display: block;
      margin:  5px 0;
      padding: 0 0 10px 0;
      .date-display-single{
        color: $blue-dark;
        font-size: 11px;
      }
    }

    .thumb-persoon {
      float: left;
      position: relative;
      width: 70px;
      height: 70px;
    }
    .lees-meer{
      position: absolute;
      right:25px;
      bottom:25px
    }
    .lees-meer a,
    .node-readmore a {
      // float: right;
      text-decoration: none;
      font-weight: normal;
      color: $gray-light;

      &:hover {
        color: $red;
      }
    }

    .icon-font {
      font-family: 'basis';
      font-size: 15px;
      font-weight: normal;
      float: left;
      padding-right: 5px;
    }
  }

  @media screen and (max-width: 768px){
    .columns{
      width: 50% !important;
      float: left !important;
    }
  }
  @include breakpoint(medium){
    .columns{
      width: 33.3333% !important;
      float: left !important;
    }
    .blog-item{
      padding: 0 !important;
      img{
        text-align: center;
        display: block;
        margin: 0 auto;
      }
    }
  }
  @include breakpoint(small){
    .columns{
      width: 100% !important;
      float: left !important;
    }
  }

}
.np-left{
  padding-left: 0 !important;
}
.news-filter{
  background: url('/sites/default/files/afbeeldingen/slider/news-filter-bg.png') no-repeat;
  background-size: 100%;
  min-height: 175px;
  padding: 10px 40px;
  margin-top: 80px;
  overflow: auto;
  .view-header{
    h3{
      padding-left: 0;
      margin-top: 20px;
      margin-bottom: 10px;
      color: $blue-dark;
      opacity: 1;
    }
  }

  .categorie a{
    display: block;
    color: $gray-dark;
    padding-bottom: 10px;
    &.active{
      font-weight: bold;
    }
  }
}
@include breakpoint(large){
  .news-filter{
    padding: 10px 20px;
    margin-top: 50px;
    background-size: cover;
    background-position: center center;
    h3{
      margin-top: 10px;
    }
  }
}
@include breakpoint(medium){
  .news-filter{
    padding: 10px 20px;
    margin-top: 30px;
    text-align: center;
    display: inline-block;
    width: 100%;
    h3{
      margin-top: 30px;
    }
  }
}

.view-tags a.active {
  background: #333;
  color: $white;
}

.view-categorie a.active {
  font-weight: bold;
}

/* Blog overview met afbeelding*/
.blog-item.has-image {
  .col {
    width: 65%;
    display: inline-block;
  }

  .thumb.col {
    float: left;
    width: 30%;
    margin-right: 5%;
  }
}

/* Blog Detail */

.blog-detail {
  margin: 20px 0 0;

  .blog-item {
    border: none;
    margin: 0 !important;
    padding-bottom: 10px;
    float: left;
    width: 100%;

    h1 {
      margin-top: 0;
    }

    .thumb {
      margin-top: 10px;
      float: left;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .title {
      padding-bottom: 10px;
      border-bottom:  1px solid rgba(73, 98, 114, 0.07);
      margin-bottom: 8px;
      margin-top: 0;

      a {
        font-size: 21px;
        line-height: 25px;
      }
    }

    .content {
      margin-top: 0;
      padding-top: 0;
      border: 0;
    }

    .datum{
      // float: left;
      margin-top: 25px;
      margin-right: 20px;
      color: #999;
    }
  }

  .auteur {
    margin-left: 10px;
  }

  .sharethis-wrapper {
    float: right;

    .st_xing_custom,
    .st_twitter_custom,
    .st_facebook_custom,
    .st_linkedin_custom,
    .st_pinterest_custom{
      @include transition(transform);
      height: 30px;
      width:30px;
      display: inline-block;
      cursor:pointer;

      &:hover {
        transform: rotate(25deg);
      }
    }
    .st_xing_custom{background: url('/sites/default/files/afbeeldingen/content/xing.svg') no-repeat 100%;}
    .st_twitter_custom{background: url('/sites/default/files/afbeeldingen/content/twitter.svg') no-repeat 100%;}
    .st_facebook_custom{background: url('/sites/default/files/afbeeldingen/content/facebook.svg') no-repeat 100%;}
    .st_linkedin_custom{background: url('/sites/default/files/afbeeldingen/content/linkedin.svg') no-repeat 100%;}
    .st_pinterest_custom{background: url('/sites/default/files/afbeeldingen/content/pinterest.svg') no-repeat 100%;}
  }

  .back-to-overview {
    float: left;
    width: 100%;
    border-top: 0;
    padding-top: 10px;
  }
}

/* MASONRY */

.view-blog.view-id-blog.view-display-id-block_5,
.view-id-cases {
  margin: -10px -10px 50px;
}

.view-blog.view-id-blog.view-display-id-block_5 .view-content {}

/*================================================
=== Comments
================================================*/
.comment-form,
.comments,
.comment,
.comment-add {
  display: none!important;
}
