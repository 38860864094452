//================================================
//=== CASES ========================================
//================================================

/* Blog overview */
.case-item {
  float: left;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;

  .col {
    width: 100%;
    float: right;
  }

  .titel {
    padding-bottom: 10px;
    border-top: 1px solid #f1f1f1;
    padding-top: 10px;
    margin-top: 8px;

    a {
      font-size: 21px;
      line-height: 25px;
    }
  }

  .auteur,
  .categorie {
    font-size: 11px;
  }

  .klant {
    border-top: 1px solid #f1f1f1;
    padding-top: 10px;
  }

  .tags {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 10px;
  }

  .lees-meer a {
    float: right;
    margin-top: 10px;
  }
}

/* Blog overview met afbeelding*/
.case-item.has-image .col {
  width: 30%;
}

.case-item.has-image .thumb.col {
  float: left;
  width: 65%;
  margin-right: 5%;
}

// Case Detail
.case-detail {
  .case-item {
    margin: 0 !important;
    border: none;

    .lees-meer a {
      margin-bottom: 10px;
    }

    &.has-image .col {
      width: 100%;
    }
  }
}
