//================================================
//=== BREADCRUMB =================================
//================================================
.front{
  .breadcrumbs-wrapper{
    display: none;
  }
}
.breadcrumbs-wrapper{
  padding: 14px;
  background: $gray-light2;
}

.breadcrumbs a,
.breadcrumbs span {
  font-size: 14px;
  color: $blue-gray;
}

.easy-breadcrumb_segment-separator {
  padding: 0 5px;
}

// Hide op homepage
.front .breadcrumbs {
  display: none;
}

.breadcrumbs {
  width: $row-width;
  min-width: 0;
  // margin: ($column-gutter / 2) auto $column-gutter;
  margin: 0 auto;
  max-width: 100%;

  .easy-breadcrumb {
    padding: 0 ($column-gutter / 2);
  }
}
