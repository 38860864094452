html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }
  blockquote:before, blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*
	jQuery.mmenu CSS
*/
/*
	jQuery.mmenu panels CSS
*/
.mm-menu.mm-horizontal > .mm-panel {
  -webkit-transition: left 0.4s ease;
  -moz-transition: left 0.4s ease;
  -ms-transition: left 0.4s ease;
  -o-transition: left 0.4s ease;
  transition: left 0.4s ease; }

.mm-menu .mm-hidden {
  display: none; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu {
  background: inherit;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0; }
  .mm-menu > .mm-panel {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    background: inherit;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 0; }
    .mm-menu > .mm-panel.mm-opened {
      left: 0%; }
    .mm-menu > .mm-panel.mm-subopened {
      left: -40%; }
    .mm-menu > .mm-panel.mm-highest {
      z-index: 1; }
  .mm-menu .mm-list {
    padding: 20px 0; }
  .mm-menu > .mm-list {
    padding: 20px 0 40px 0; }

.mm-panel > .mm-list {
  margin-left: -20px;
  margin-right: -20px; }
  .mm-panel > .mm-list:first-child {
    padding-top: 0; }

.mm-list,
.mm-list > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }

.mm-list {
  font: inherit;
  font-size: 14px; }
  .mm-list a,
  .mm-list a:hover {
    text-decoration: none; }
  .mm-list > li {
    position: relative; }
    .mm-list > li > a,
    .mm-list > li > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: inherit;
      line-height: 20px;
      display: block;
      padding: 10px 10px 10px 20px;
      margin: 0; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    content: '';
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    width: auto;
    margin-left: 20px;
    position: relative;
    left: auto; }
  .mm-list a.mm-subopen {
    width: 40px;
    height: 100%;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; }
    .mm-list a.mm-subopen:before {
      content: '';
      border-left-width: 1px;
      border-left-style: solid;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
    .mm-list a.mm-subopen.mm-fullsubopen {
      width: 100%; }
      .mm-list a.mm-subopen.mm-fullsubopen:before {
        border-left: none; }
    .mm-list a.mm-subopen + a,
    .mm-list a.mm-subopen + span {
      padding-right: 5px;
      margin-right: 40px; }
  .mm-list > li.mm-selected > a.mm-subopen {
    background: transparent; }
  .mm-list > li.mm-selected > a.mm-fullsubopen + a,
  .mm-list > li.mm-selected > a.mm-fullsubopen + span {
    padding-right: 45px;
    margin-right: 0; }
  .mm-list a.mm-subclose {
    text-indent: 20px;
    padding-top: 30px;
    margin-top: -20px; }
  .mm-list > li.mm-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    text-transform: uppercase;
    text-indent: 20px;
    line-height: 25px;
    padding-right: 5px; }
  .mm-list > li.mm-spacer {
    padding-top: 40px; }
    .mm-list > li.mm-spacer.mm-label {
      padding-top: 25px; }
  .mm-list a.mm-subopen:after,
  .mm-list a.mm-subclose:before {
    content: '';
    border: 2px solid transparent;
    display: block;
    width: 7px;
    height: 7px;
    margin-bottom: -5px;
    position: absolute;
    bottom: 50%;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .mm-list a.mm-subopen:after {
    border-top: none;
    border-left: none;
    right: 18px; }
  .mm-list a.mm-subclose:before {
    border-right: none;
    border-bottom: none;
    margin-bottom: -15px;
    left: 22px; }

.mm-menu.mm-vertical .mm-list .mm-panel {
  display: none;
  padding: 10px 0 10px 10px; }
  .mm-menu.mm-vertical .mm-list .mm-panel li:last-child:after {
    border-color: transparent; }

.mm-menu.mm-vertical .mm-list li.mm-opened > .mm-panel {
  display: block; }

.mm-menu.mm-vertical .mm-list > li.mm-opened > a.mm-subopen {
  height: 40px; }
  .mm-menu.mm-vertical .mm-list > li.mm-opened > a.mm-subopen:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 16px;
    right: 16px; }

html.mm-opened .mm-page {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }

.mm-ismenu {
  background: #333;
  color: rgba(255, 255, 255, 0.6); }

.mm-menu .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.15); }

.mm-menu .mm-list > li > a.mm-subclose {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.3); }

.mm-menu .mm-list > li > a.mm-subopen:after, .mm-menu .mm-list > li > a.mm-subclose:before {
  border-color: rgba(255, 255, 255, 0.3); }

.mm-menu .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.15); }

.mm-menu .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu .mm-list > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.1); }

.mm-menu .mm-list > li.mm-label {
  background: rgba(255, 255, 255, 0.05); }

.mm-menu.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(255, 255, 255, 0.05); }

/*
	jQuery.mmenu offcanvas addon CSS
*/
.mm-page,
.mm-fixed-top,
.mm-fixed-bottom {
  -webkit-transition: none 0.4s ease;
  -moz-transition: none 0.4s ease;
  -ms-transition: none 0.4s ease;
  -o-transition: none 0.4s ease;
  transition: none 0.4s ease;
  -webkit-transition-property: top, right, bottom, left, border;
  -moz-transition-property: top, right, bottom, left, border;
  -ms-transition-property: top, right, bottom, left, border;
  -o-transition-property: top, right, bottom, left, border;
  transition-property: top, right, bottom, left, border; }

html.mm-opened .mm-page,
html.mm-opened #mm-blocker {
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0px solid transparent; }

html.mm-opening .mm-page {
  border: 0px solid #010101; }

.mm-fixed-top,
.mm-fixed-bottom {
  position: fixed;
  left: 0; }

.mm-fixed-top {
  top: 0; }

.mm-fixed-bottom {
  bottom: 0; }

html.mm-opened {
  overflow-x: hidden;
  position: relative; }

html.mm-opened .mm-page {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  position: relative; }

html.mm-background .mm-page {
  background: inherit; }

#mm-blocker {
  background: url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==) transparent;
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999; }

html.mm-opened #mm-blocker,
html.mm-blocking #mm-blocker {
  display: block; }

.mm-menu.mm-offcanvas {
  display: none;
  position: fixed; }

.mm-menu.mm-current {
  display: block; }

html.mm-opening .mm-page,
html.mm-opening #mm-blocker,
html.mm-opening .mm-fixed-top,
html.mm-opening .mm-fixed-bottom {
  left: 80%; }

.mm-menu {
  width: 80%; }

@media all and (max-width: 175px) {
  .mm-menu {
    width: 140px; }
  html.mm-opening .mm-page,
  html.mm-opening #mm-blocker,
  html.mm-opening .mm-fixed-top,
  html.mm-opening .mm-fixed-bottom {
    left: 140px; } }

@media all and (min-width: 550px) {
  .mm-menu {
    width: 440px; }
  html.mm-opening .mm-page,
  html.mm-opening #mm-blocker,
  html.mm-opening .mm-fixed-top,
  html.mm-opening .mm-fixed-bottom {
    left: 440px; } }

/*
	jQuery.mmenu counters addon CSS
*/
em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 40px;
  top: 50%; }
  em.mm-counter + a.mm-subopen {
    padding-left: 40px; }
    em.mm-counter + a.mm-subopen + a,
    em.mm-counter + a.mm-subopen + span {
      margin-right: 80px; }
  em.mm-counter + a.mm-fullsubopen {
    padding-left: 0; }

.mm-vertical em.mm-counter {
  top: 12px;
  margin-top: 0; }

.mm-nosubresults > em.mm-counter {
  display: none; }

.mm-menu em.mm-counter {
  color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu dragOpen addon CSS
*/
html.mm-opened.mm-dragging .mm-menu,
html.mm-opened.mm-dragging .mm-page,
html.mm-opened.mm-dragging .mm-fixed-top,
html.mm-opened.mm-dragging .mm-fixed-bottom,
html.mm-opened.mm-dragging #mm-blocker {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s; }

/*
	jQuery.mmenu header addon CSS
*/
.mm-header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  background: inherit;
  border-bottom: 1px solid transparent;
  text-align: center;
  line-height: 20px;
  width: 100%;
  height: 60px;
  padding: 30px 40px 0 40px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0; }
  .mm-header .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 1; }
  .mm-header .mm-prev,
  .mm-header .mm-next {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    display: block;
    width: 40px;
    height: 100%;
    position: absolute;
    bottom: 0; }
    .mm-header .mm-prev:before,
    .mm-header .mm-next:before {
      content: '';
      border: 2px solid transparent;
      display: block;
      width: 7px;
      height: 7px;
      margin-bottom: -5px;
      position: absolute;
      bottom: 50%;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      margin-bottom: -15px; }
  .mm-header .mm-prev {
    left: 0; }
    .mm-header .mm-prev:before {
      border-right: none;
      border-bottom: none;
      left: 22px; }
  .mm-header .mm-next {
    right: 0; }
    .mm-header .mm-next:before {
      border-top: none;
      border-left: none;
      right: 18px; }

.mm-menu.mm-hassearch .mm-header {
  height: 50px;
  padding-top: 20px;
  top: 50px; }
  .mm-menu.mm-hassearch .mm-header .mm-prev:before,
  .mm-menu.mm-hassearch .mm-header .mm-mext:before {
    margin-bottom: -10px; }

.mm-menu.mm-hasheader li.mm-subtitle {
  display: none; }

.mm-menu.mm-hasheader > .mm-panel {
  padding-top: 80px; }
  .mm-menu.mm-hasheader > .mm-panel.mm-list {
    padding-top: 60px; }
  .mm-menu.mm-hasheader > .mm-panel > .mm-list:first-child {
    margin-top: -20px; }

.mm-menu.mm-hasheader.mm-hassearch > .mm-panel {
  padding-top: 120px; }
  .mm-menu.mm-hasheader.mm-hassearch > .mm-panel.mm-list {
    padding-top: 100px; }

.mm-menu .mm-header {
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.3); }
  .mm-menu .mm-header a:before {
    border-color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu labels addon CSS
*/
.mm-menu.mm-fixedlabels .mm-list {
  background: inherit; }
  .mm-menu.mm-fixedlabels .mm-list > li.mm-label {
    background: inherit !important;
    opacity: 0.97;
    height: 25px;
    overflow: visible;
    position: relative;
    z-index: 1; }
    .mm-menu.mm-fixedlabels .mm-list > li.mm-label > div {
      background: inherit;
      width: 100%;
      position: absolute;
      left: 0; }
      .mm-menu.mm-fixedlabels .mm-list > li.mm-label > div > div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
    .mm-menu.mm-fixedlabels .mm-list > li.mm-label.mm-spacer > div > div {
      padding-top: 25px; }

.mm-list > li.mm-label > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0; }

.mm-list > li.mm-label.mm-opened a.mm-subopen:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 17px; }

.mm-list > li.mm-collapsed {
  display: none; }

.mm-menu .mm-list li.mm-label > div > div {
  background: rgba(255, 255, 255, 0.05); }

/*
	jQuery.mmenu searchfield addon CSS
*/
.mm-search,
.mm-search input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box; }

.mm-list > li.mm-search {
  padding: 10px;
  margin-top: -20px; }

.mm-list > li.mm-subtitle + li.mm-search {
  margin-top: 0; }

div.mm-panel > div.mm-search {
  padding: 0 0 10px 0; }

.mm-menu.mm-hasheader .mm-list > li.mm-search {
  margin-top: 0; }

.mm-search {
  background: inherit;
  width: 100%;
  padding: 10px;
  position: relative;
  top: 0;
  z-index: 2; }
  .mm-search input {
    border: none;
    border-radius: 30px;
    font: inherit;
    font-size: 14px;
    line-height: 30px;
    outline: none;
    display: block;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0 10px; }

.mm-menu .mm-noresultsmsg {
  text-align: center;
  font-size: 21px;
  display: none;
  padding: 60px 0; }
  .mm-menu .mm-noresultsmsg:after {
    border: none !important; }

.mm-noresults .mm-noresultsmsg {
  display: block; }

.mm-menu li.mm-nosubresults > a.mm-subopen {
  display: none; }
  .mm-menu li.mm-nosubresults > a.mm-subopen + a,
  .mm-menu li.mm-nosubresults > a.mm-subopen + span {
    padding-right: 10px; }

.mm-menu.mm-hassearch > .mm-panel {
  padding-top: 70px; }
  .mm-menu.mm-hassearch > .mm-panel > .mm-list:first-child {
    margin-top: -20px; }

.mm-menu.mm-hasheader > .mm-panel > div.mm-search:first-child {
  margin-top: -10px; }
  .mm-menu.mm-hasheader > .mm-panel > div.mm-search:first-child + .mm-list {
    padding-top: 0; }

.mm-menu .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6); }

.mm-menu .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.3); }

/*
	jQuery.mmenu toggles addon CSS
*/
label.mm-toggle {
  border-radius: 30px;
  width: 50px;
  height: 30px;
  margin: -15px 0 0 0;
  position: absolute;
  top: 50%;
  z-index: 1; }
  label.mm-toggle div {
    border-radius: 30px;
    width: 28px;
    height: 28px;
    margin: 1px; }

input.mm-toggle {
  position: absolute;
  left: -10000px; }
  input.mm-toggle:checked ~ label.mm-toggle div {
    float: right; }

label.mm-toggle {
  right: 20px; }
  label.mm-toggle + a,
  label.mm-toggle + span {
    margin-right: 70px; }

a.mm-subopen + label.mm-toggle {
  right: 50px; }
  a.mm-subopen + label.mm-toggle + a,
  a.mm-subopen + label.mm-toggle + span {
    margin-right: 100px; }

em.mm-counter + a.mm-subopen + label.mm-toggle {
  right: 90px; }
  em.mm-counter + a.mm-subopen + label.mm-toggle + a,
  em.mm-counter + a.mm-subopen + label.mm-toggle + span {
    margin-right: 140px; }

.mm-menu label.mm-toggle {
  background: rgba(0, 0, 0, 0.15); }
  .mm-menu label.mm-toggle div {
    background: #333; }

.mm-menu input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

/*
	jQuery.mmenu effects extension CSS
*/
html.mm-slide .mm-menu {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease; }

html.mm-slide.mm-opened .mm-menu {
  -webkit-transform: translateX(-40%);
  -moz-transform: translateX(-40%);
  -ms-transform: translateX(-40%);
  -o-transform: translateX(-40%);
  transform: translateX(-40%); }

html.mm-slide.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%); }

html.mm-slide.mm-right.mm-opened .mm-menu {
  -webkit-transform: translateX(40%);
  -moz-transform: translateX(40%);
  -ms-transform: translateX(40%);
  -o-transform: translateX(40%);
  transform: translateX(40%); }

html.mm-slide.mm-right.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%); }

html.mm-slide.mm-top.mm-opened .mm-menu {
  -webkit-transform: translateY(-40%);
  -moz-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  -o-transform: translateY(-40%);
  transform: translateY(-40%); }

html.mm-slide.mm-top.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

html.mm-slide.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: translateY(40%);
  -moz-transform: translateY(40%);
  -ms-transform: translateY(40%);
  -o-transform: translateY(40%);
  transform: translateY(40%); }

html.mm-slide.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

html.mm-zoom-menu .mm-menu {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease; }

html.mm-zoom-menu.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(-40%);
  -moz-transform: scale(0.7, 0.7) translateX(-40%);
  -ms-transform: scale(0.7, 0.7) translateX(-40%);
  -o-transform: scale(0.7, 0.7) translateX(-40%);
  transform: scale(0.7, 0.7) translateX(-40%);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

html.mm-zoom-menu.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  -moz-transform: scale(1, 1) translateX(0%);
  -ms-transform: scale(1, 1) translateX(0%);
  -o-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }

html.mm-zoom-menu.mm-right.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateX(40%);
  -moz-transform: scale(0.7, 0.7) translateX(40%);
  -ms-transform: scale(0.7, 0.7) translateX(40%);
  -o-transform: scale(0.7, 0.7) translateX(40%);
  transform: scale(0.7, 0.7) translateX(40%);
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center; }

html.mm-zoom-menu.mm-right.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateX(0%);
  -moz-transform: scale(1, 1) translateX(0%);
  -ms-transform: scale(1, 1) translateX(0%);
  -o-transform: scale(1, 1) translateX(0%);
  transform: scale(1, 1) translateX(0%); }

html.mm-zoom-menu.mm-top.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(-40%);
  -moz-transform: scale(0.7, 0.7) translateY(-40%);
  -ms-transform: scale(0.7, 0.7) translateY(-40%);
  -o-transform: scale(0.7, 0.7) translateY(-40%);
  transform: scale(0.7, 0.7) translateY(-40%);
  -webkit-transform-origin: center top;
  -moz-transform-origin: center top;
  -ms-transform-origin: center top;
  -o-transform-origin: center top;
  transform-origin: center top; }

html.mm-zoom-menu.mm-top.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  -moz-transform: scale(1, 1) translateY(0%);
  -ms-transform: scale(1, 1) translateY(0%);
  -o-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }

html.mm-zoom-menu.mm-bottom.mm-opened .mm-menu {
  -webkit-transform: scale(0.7, 0.7) translateY(40%);
  -moz-transform: scale(0.7, 0.7) translateY(40%);
  -ms-transform: scale(0.7, 0.7) translateY(40%);
  -o-transform: scale(0.7, 0.7) translateY(40%);
  transform: scale(0.7, 0.7) translateY(40%);
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform-origin: center bottom; }

html.mm-zoom-menu.mm-bottom.mm-opening .mm-menu {
  -webkit-transform: scale(1, 1) translateY(0%);
  -moz-transform: scale(1, 1) translateY(0%);
  -ms-transform: scale(1, 1) translateY(0%);
  -o-transform: scale(1, 1) translateY(0%);
  transform: scale(1, 1) translateY(0%); }

html.mm-zoom-page .mm-page {
  -webkit-transition-property: -webkit-transform, top, right, bottom, left, border;
  -moz-transition-property: -moz-transform, top, right, bottom, left, border;
  -ms-transition-property: -ms-transform, top, right, bottom, left, border;
  -o-transition-property: -o-transform, top, right, bottom, left, border;
  transition-property: transform, top, right, bottom, left, border; }

html.mm-zoom-page.mm-opened .mm-page {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center; }

html.mm-zoom-page.mm-opening .mm-page {
  -webkit-transform: scale(1.5, 1.5);
  -moz-transform: scale(1.5, 1.5);
  -ms-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5); }

html.mm-zoom-page.mm-right.mm-opened .mm-page {
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center; }

html.mm-zoom-page.mm-top.mm-opened .mm-page {
  -webkit-transform-origin: center top;
  -moz-transform-origin: center top;
  -ms-transform-origin: center top;
  -o-transform-origin: center top;
  transform-origin: center top; }

html.mm-zoom-page.mm-bottom.mm-opened .mm-page {
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform-origin: center bottom; }

html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel {
  -webkit-transform: scale(1.5, 1.5);
  -moz-transform: scale(1.5, 1.5);
  -ms-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition-property: -webkit-transform, left;
  -moz-transition-property: -moz-transform, left;
  -ms-transition-property: -ms-transform, left;
  -o-transition-property: -o-transform, left;
  transition-property: transform, left; }
  html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
    html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel.mm-opened.mm-subopened {
      -webkit-transform: scale(0.7, 0.7);
      -moz-transform: scale(0.7, 0.7);
      -ms-transform: scale(0.7, 0.7);
      -o-transform: scale(0.7, 0.7);
      transform: scale(0.7, 0.7); }

/*
	jQuery.mmenu fullscreen extension CSS
*/
html.mm-opening.mm-fullscreen .mm-page,
html.mm-opening.mm-fullscreen #mm-blocker,
html.mm-opening.mm-fullscreen .mm-fixed-top,
html.mm-opening.mm-fullscreen .mm-fixed-bottom {
  left: 100%; }

.mm-menu.mm-fullscreen {
  width: 100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-fullscreen {
    width: 140px; }
  html.mm-opening.mm-fullscreen .mm-page,
  html.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-opening.mm-fullscreen .mm-fixed-bottom {
    left: 140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-fullscreen {
    width: 10000px; }
  html.mm-opening.mm-fullscreen .mm-page,
  html.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-opening.mm-fullscreen .mm-fixed-bottom {
    left: 10000px; } }

.mm-menu.mm-top.mm-fullscreen {
  height: 100%; }

html.mm-top.mm-opening.mm-fullscreen .mm-page,
html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
  top: 100%; }

html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
  bottom: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-top.mm-fullscreen {
    height: 140px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-page,
  html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
    top: 140px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-top.mm-fullscreen {
    height: 10000px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-page,
  html.mm-top.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-top {
    top: 10000px; }
  html.mm-top.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: -10000px; } }

.mm-menu.mm-right.mm-fullscreen {
  width: 100%; }

html.mm-right.mm-opening.mm-fullscreen .mm-page,
html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
  right: 100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-right.mm-fullscreen {
    width: 140px; }
  html.mm-right.mm-opening.mm-fullscreen .mm-page,
  html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
    right: 140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-right.mm-fullscreen {
    width: 10000px; }
  html.mm-right.mm-opening.mm-fullscreen .mm-page,
  html.mm-right.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-top,
  html.mm-right.mm-opening.mm-fullscreen .mm-fixed-bottom {
    right: 10000px; } }

.mm-menu.mm-bottom.mm-fullscreen {
  height: 100%; }

html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
  bottom: 100%; }

html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
  top: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-bottom.mm-fullscreen {
    height: 140px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
  html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: 140px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
    top: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-bottom.mm-fullscreen {
    height: 10000px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-page,
  html.mm-bottom.mm-opening.mm-fullscreen #mm-blocker,
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-bottom {
    bottom: 10000px; }
  html.mm-bottom.mm-opening.mm-fullscreen .mm-fixed-top {
    top: -10000px; } }

.mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
  left: -100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
    left: -140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-fullscreen.mm-front, .mm-menu.mm-fullscreen.mm-next {
    left: -10000px; } }

.mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
  top: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
    top: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-top.mm-fullscreen.mm-front, .mm-menu.mm-top.mm-fullscreen.mm-next {
    top: -10000px; } }

.mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
  right: -100%; }

@media all and (max-width: 140px) {
  .mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
    right: -140px; } }

@media all and (min-width: 10000px) {
  .mm-menu.mm-right.mm-fullscreen.mm-front, .mm-menu.mm-right.mm-fullscreen.mm-next {
    right: -10000px; } }

.mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
  bottom: -100%; }

@media all and (max-height: 140px) {
  .mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
    bottom: -140px; } }

@media all and (min-height: 10000px) {
  .mm-menu.mm-bottom.mm-fullscreen.mm-front, .mm-menu.mm-bottom.mm-fullscreen.mm-next {
    bottom: -10000px; } }

html.mm-front .mm-fixed-top,
html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-bottom {
  left: 0;
  right: auto; }

html.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-top {
  top: 0; }

html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-bottom {
  bottom: 0; }

html.mm-opened.mm-fullscreen .mm-page {
  box-shadow: none !important; }

/*
	jQuery.mmenu position extension CSS
*/
.mm-menu.mm-top {
  width: 100%; }

html.mm-top.mm-opened .mm-page,
html.mm-top.mm-opened #mm-blocker {
  top: 0%; }

html.mm-top.mm-opened.mm-opening .mm-page,
html.mm-top.mm-opened.mm-opening #mm-blocker,
html.mm-top.mm-opened.mm-opening .mm-fixed-top,
html.mm-top.mm-opened.mm-opening .mm-fixed-bottom {
  left: 0; }

.mm-menu.mm-right {
  left: auto;
  right: 0; }

html.mm-right.mm-opened .mm-page,
html.mm-right.mm-opened #mm-blocker,
html.mm-right.mm-opened .mm-fixed-top,
html.mm-right.mm-opened .mm-fixed-bottom {
  left: auto;
  right: 0%; }

html.mm-right.mm-opened.mm-opening .mm-page,
html.mm-right.mm-opened.mm-opening #mm-blocker,
html.mm-right.mm-opened.mm-opening .mm-fixed-top,
html.mm-right.mm-opened.mm-opening .mm-fixed-bottom {
  left: auto; }

.mm-menu.mm-bottom {
  width: 100%;
  top: auto;
  bottom: 0; }

html.mm-bottom.mm-opened .mm-page,
html.mm-bottom.mm-opened #mm-blocker {
  bottom: 0%;
  top: auto; }

html.mm-bottom.mm-opened.mm-opening .mm-page,
html.mm-bottom.mm-opened.mm-opening #mm-blocker,
html.mm-bottom.mm-opened.mm-opening .mm-fixed-top,
html.mm-bottom.mm-opened.mm-opening .mm-fixed-bottom {
  top: auto;
  left: 0; }

.mm-menu.mm-top {
  height: 80%; }

html.mm-top.mm-opening .mm-page,
html.mm-top.mm-opening #mm-blocker,
html.mm-top.mm-opening .mm-fixed-top {
  top: 80%; }

html.mm-top.mm-opening .mm-fixed-bottom {
  bottom: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-top {
    height: 140px; }
  html.mm-top.mm-opening .mm-page,
  html.mm-top.mm-opening #mm-blocker,
  html.mm-top.mm-opening .mm-fixed-top {
    top: 140px; }
  html.mm-top.mm-opening .mm-fixed-bottom {
    bottom: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-top {
    height: 880px; }
  html.mm-top.mm-opening .mm-page,
  html.mm-top.mm-opening #mm-blocker,
  html.mm-top.mm-opening .mm-fixed-top {
    top: 880px; }
  html.mm-top.mm-opening .mm-fixed-bottom {
    bottom: -880px; } }

.mm-menu.mm-right {
  width: 80%; }

html.mm-right.mm-opening .mm-page,
html.mm-right.mm-opening #mm-blocker,
html.mm-right.mm-opening .mm-fixed-top,
html.mm-right.mm-opening .mm-fixed-bottom {
  right: 80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-right {
    width: 140px; }
  html.mm-right.mm-opening .mm-page,
  html.mm-right.mm-opening #mm-blocker,
  html.mm-right.mm-opening .mm-fixed-top,
  html.mm-right.mm-opening .mm-fixed-bottom {
    right: 140px; } }

@media all and (min-width: 550px) {
  .mm-menu.mm-right {
    width: 440px; }
  html.mm-right.mm-opening .mm-page,
  html.mm-right.mm-opening #mm-blocker,
  html.mm-right.mm-opening .mm-fixed-top,
  html.mm-right.mm-opening .mm-fixed-bottom {
    right: 440px; } }

.mm-menu.mm-bottom {
  height: 80%; }

html.mm-bottom.mm-opening .mm-page,
html.mm-bottom.mm-opening #mm-blocker,
html.mm-bottom.mm-opening .mm-fixed-bottom {
  bottom: 80%; }

html.mm-bottom.mm-opening .mm-fixed-top {
  top: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-bottom {
    height: 140px; }
  html.mm-bottom.mm-opening .mm-page,
  html.mm-bottom.mm-opening #mm-blocker,
  html.mm-bottom.mm-opening .mm-fixed-bottom {
    bottom: 140px; }
  html.mm-bottom.mm-opening .mm-fixed-top {
    top: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-bottom {
    height: 880px; }
  html.mm-bottom.mm-opening .mm-page,
  html.mm-bottom.mm-opening #mm-blocker,
  html.mm-bottom.mm-opening .mm-fixed-bottom {
    bottom: 880px; }
  html.mm-bottom.mm-opening .mm-fixed-top {
    top: -880px; } }

/*
	jQuery.mmenu z-position extension CSS
*/
html.mm-front.mm-opened .mm-page {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important; }

.mm-menu.mm-front,
.mm-menu.mm-next {
  -webkit-transition: none 0.4s ease;
  -moz-transition: none 0.4s ease;
  -ms-transition: none 0.4s ease;
  -o-transition: none 0.4s ease;
  transition: none 0.4s ease;
  -webkit-transition-property: top, right, bottom, left, -webkit-transform;
  -moz-transition-property: top, right, bottom, left, -moz-transform;
  -ms-transition-property: top, right, bottom, left, -o-transform;
  -o-transition-property: top, right, bottom, left, -o-transform;
  transition-property: top, right, bottom, left, transform; }

html.mm-front .mm-page,
html.mm-front #mm-blocker {
  z-index: 0; }

.mm-menu.mm-front {
  z-index: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); }

html.mm-opened.mm-next .mm-page {
  box-shadow: none; }

html.mm-opening .mm-menu.mm-front, html.mm-opening .mm-menu.mm-next {
  left: 0%; }

.mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
  left: 0; }

html.mm-opening .mm-menu.mm-top.mm-front, html.mm-opening .mm-menu.mm-top.mm-next {
  left: 0;
  top: 0%; }

.mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
  left: auto; }

html.mm-opening .mm-menu.mm-right.mm-front, html.mm-opening .mm-menu.mm-right.mm-next {
  left: auto;
  right: 0%; }

.mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
  top: auto;
  left: 0; }

html.mm-opening .mm-menu.mm-bottom.mm-front, html.mm-opening .mm-menu.mm-bottom.mm-next {
  left: 0;
  bottom: 0%; }

.mm-menu.mm-front, .mm-menu.mm-next {
  left: -80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-front, .mm-menu.mm-next {
    left: -140px; } }

@media all and (min-width: 550px) {
  .mm-menu.mm-front, .mm-menu.mm-next {
    left: -440px; } }

.mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
  top: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
    top: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-top.mm-front, .mm-menu.mm-top.mm-next {
    top: -880px; } }

.mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
  right: -80%; }

@media all and (max-width: 175px) {
  .mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
    right: -140px; } }

@media all and (min-width: 550px) {
  .mm-menu.mm-right.mm-front, .mm-menu.mm-right.mm-next {
    right: -440px; } }

.mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
  bottom: -80%; }

@media all and (max-height: 175px) {
  .mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
    bottom: -140px; } }

@media all and (min-height: 1100px) {
  .mm-menu.mm-bottom.mm-front, .mm-menu.mm-bottom.mm-next {
    bottom: -880px; } }

html.mm-front .mm-fixed-top,
html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-bottom {
  left: 0;
  right: auto; }

html.mm-front .mm-fixed-top,
html.mm-opening.mm-front .mm-fixed-top {
  top: 0; }

html.mm-front .mm-fixed-bottom,
html.mm-opening.mm-front .mm-fixed-bottom {
  bottom: 0; }

/*
	jQuery.mmenu themes extension CSS
*/
html.mm-opened.mm-light .mm-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-ismenu.mm-light {
  background: #f3f3f3;
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-light .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-light .mm-list > li > a.mm-subclose {
  background: rgba(255, 255, 255, 0.6);
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-list > li > a.mm-subopen:after, .mm-menu.mm-light .mm-list > li > a.mm-subclose:before {
  border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-light .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-light .mm-list > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-light .mm-list > li.mm-label {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-light.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light .mm-search input {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-light .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light .mm-list li.mm-label > div > div {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-light .mm-header {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-light .mm-header a:before {
    border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-light label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-light label.mm-toggle div {
    background: #f3f3f3; }

.mm-menu.mm-light input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

html.mm-opened.mm-white .mm-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.mm-ismenu.mm-white {
  background: #fff;
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-white .mm-list > li:after {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-white .mm-list > li > a.mm-subclose {
  background: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-list > li > a.mm-subopen:after, .mm-menu.mm-white .mm-list > li > a.mm-subclose:before {
  border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-list > li > a.mm-subopen:before {
  border-color: rgba(0, 0, 0, 0.1); }

.mm-menu.mm-white .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-white .mm-list > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.06); }

.mm-menu.mm-white .mm-list > li.mm-label {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-white.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white .mm-search input {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6); }

.mm-menu.mm-white .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white em.mm-counter {
  color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white .mm-list li.mm-label > div > div {
  background: rgba(0, 0, 0, 0.03); }

.mm-menu.mm-white .mm-header {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3); }
  .mm-menu.mm-white .mm-header a:before {
    border-color: rgba(0, 0, 0, 0.3); }

.mm-menu.mm-white label.mm-toggle {
  background: rgba(0, 0, 0, 0.1); }
  .mm-menu.mm-white label.mm-toggle div {
    background: #fff; }

.mm-menu.mm-white input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

html.mm-opened.mm-black .mm-page {
  box-shadow: none; }

.mm-ismenu.mm-black {
  background: #000;
  color: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-black .mm-list > li:after {
  border-color: rgba(255, 255, 255, 0.2); }

.mm-menu.mm-black .mm-list > li > a.mm-subclose {
  background: rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-list > li > a.mm-subopen:after, .mm-menu.mm-black .mm-list > li > a.mm-subclose:before {
  border-color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-list > li > a.mm-subopen:before {
  border-color: rgba(255, 255, 255, 0.2); }

.mm-menu.mm-black .mm-list > li.mm-selected > a:not(.mm-subopen),
.mm-menu.mm-black .mm-list > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.25); }

.mm-menu.mm-black .mm-list > li.mm-label {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-black.mm-vertical .mm-list li.mm-opened > ul {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6); }

.mm-menu.mm-black .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black em.mm-counter {
  color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black .mm-list li.mm-label > div > div {
  background: rgba(255, 255, 255, 0.15); }

.mm-menu.mm-black .mm-header {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.3); }
  .mm-menu.mm-black .mm-header a:before {
    border-color: rgba(255, 255, 255, 0.3); }

.mm-menu.mm-black label.mm-toggle {
  background: rgba(255, 255, 255, 0.2); }
  .mm-menu.mm-black label.mm-toggle div {
    background: #000; }

.mm-menu.mm-black input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963; }

/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */
/* Browser Resets
*********************************/
.flex-container a:active,
.flex-container a:focus,
.flexslider a:active,
.flexslider a:focus {
  outline: none; }

.flex-control-nav,
.flex-direction-nav,
.slides {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Icon Fonts
*********************************/
/* Font-face Icons */
@font-face {
  font-family: 'flexslider-icon';
  src: url("fonts/flexslider-icon.eot");
  src: url("fonts/flexslider-icon.eot?#iefix") format("embedded-opentype"), url("fonts/flexslider-icon.woff") format("woff"), url("fonts/flexslider-icon.ttf") format("truetype"), url("fonts/flexslider-icon.svg#flexslider-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
  margin: 0;
  padding: 0; }
  .flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden; }
  .flexslider .slides img {
    width: 100%;
    display: block; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flex-pauseplay span {
  text-transform: capitalize; }

/* Clearfix for the .slides element */
.slides:after {
  content: '\0020';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html[xmlns] .slides {
  display: block; }

* html .slides {
  height: 1%; }

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block; }

/* FlexSlider Default Theme
*********************************/
.flexslider {
  margin: 0 0 60px;
  background: #fff;
  border: 4px solid #fff;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  zoom: 1; }

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.loading .flex-viewport {
  max-height: 300px; }

.flexslider .slides {
  zoom: 1; }

.carousel li {
  margin-right: 5px; }

/* Direction Nav */
.flex-direction-nav {
  *height: 0; }
  .flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .flex-direction-nav .flex-prev {
    left: -50px; }
  .flex-direction-nav .flex-next {
    right: -50px;
    text-align: right; }

.flexslider:hover .flex-prev {
  opacity: 0.7;
  left: 10px; }

.flexslider:hover .flex-next {
  opacity: 0.7;
  right: 10px; }
  .flexslider:hover .flex-next:hover {
    opacity: 1; }

.flexslider:hover .flex-prev:hover {
  opacity: 1; }

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default; }

.flex-direction-nav a:before {
  font-family: 'flexslider-icon';
  font-size: 40px;
  display: inline-block;
  content: '\f001'; }

.flex-direction-nav a.flex-next:before {
  content: '\f002'; }

/* Pause/Play */
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000; }
  .flex-pauseplay a:before {
    font-family: 'flexslider-icon';
    font-size: 20px;
    display: inline-block;
    content: '\f004'; }
  .flex-pauseplay a:hover {
    opacity: 1; }
  .flex-pauseplay a.flex-play:before {
    content: '\f003'; }

/* Control Nav */
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center; }
  .flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline; }

.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .flex-control-paging li a:hover {
    background: #333;
    background: rgba(0, 0, 0, 0.7); }
  .flex-control-paging li a.flex-active {
    background: #000;
    background: rgba(0, 0, 0, 0.9);
    cursor: default; }

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden; }
  .flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0; }
  .flex-control-thumbs img {
    width: 100%;
    display: block;
    opacity: 0.7;
    cursor: pointer; }
    .flex-control-thumbs img:hover {
      opacity: 1; }
  .flex-control-thumbs .flex-active {
    opacity: 1;
    cursor: default; }

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px; }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px; } }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }
  .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #fff; }

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after, .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before, .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
    border-right: 17px solid #fff;
    margin-left: 31px; }
  .mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
    border-left: 17px solid #fff;
    margin-left: 39px; }
  .mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.mfp-ie7 .mfp-img {
  padding: 0; }

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }

.mfp-ie7 .mfp-container {
  padding: 0; }

.mfp-ie7 .mfp-content {
  padding-top: 44px; }

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 */
/**
 * Wireframes.
 */
.with-wireframes #header, .with-wireframes #main, .with-wireframes #content, .with-wireframes #navigation, .with-wireframes .region-sidebar-first, .with-wireframes .region-sidebar-second, .with-wireframes #footer, .with-wireframes .region-bottom {
  outline: 1px solid #ccc; }

.lt-ie8 .with-wireframes #header, .lt-ie8 .with-wireframes #main, .lt-ie8 .with-wireframes #content, .lt-ie8 .with-wireframes #navigation, .lt-ie8 .with-wireframes .region-sidebar-first, .lt-ie8 .with-wireframes .region-sidebar-second, .lt-ie8 .with-wireframes #footer, .lt-ie8 .with-wireframes .region-bottom {
  /* IE6/7 do not support the outline property. */
  border: 1px solid #ccc; }

/**
 * Accessibility features.
 */
/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible, .element-focusable {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px); }

#navigation .block-menu .block__title, #navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px); }

/* Turns off the element-invisible effect. */
.element-focusable:active, .element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto; }

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */
#skip-link {
  margin: 0; }
  #skip-link a {
    display: block;
    width: 100%;
    padding: 2px 0 3px 0;
    text-align: center;
    background-color: #666;
    color: #fff; }
    #skip-link a:visited {
      display: block;
      width: 100%;
      padding: 2px 0 3px 0;
      text-align: center;
      background-color: #666;
      color: #fff; }

/**
 * Branding header.
 */
/* Wrapping link for logo. */
.header__logo {
  float: left;
  /* LTR */
  margin: 0;
  padding: 0; }

/* Logo image. */
.header__logo-image {
  vertical-align: bottom; }

/* Wrapper for website name and slogan. */
.header__name-and-slogan {
  float: left; }

/* The name of the website. */
.header__site-name {
  margin: 0;
  font-size: 2em;
  line-height: 1.5em; }

/* The link around the name of the website. */
.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none; }

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline; }

/* The slogan (or tagline) of a website. */
.header__site-slogan {
  margin: 0; }

/* The secondary menu (login, etc.) */
.header__secondary-menu {
  float: right;
  /* LTR */ }

/* Wrapper for any blocks placed in the header region. */
.header__region {
  /* Clear the logo. */
  clear: both; }

/**
 * Navigation bar.
 */
#navigation {
  /* Sometimes you want to prevent overlapping with main div. */
  /* overflow: hidden; */ }
  #navigation .block {
    margin-bottom: 0; }
  #navigation .links, #navigation .menu {
    margin: 0;
    padding: 0;
    text-align: left;
    /* LTR */ }
  #navigation .links li, #navigation .menu li {
    /* A simple method to get navigation links to appear in one line. */
    float: left;
    /* LTR */
    padding: 0 10px 0 0;
    /* LTR */
    list-style-type: none;
    list-style-image: none; }

/* Main menu and secondary menu links and menu block links. */
/**
 * Breadcrumb navigation.
 */
.breadcrumb ol {
  margin: 0;
  padding: 0; }

.breadcrumb li {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0; }

/**
 * Titles.
 */
.page__title, .node__title, .block__title, .comments__title, .comments__form-title, .comment__title {
  /* Comment title. */
  margin: 0; }

/**
 * Messages.
 */
.messages, .messages--status, .messages--warning, .messages--error {
  margin: 1.5em 0;
  padding: 10px;
  background: #43AC6A;
  color: #fff;
  border: 0; }

.messages *, .messages--status *, .messages--warning *, .messages--error * {
  color: #fff;
  padding: 0;
  margin: 0; }

.messages a, .messages--status a, .messages--warning a, .messages--error a {
  color: #fff;
  text-decoration: underline; }

.messages__list {
  margin: 0; }

.messages__item {
  list-style-image: none; }

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333; }

/* System status report. */
.ok, .messages--status {
  background-color: #43AC6A; }

.warning, .messages--warning {
  background-color: #f08a24; }

.error, .messages--error {
  background-color: #f04124; }

/**
 * Tabs.
 */
/* Basic positioning styles shared by primary and secondary tabs. */
.tabs-primary, .tabs-secondary {
  overflow: hidden;
  *zoom: 1;
  background-color: #bbb;
  /* IE 9 and earlier don't understand gradients. */
  list-style: none;
  border-bottom: 1px solid #bbbbbb \0/ie;
  margin: 1.5em 0;
  padding: 0 2px;
  white-space: nowrap; }

.tabs-primary__tab {
  float: left;
  /* LTR */
  margin: 0 3px; }

.tabs-secondary__tab {
  float: left;
  /* LTR */
  margin: 0 3px; }
  .tabs-secondary__tab.is-active {
    float: left;
    /* LTR */
    margin: 0 3px; }

a.tabs-primary__tab-link, a.tabs-secondary__tab-link {
  border: 1px solid #e9e9e9;
  border-right: 0;
  border-bottom: 0;
  display: block;
  line-height: 1.5em;
  text-decoration: none; }

/* Primary tabs. */
.tabs-primary__tab {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  text-shadow: 1px 1px 0 white;
  border: 1px solid #bbbbbb;
  border-bottom-color: transparent;
  /* IE 9 and earlier don't understand gradients. */
  border-bottom: 0 \0/ie; }
  .tabs-primary__tab.is-active {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    text-shadow: 1px 1px 0 white;
    border: 1px solid #bbbbbb;
    border-bottom-color: transparent;
    /* IE 9 and earlier don't understand gradients. */
    border-bottom: 0 \0/ie;
    border-bottom-color: white; }

a.tabs-primary__tab-link {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  color: #333;
  background-color: #dedede;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center; }
  a.tabs-primary__tab-link.is-active {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    color: #333;
    background-color: #dedede;
    letter-spacing: 1px;
    padding: 0 1em;
    text-align: center; }
  a.tabs-primary__tab-link:hover, a.tabs-primary__tab-link:focus {
    background-color: #e9e9e9;
    border-color: #f2f2f2; }
  a.tabs-primary__tab-link:active, a.tabs-primary__tab-link.is-active {
    background-color: transparent;
    *zoom: 1;
    background-color: #e9e9e9;
    border-color: #fff; }

/* Secondary tabs. */
.tabs-secondary {
  font-size: .9em;
  /* Collapse bottom margin of ul.primary. */
  margin-top: -1.5em; }

.tabs-secondary__tab {
  margin: 0.75em 3px; }
  .tabs-secondary__tab.is-active {
    margin: 0.75em 3px; }

a.tabs-secondary__tab-link {
  -webkit-border-radius: 0.75em;
  -moz-border-radius: 0.75em;
  -ms-border-radius: 0.75em;
  -o-border-radius: 0.75em;
  border-radius: 0.75em;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  text-shadow: 1px 1px 0 white;
  background-color: #f2f2f2;
  color: #666;
  padding: 0 .5em; }
  a.tabs-secondary__tab-link.is-active {
    -webkit-border-radius: 0.75em;
    -moz-border-radius: 0.75em;
    -ms-border-radius: 0.75em;
    -o-border-radius: 0.75em;
    border-radius: 0.75em;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    text-shadow: 1px 1px 0 white;
    background-color: #f2f2f2;
    color: #666;
    padding: 0 .5em; }
  a.tabs-secondary__tab-link:hover, a.tabs-secondary__tab-link:focus {
    background-color: #dedede;
    border-color: #999;
    color: #333; }
  a.tabs-secondary__tab-link:active, a.tabs-secondary__tab-link.is-active {
    text-shadow: 1px 1px 0 #333333;
    background-color: #666;
    border-color: #000;
    color: #fff; }

/**
 * Inline styles.
 */
/* List of links generated by theme_links(). */
.inline {
  display: inline;
  padding: 0; }
  .inline li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0;
    /* LTR */ }

/* The inline field label used by the Fences module. */
span.field-label {
  padding: 0 1em 0 0;
  /* LTR */ }

/**
 * 'More' links.
 */
.more-link {
  text-align: right;
  /* LTR */ }

.more-help-link {
  text-align: right;
  /* LTR */ }
  .more-help-link a {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA7UlEQVR42qWTPQqDQBCFcwSPkCNITpAj5AjeIm1uYpkyR7Cy2Mot7OwsBAsRwUKwmOwLGRle3EIy8PyBfZ/z3J2TiPylz8VWWZZpUB40BonRKyizaxkA88MYYiqCEgv4MTvnZJom0VqWRbz3FlJZgLYtqmEY1Lg9r+sKsIXcLSC3AC019H0vqLquLeC5AfiHYSGkcdAJimKIBQiJ4+CO92OAtm0FNc8zOjkMwE5Q63FAtbeg6zpAYvG8BWR7i5qmQYwY4MIHqYhE2DOPQWcGJBQF2XU72ZzyUeZ5GCNt5/hybJgYdAXsq5sOEE/jG6dC5IOqCXTmAAAAAElFTkSuQmCC");
    *background-image: url("../../images/help.png");
    background-position: 0 50%;
    /* LTR */
    background-repeat: no-repeat;
    padding: 1px 0 1px 20px;
    /* LTR */ }

/**
 * Pager.
 */
/* A list of page numbers when more than 1 page of content is available. */
.pager {
  clear: both;
  padding: 0;
  text-align: center; }

.pager-item, .pager-first, .pager-previous, .pager-next, .pager-last, .pager-ellipsis {
  /* The current page's list item. */
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none; }

.pager-current {
  /* The current page's list item. */
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none;
  font-weight: bold; }

/**
 * Blocks.
 */
/* Block wrapper. */
/**
 * Menus.
 */
.menu__item.is-leaf {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHBAMAAAA2fErgAAAAD1BMVEX///+/v7+Li4sAAADAwMBFvsw8AAAAAXRSTlMAQObYZgAAAB1JREFUCFtjYAADYwMGBmYVZSDhKAwkFJWhYiAAAB2+Aa/9ugeaAAAAAElFTkSuQmCC");
  *list-style-image: url("../../images/menu-leaf.png");
  list-style-type: square; }

.menu__item.is-expanded {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  *list-style-image: url("../../images/menu-expanded.png");
  list-style-type: circle; }

.menu__item.is-collapsed {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  /* LTR */
  *list-style-image: url("../../images/menu-collapsed.png");
  /* LTR */
  list-style-type: disc; }

/* The active item in a Drupal menu. */
.menu a.active {
  color: #000; }

/**
 * Marker.
 */
/* The 'new' or 'updated' marker. */
.new, .update {
  color: #c00;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent; }

/**
 * Unpublished note.
 */
/* The word 'Unpublished' displayed underneath the content. */
.unpublished {
  height: 0;
  overflow: visible;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, 'Arial Narrow', Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  /* A very nice CSS3 property. */
  word-wrap: break-word; }

.lt-ie8 .node-unpublished > *, .lt-ie8 .comment-unpublished > * {
  /* Otherwise these elements will appear below the 'Unpublished' text. */
  position: relative; }

/**
 * Comments.
 */
/* Wrapper for the list of comments and its title. */
.comments {
  margin: 1.5em 0; }

/* Preview of the comment before submitting new or updated comment. */
.comment-preview {
  /* Drupal core will use a #ffffea background. See #1110842. */
  background-color: #ffffea; }

/* Wrapper for a single comment. */
.comment {
  /* Comment's permalink wrapper. */ }
  .comment ul {
    margin: 0; }
  .comment .permalink {
    text-transform: uppercase;
    font-size: 75%; }

/* Nested comments are indented. */
.indented {
  /* Drupal core uses a 25px left margin. */
  margin-left: 30px;
  /* LTR */ }

/**
 * Forms.
 */
/* Wrapper for a form element (or group of form elements) and its label. */
form .form-item {
  margin: 1.5em 0; }

.block.zoekveld .form-item {
  margin: 0; }

/* Pack groups of checkboxes and radio buttons closer together. */
.form-checkboxes .form-item, .form-radios .form-item {
  /* Drupal core uses '0.4em 0'. */
  margin: 0; }

/* Form items in a table. */
tr.odd .form-item, tr.even .form-item {
  margin: 0; }

/* Highlight the form elements that caused a form submission error. */
.form-item input.error, .form-item textarea.error, .form-item select.error {
  border: 1px solid #c00; }

.form-item .description {
  font-size: 0.85em; }

/* The descriptive help text (separate from the label). */
.form-type-radio .description, .form-type-checkbox .description {
  margin-left: 2.4em; }

/* The part of the label that indicates a required field. */
.form-required {
  color: #c00; }

/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal; }

/* Buttons used by contrib modules like Media. */
a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button; }

/* Password confirmation. */
.password-parent, .confirm-parent {
  margin: 0; }

/* Drupal's default login form block. */
#user-login-form {
  text-align: left;
  /* LTR */ }

/**
 * OpenID
 *
 * The default styling for the OpenID login link seems to assume Garland's
 * styling of list items.
 */
/* OpenID creates a new ul above the login form's links. */
.openid-links {
  /* Position OpenID's ul next to the rest of the links. */
  margin-bottom: 0; }

/* The 'Log in using OpenID' and 'Cancel OpenID login' links. */
.openid-link, .user-link {
  margin-top: 1.5em; }

html.js #user-login-form li.openid-link, #user-login-form li.openid-link {
  /* Un-do some of the padding on the ul list. */
  margin-left: -20px;
  /* LTR */ }

#user-login ul {
  margin: 1.5em 0; }

/**
 * Drupal admin tables.
 */
form th {
  text-align: left;
  /* LTR */
  padding-right: 1em;
  /* LTR */
  border-bottom: 3px solid #ccc; }

form tbody {
  border-top: 1px solid #ccc; }

form table ul {
  margin: 0; }

tr.even, tr.odd {
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.1em 0.6em; }

tr.even {
  background-color: #fff; }

.lt-ie8 tr.even th, .lt-ie8 tr.even td {
  /* IE doesn't display borders on table rows. */
  border-bottom: 1px solid #ccc; }

.lt-ie8 tr.odd th, .lt-ie8 tr.odd td {
  /* IE doesn't display borders on table rows. */
  border-bottom: 1px solid #ccc; }

/* Markup generated by theme_tablesort_indicator(). */
td.active {
  background-color: #ddd; }

td.checkbox {
  text-align: center; }

/* Center checkboxes inside table cell. */
th.checkbox {
  text-align: center; }

/* Drupal core wrongly puts this in system.menus.css. Since we override that, add it back. */
td.menu-disabled {
  background: #ccc; }

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list. */
#autocomplete .selected {
  background: #0072b9;
  color: #fff; }

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
html.js .collapsible .fieldset-legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  *background-image: url("../../images/menu-expanded.png");
  background-position: 5px 65%;
  /* LTR */
  background-repeat: no-repeat;
  padding-left: 15px;
  /* LTR */ }

html.js .collapsed .fieldset-legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  /* LTR */
  *background-image: url("../../images/menu-collapsed.png");
  /* LTR */
  background-position: 5px 50%;
  /* LTR */ }

.fieldset-legend .summary {
  color: #999;
  font-size: 0.9em;
  margin-left: 0.5em; }

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0; }

tr.drag-previous {
  background-color: #ffd; }

.tabledrag-toggle-weight {
  font-size: 0.9em; }

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc; }

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: bold; }
  .progress .bar {
    background: #ccc;
    border-color: #666;
    margin: 0 0.2em;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px; }
  .progress .filled {
    background-color: #0072b9;
    background-image: url("../../images/progress.gif"); }

.row:after, .content:after {
  content: '';
  display: block;
  clear: both; }

@keyframes bounce1 {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(30px); } }

@font-face {
  font-family: 'basis';
  src: url("fonts/basis.eot");
  src: url("fonts/basis.eot?#iefix") format("embedded-opentype"), url("fonts/basis.woff") format("woff"), url("fonts/basis.ttf") format("truetype"), url("fonts/basis.svg#basis") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: 'basis' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'basis' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-fontawesome-webfont:before {
  content: 'a'; }

.icon-fontawesome-webfont-1:before {
  content: 'b'; }

.icon-fontawesome-webfont-2:before {
  content: 'c'; }

.icon-fontawesome-webfont-3:before {
  content: 'd'; }

.icon-fontawesome-webfont-4:before {
  content: 'e'; }

.icon-fontawesome-webfont-5:before {
  content: 'f'; }

.icon-fontawesome-webfont-6:before {
  content: 'g'; }

.icon-fontawesome-webfont-7:before {
  content: 'h'; }

.icon-fontawesome-webfont-8:before {
  content: 'i'; }

.icon-fontawesome-webfont-9:before {
  content: 'j'; }

.icon-fontawesome-webfont-10:before {
  content: 'k'; }

.icon-fontawesome-webfont-11:before {
  content: 'l'; }

.icon-fontawesome-webfont-12:before {
  content: 'm'; }

.icon-fontawesome-webfont-14:before {
  content: 'o'; }

.icon-fontawesome-webfont-15:before {
  content: 'p'; }

.icon-fontawesome-webfont-16:before {
  content: 'q'; }

.icon-fontawesome-webfont-17:before {
  content: 'r'; }

.icon-fontawesome-webfont-19:before {
  content: 't'; }

.icon-fontawesome-webfont-20:before {
  content: 'u'; }

.icon-fontawesome-webfont-21:before {
  content: 'v'; }

.icon-fontawesome-webfont-22:before {
  content: 'w'; }

.icon-fontawesome-webfont-23:before {
  content: 'x'; }

.icon-fontawesome-webfont-24:before {
  content: 'y'; }

.icon-fontawesome-webfont-25:before {
  content: 'z'; }

.icon-fontawesome-webfont-26:before {
  content: 'A'; }

.icon-fontawesome-webfont-27:before {
  content: 'B'; }

.icon-fontawesome-webfont-28:before {
  content: 'C'; }

.icon-fontawesome-webfont-29:before {
  content: 'D'; }

.icon-fontawesome-webfont-37:before {
  content: 'L'; }

.icon-fontawesome-webfont-38:before {
  content: 'M'; }

.icon-fontawesome-webfont-39:before {
  content: 'N'; }

.icon-fontawesome-webfont-40:before {
  content: 'O'; }

.icon-fontawesome-webfont-41:before {
  content: 'P'; }

.icon-fontawesome-webfont-42:before {
  content: 'Q'; }

.icon-fontawesome-webfont-43:before {
  content: 'R'; }

.icon-fontawesome-webfont-44:before {
  content: 'S'; }

.icon-fontawesome-webfont-45:before {
  content: 'T'; }

.icon-fontawesome-webfont-46:before {
  content: 'U'; }

.icon-fontawesome-webfont-47:before {
  content: 'V'; }

.icon-fontawesome-webfont-48:before {
  content: 'W'; }

.icon-fontawesome-webfont-49:before {
  content: 'X'; }

.icon-fontawesome-webfont-50:before {
  content: 'Y'; }

.icon-fontawesome-webfont-51:before {
  content: 'Z'; }

.icon-fontawesome-webfont-53:before {
  content: '1'; }

.icon-fontawesome-webfont-54:before {
  content: '2'; }

.icon-fontawesome-webfont-55:before {
  content: '3'; }

.icon-fontawesome-webfont-56:before {
  content: '4'; }

.icon-fontawesome-webfont-57:before {
  content: '5'; }

.icon-fontawesome-webfont-60:before {
  content: '8'; }

.icon-fontawesome-webfont-61:before {
  content: '9'; }

.icon-fontawesome-webfont-63:before {
  content: '\''; }

.icon-fontawesome-webfont-64:before {
  content: '#'; }

.icon-fontawesome-webfont-65:before {
  content: '$'; }

.icon-fontawesome-webfont-66:before {
  content: '%'; }

.icon-fontawesome-webfont-67:before {
  content: '&'; }

.icon-fontawesome-webfont-68:before {
  content: '\''; }

.icon-fontawesome-webfont-69:before {
  content: '('; }

.icon-fontawesome-webfont-70:before {
  content: ')'; }

.icon-fontawesome-webfont-71:before {
  content: '*'; }

.icon-fontawesome-webfont-72:before {
  content: '+'; }

.icon-fontawesome-webfont-74:before {
  content: '-'; }

.icon-fontawesome-webfont-75:before {
  content: '.'; }

.icon-fontawesome-webfont-76:before {
  content: '/'; }

.icon-fontawesome-webfont-77:before {
  content: ':'; }

.icon-fontawesome-webfont-78:before {
  content: ';'; }

.icon-fontawesome-webfont-79:before {
  content: '<'; }

.icon-fontawesome-webfont-80:before {
  content: '='; }

.icon-fontawesome-webfont-81:before {
  content: '>'; }

.icon-fontawesome-webfont-82:before {
  content: '?'; }

.icon-fontawesome-webfont-86:before {
  content: '^'; }

.icon-fontawesome-webfont-87:before {
  content: '_'; }

.icon-fontawesome-webfont-88:before {
  content: '`'; }

.icon-fontawesome-webfont-89:before {
  content: '{'; }

.icon-fontawesome-webfont-90:before {
  content: '|'; }

.icon-fontawesome-webfont-91:before {
  content: '}'; }

.icon-fontawesome-webfont-92:before {
  content: '~'; }

/*.icon-fontawesome-webfont-93:before {
  content: '\';
}*/
.icon-fontawesome-webfont-94:before {
  content: '\e000'; }

.icon-fontawesome-webfont-95:before {
  content: '\e001'; }

.icon-fontawesome-webfont-96:before {
  content: '\e002'; }

.icon-fontawesome-webfont-97:before {
  content: '\e003'; }

.icon-fontawesome-webfont-98:before {
  content: '\e004'; }

.icon-linkedin-circled:before {
  content: '\e005'; }

.icon-pinterest-circled:before {
  content: '\e006'; }

.icon-gplus-circled:before {
  content: '\e008'; }

.icon-gplus:before {
  content: '\e009'; }

.icon-cog:before {
  content: '\e00a'; }

.icon-code:before {
  content: '\e00b'; }

.icon-chart-line:before {
  content: '\e00c'; }

.icon-chart-bar:before {
  content: '\e00d'; }

.icon-check:before {
  content: '\e00e'; }

.icon-link:before {
  content: '\e00f'; }

.icon-pinterest:before {
  content: '\e010'; }

.icon-linkedin:before {
  content: '\e011'; }

.icon-skype:before {
  content: '\e012'; }

.icon-vimeo-circled:before {
  content: '\e013'; }

.icon-vimeo:before {
  content: '\e014'; }

.icon-vkontakte:before {
  content: '\e015'; }

.icon-user-add:before {
  content: '\e016'; }

.icon-twitter:before {
  content: '\e017'; }

.icon-twitter-circled:before {
  content: '\e018'; }

.icon-rss:before {
  content: '\e019'; }

.icon-facebook-places:before {
  content: '\e01a'; }

.icon-drupal:before {
  content: '\e01b'; }

.icon-wordpress:before {
  content: '\e01c'; }

.icon-rnm-react:before {
  content: '\e01d'; }

.icon-facebook-flat:before {
  content: '\e01e'; }

.icon-googleplus-flat:before {
  content: '\e01f'; }

.icon-linkedin-flat:before {
  content: '\e020'; }

.icon-twitter-flat:before {
  content: '\e021'; }

.icon-check-1:before {
  content: '\e022'; }

.icon-widget:before {
  content: '\e023'; }

.icon-fontawesome-webfont-13:before {
  content: 'n'; }

.icon-fontawesome-webfont-30:before {
  content: 'E'; }

.icon-fontawesome-webfont-31:before {
  content: 'F'; }

.icon-fontawesome-webfont-34:before {
  content: 'I'; }

.icon-fontawesome-webfont-99:before {
  content: '\e024'; }

.icon-fontawesome-webfont-100:before {
  content: '\e025'; }

.icon-fontawesome-webfont-101:before {
  content: '\e026'; }

.icon-fontawesome-webfont-102:before {
  content: '\e027'; }

.icon-fontawesome-webfont-103:before {
  content: '\e028'; }

.icon-fontawesome-webfont-104:before {
  content: '\e029'; }

.icon-fontawesome-webfont-105:before {
  content: '\e02a'; }

.icon-fontawesome-webfont-106:before {
  content: '\e02b'; }

.icon-fontawesome-webfont-107:before {
  content: '\e02c'; }

.icon-fontawesome-webfont-108:before {
  content: '\e02d'; }

.icon-fontawesome-webfont-109:before {
  content: '\e02e'; }

.icon-fontawesome-webfont-110:before {
  content: '\e02f'; }

.icon-fontawesome-webfont-112:before {
  content: '\e031'; }

.icon-fontawesome-webfont-113:before {
  content: '\e032'; }

.icon-fontawesome-webfont-114:before {
  content: '\e033'; }

.icon-fontawesome-webfont-115:before {
  content: '\e034'; }

.icon-fontawesome-webfont-116:before {
  content: '\e035'; }

.icon-fontawesome-webfont-117:before {
  content: '\e036'; }

.icon-fontawesome-webfont-118:before {
  content: '\e037'; }

.icon-fontawesome-webfont-119:before {
  content: '\e038'; }

.icon-fontawesome-webfont-83:before {
  content: '@'; }

.icon-fontawesome-webfont-84:before {
  content: '['; }

.icon-fontawesome-webfont-120:before {
  content: '\e039'; }

.icon-chart-pie:before {
  content: '\e03a'; }

.icon-cancel-squared:before {
  content: '\e03b'; }

.icon-cancel-circled:before {
  content: '\e03c'; }

.icon-comment:before {
  content: '\e03d'; }

.icon-heart-empty:before {
  content: '\e03e'; }

.icon-heart:before {
  content: '\e03f'; }

.icon-help-circled:before {
  content: '\e040'; }

.icon-info-circled:before {
  content: '\e041'; }

.icon-share:before {
  content: '\e042'; }

.icon-youtube-alt:before {
  content: '\e043'; }

.icon-fontawesome-webfont-18:before {
  content: 's'; }

.icon-info:before {
  content: 'G'; }

.icon-erase:before {
  content: 'H'; }

.icon-export:before {
  content: 'J'; }

.icon-globe:before {
  content: 'K'; }

.icon-magnifying-glass:before {
  content: '0'; }

.icon-social-apple:before {
  content: '6'; }

.icon-social-android:before {
  content: '7'; }

.icon-attention:before {
  content: '!'; }

.icon-back:before {
  content: ','; }

.icon-facebook:before {
  content: ']'; }

.icon-facebook-circled:before {
  content: '\e007'; }

.icon-facebook-squared:before {
  content: '\e030'; }

.icon-bubble:before {
  content: '\e044'; }

.icon-fontawesome-webfont-32:before {
  content: '\e045'; }

.icon-fontawesome-webfont-33:before {
  content: '\e046'; }

.icon-fontawesome-webfont-35:before {
  content: '\e047'; }

.icon-fontawesome-webfont-36:before {
  content: '\e048'; }

.icon-star:before {
  content: '\e049'; }

.icon-fontawesome-webfont-52:before {
  content: '\e04a'; }

@font-face {
  font-family: "sima-europe";
  src: url("fonts/sima-europe.eot");
  src: url("fonts/sima-europe.eot?#iefix") format("embedded-opentype"), url("fonts/sima-europe.woff") format("woff"), url("fonts/sima-europe.ttf") format("truetype"), url("fonts/sima-europe.svg#sima-europe") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "sima-europe" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "sima-europe" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-phone:before {
  content: "\e000"; }

.icon-search:before {
  content: "\e001"; }

.icon-usp-communication:before {
  content: "\e002"; }

.icon-usp-nature:before {
  content: "\e003"; }

.icon-usp-unique:before {
  content: "\e004"; }

.icon-usp-timing:before {
  content: "\e005"; }

.icon-usp-quality:before {
  content: "\e006"; }

.icon-usp-wish:before {
  content: "\e007"; }

.icon-arrow-right:before {
  content: "\e008"; }

/* Generated by Font Squirrel (https://www.fontsquirrel.com) on November 7, 2016 */
@font-face {
  font-family: 'alpine_scriptmedium';
  src: url("fonts/alpine_script.woff2") format("woff2"), url("fonts/alpine_script.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'black_coffee_shadowbold';
  src: url("fonts/black-coffee-shadow.woff2") format("woff2"), url("fonts/black-coffee-shadow.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'black_coffee_regularbold';
  src: url("fonts/black-coffee.woff2") format("woff2"), url("fonts/black-coffee.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'bpreplayregular';
  src: url("fonts/bpreplay.woff2") format("woff2"), url("fonts/bpreplay.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'bpreplaybold';
  src: url("fonts/bpreplaybold.woff2") format("woff2"), url("fonts/bpreplaybold.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'bpreplaybold_italic';
  src: url("fonts/bpreplaybolditalics-webfont.woff2") format("woff2"), url("fonts/bpreplaybolditalics-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'bpreplayitalic';
  src: url("fonts/bpreplayitalics-webfont.woff2") format("woff2"), url("fonts/bpreplayitalics-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'fh_inkregular';
  src: url("fonts/fh_ink-webfont.woff2") format("woff2"), url("fonts/fh_ink-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'hammers.and.stringsmedium';
  src: url("fonts/hammers.and.strings.woff2") format("woff2"), url("fonts/hammers.and.strings.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'taramdaregular';
  src: url("fonts/taramda.woff2") format("woff2"), url("fonts/taramda.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

*,
*:after,
*:before {
  box-sizing: border-box; }

.panel-flexible .panels-flexible-row,
.row {
  width: 1024px;
  max-width: 100%;
  margin: 0 auto; }
  .panel-flexible .panels-flexible-row .panel-flexible .panels-flexible-row,
  .panel-flexible .panels-flexible-row .row,
  .row .panel-flexible .panels-flexible-row,
  .row .row {
    width: 100%;
    max-width: none;
    min-width: 0;
    margin: 0 -15px; }
  .panel-flexible .panels-flexible-row.collapse,
  .row.collapse {
    width: 100%; }
    .panel-flexible .panels-flexible-row.collapse .column,
    .panel-flexible .panels-flexible-row.collapse .columns,
    .row.collapse .column,
    .row.collapse .columns {
      padding: 0; }
  .panel-flexible .panels-flexible-row .row,
  .row .row {
    width: 100%;
    max-width: none;
    min-width: 0;
    margin: 0 auto; }
    .panel-flexible .panels-flexible-row .row.collapse,
    .row .row.collapse {
      margin: 0; }

.column,
.columns,
.panel-flexible .panels-flexible-region.columns {
  float: left;
  padding: 0 15px;
  position: relative; }
  @media screen and (max-width: 767px) {
    .column,
    .columns,
    .panel-flexible .panels-flexible-region.columns {
      padding: 0 30px !important; } }
  .column.centered,
  .columns.centered,
  .panel-flexible .panels-flexible-region.columns.centered {
    float: none;
    margin: 0 auto; }

.row.banner,
.row.bg-gray,
.person-info,
.slider.container .row,
.row.book-row {
  width: 100% !important;
  padding: 0; }
  .row.banner .columns.twelve, .row.bg-gray .columns.twelve,
  .person-info .twelve.columns,
  .person-info .row .columns.twelve, .row
  .person-info .columns.twelve,
  .slider.container .row .columns.twelve,
  .row.book-row .twelve.columns, .row.book-row .columns.twelve {
    padding: 0; }

.row.bg-gray .pane-references {
  width: 1024px;
  margin: 0 auto; }

@media screen and (max-width: 1023px) {
  .row.banner,
  .row.bg-gray,
  .person-info {
    width: 100% !important; }
    .row.banner .columns.twelve, .row.bg-gray .columns.twelve,
    .person-info .twelve.columns,
    .person-info .row .columns.twelve, .row
    .person-info .columns.twelve {
      padding: 0; }
  .row.bg-gray .pane-references {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px; }
  .panel-flexible .panels-flexible-row .row, .row .row {
    width: 100%;
    margin: 0 !important; } }

[class*='column'] + [class*='column'].end {
  float: left; }

.one, .row .one {
  width: 8.33333%; }

.two, .row .two {
  width: 16.66667%; }

.three, .row .three {
  width: 25%; }

.four, .row .four {
  width: 33.33333%; }

.five, .row .five {
  width: 41.66667%; }

.six, .row .six {
  width: 50%; }

.seven, .row .seven {
  width: 58.33333%; }

.eight, .row .eight {
  width: 66.66667%; }

.nine, .row .nine {
  width: 75%; }

.ten, .row .ten {
  width: 83.33333%; }

.eleven, .row .eleven {
  width: 91.66667%; }

.twelve, .row .twelve {
  width: 100%; }

.row .offset-by-one {
  margin-left: 8.33333%; }

.row .offset-by-two {
  margin-left: 16.66667%; }

.row .offset-by-three {
  margin-left: 25%; }

.row .offset-by-four {
  margin-left: 33.33333%; }

.row .offset-by-five {
  margin-left: 41.66667%; }

.row .offset-by-six {
  margin-left: 50%; }

.row .offset-by-seven {
  margin-left: 58.33333%; }

.row .offset-by-eight {
  margin-left: 66.66667%; }

.row .offset-by-nine {
  margin-left: 75%; }

.row .offset-by-ten {
  margin-left: 83.33333%; }

.panels-flexible-row {
  padding-bottom: 0 !important; }

.column.centered {
  margin-left: auto;
  margin-right: auto;
  float: none; }

@media screen and (max-width: 424px) {
  .left,
  .right {
    float: none; }
  body {
    width: 100%;
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; }
  .row {
    width: auto;
    min-width: 0;
    margin-left: 0;
    margin-right: 0; }
  .column,
  .columns {
    width: 100% !important;
    float: none; }
  .column:last-child,
  .columns:last-child {
    float: none; }
  [class*='column'] + [class*='column']:last-child {
    float: none; }
  .column:after,
  .column:before,
  .columns:after,
  .columns:before {
    content: '';
    display: table; }
  .column:after,
  .columns:after {
    clear: both; }
  .offset-by-one, .offset-by-two, .offset-by-three, .offset-by-four, .offset-by-five, .offset-by-six, .offset-by-seven, .offset-by-eight, .offset-by-nine, .offset-by-ten {
    margin-left: 0 !important; }
  .push-two, .push-three, .push-four, .push-five, .push-six, .push-seven, .push-eight, .push-nine, .push-ten {
    left: auto; }
  .pull-two, .pull-three, .pull-four, .pull-five, .pull-six, .pull-seven, .pull-eight, .pull-nine, .pull-ten {
    right: auto; }
  /* Mobile 4-column Grid */
  .row .mobile-one {
    width: 25% !important;
    float: left;
    padding: 0 15px; }
    .row .mobile-one:last-child {
      float: right; }
    .row .mobile-one.end {
      float: left; }
  .row.collapse .mobile-one {
    padding: 0; }
  .row .mobile-two {
    width: 50% !important;
    float: left;
    padding: 0 15px; }
    .row .mobile-two:last-child {
      float: right; }
    .row .mobile-two.end {
      float: left; }
  .row.collapse .mobile-two {
    padding: 0; }
  .row .mobile-three {
    width: 75% !important;
    float: left;
    padding: 0 15px; }
    .row .mobile-three:last-child {
      float: right; }
    .row .mobile-three.end {
      float: left; }
  .row.collapse .mobile-three {
    padding: 0; }
  .row .mobile-four {
    width: 100% !important;
    float: left;
    padding: 0 15px; }
    .row .mobile-four:last-child {
      float: right; }
    .row .mobile-four.end {
      float: left; }
  .row.collapse .mobile-four {
    padding: 0; }
  .push-one-mobile {
    left: 25%; }
  .pull-one-mobile {
    right: 25%; }
  .push-two-mobile {
    left: 50%; }
  .pull-two-mobile {
    right: 50%; }
  .push-three-mobile {
    left: 75%; }
  .pull-three-mobile {
    right: 75%; } }

.npl {
  padding-left: 0px !important; }

.npr {
  padding-right: 0px !important; }

@media screen and (max-width: 767px) {
  .col {
    width: 100% !important; } }

.vp {
  display: none !important; }

.vt {
  display: none !important; }

.hd {
  display: none !important; }

.vd {
  display: inherit !important; }

.np,
.npd {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.npl,
.npld {
  padding-left: 0px !important; }

.npr,
.nprd {
  padding-right: 0px !important; }

.npl {
  padding-left: 0px !important; }

.npr {
  padding-right: 0px !important; }

@media screen and (max-width: 1023px) {
  .panel-flexible .panels-flexible-row,
  .row {
    width: 1024px;
    margin: 0 auto; }
    .panel-flexible .panels-flexible-row .panel-flexible .panels-flexible-row,
    .panel-flexible .panels-flexible-row .row,
    .row .panel-flexible .panels-flexible-row,
    .row .row {
      width: auto;
      margin: 0 -15px; }
    .panel-flexible .panels-flexible-row .row,
    .row .row {
      width: 100%;
      margin: 0 -15px; }
  .hd {
    display: inherit !important; }
  .vd {
    display: none !important; }
  .vt {
    display: inherit !important; }
  .ht {
    display: none !important; }
  .npld,
  .nprd {
    padding: 0 15px !important; } }

@media screen and (max-width: 767px) {
  .hd {
    display: inherit !important; }
  .vd {
    display: none !important; }
  .vp {
    display: inherit !important; }
  .hp {
    display: none !important; }
  .npd,
  .npld,
  .nprd {
    padding: 0 15px !important; }
  .nplm,
  .npm {
    padding-left: 0px !important; }
  .npm,
  .nprm {
    padding-right: 0px !important; } }

.slider .columns {
  padding: 0 !important; }

/* Requires
   globals.css */
/* Standard Forms ---------------------- */
form {
  margin: 0 0 16px; }

.row form .row {
  margin: 0 -8px; }
  .row form .row .column,
  .row form .row .columns {
    padding: 0 8px; }
  .row form .row.collapse {
    margin: 0; }
    .row form .row.collapse .column,
    .row form .row.collapse .columns {
      padding: 0; }

label {
  font-size: ms(0);
  color: #496272;
  cursor: pointer;
  display: block;
  padding: 5px 0;
  font-weight: 500;
  margin-bottom: 3px; }
  label.right {
    float: none;
    text-align: right; }
  label.inline {
    line-height: ms(0)24px;
    margin: 0 0 16px; }

.postfix,
.prefix {
  display: block;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  height: ms(0)24px;
  line-height: ms(0)24px-1; }

a.button.postfix,
a.button.prefix {
  padding-left: 0;
  padding-right: 0;
  text-align: center; }

span.postfix,
span.prefix {
  background: #f2f2f2;
  border: 1px solid #cccccc; }

.prefix {
  left: 2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  overflow: hidden; }

.postfix {
  right: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

input[type='date'],
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
textarea {
  background-color: #fff;
  font-family: inherit;
  border: 1px solid #cccccc;
  border-radius: 0;
  color: #496272;
  display: block;
  font-size: 14px;
  margin: 0 0 16px;
  padding: 8px;
  height: ms(0)24px;
  width: 100%;
  transition: all 0.15s linear; }
  input[type='date'].oversize,
  input[type='datetime'].oversize,
  input[type='email'].oversize,
  input[type='number'].oversize,
  input[type='password'].oversize,
  input[type='search'].oversize,
  input[type='tel'].oversize,
  input[type='text'].oversize,
  input[type='time'].oversize,
  input[type='url'].oversize,
  textarea.oversize {
    font-size: ms(1);
    padding: 6px 8px; }
  input[type='date']:focus,
  input[type='datetime']:focus,
  input[type='email']:focus,
  input[type='number']:focus,
  input[type='password']:focus,
  input[type='search']:focus,
  input[type='tel']:focus,
  input[type='text']:focus,
  input[type='time']:focus,
  input[type='url']:focus,
  textarea:focus {
    background: #fafafa;
    border-color: #b3b3b3; }
  input[type='date'][disabled],
  input[type='datetime'][disabled],
  input[type='email'][disabled],
  input[type='number'][disabled],
  input[type='password'][disabled],
  input[type='search'][disabled],
  input[type='tel'][disabled],
  input[type='text'][disabled],
  input[type='time'][disabled],
  input[type='url'][disabled],
  textarea[disabled] {
    background-color: #ddd; }

textarea {
  height: auto; }

select {
  width: 100%; }

fieldset {
  border: solid 1px #ddd;
  border-radius: 3px;
  padding: 12px;
  margin: 18px 0; }
  fieldset legend {
    font-weight: bold;
    background: #fff;
    padding: 0 3px;
    margin: 0;
    margin-left: -3px; }

/* Errors */
.error input,
.error input:focus,
.error textarea,
.error textarea:focus,
input.error,
input.error:focus,
textarea.error,
textarea.error:focus {
  border-color: #c60f13;
  background-color: rgba(198, 15, 19, 0.1); }

.error input:focus,
.error textarea:focus,
input.error:focus,
textarea.error:focus {
  outline-color: #f5797c; }

.error label,
label.error {
  color: #c60f13; }

.error small,
small.error {
  display: block;
  padding: 6px 4px;
  margin-top: -17px;
  margin-bottom: 16px;
  background: #c60f13;
  color: #fff;
  font-size: ms(0)-2;
  font-weight: bold;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* Correct FF custom dropdown height */
@-moz-document url-prefix() {
  form.custom div.custom.dropdown a.selector {
    height: 28px; } }

.lt-ie9 form.custom div.custom.dropdown a.selector {
  height: 28px; }

.scrolled .region-top-menu {
  display: none; }

.scrolled #logo img {
  width: 80px; }

.scrolled .hoofdmenu {
  padding-top: 15px; }

.region-top-menu {
  float: right;
  margin: 30px 0 0; }
  .region-top-menu .block {
    margin: 0;
    float: left; }
  .region-top-menu ul {
    list-style: none !important;
    margin-bottom: 0; }
    .region-top-menu ul li {
      list-style: none !important;
      float: left; }
      .region-top-menu ul li a {
        color: #333333;
        padding: 0 10px;
        font-size: 10px; }
  @media screen and (max-width: 767px) {
    .region-top-menu {
      display: none; } }
  .region-top-menu .language-switcher-locale-url {
    padding: 7px 15px; }
    .region-top-menu .language-switcher-locale-url li a {
      font-size: 16px;
      line-height: 19px; }
      .region-top-menu .language-switcher-locale-url li a.active {
        color: #00A1BD; }

.hoofdmenu {
  transition-property: padding;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  z-index: 1;
  position: relative;
  background: #fff;
  float: right;
  width: 100%; }
  .hoofdmenu ul {
    float: right;
    margin: 0; }
    .hoofdmenu ul li {
      list-style: none !important;
      float: left;
      position: relative; }
      .hoofdmenu ul li:hover ul {
        display: block; }
      .hoofdmenu ul li a {
        display: block;
        float: none;
        color: #496272;
        font-weight: normal;
        border-right: 0;
        padding: 10px 16px;
        font-size: 15px;
        text-align: center;
        white-space: nowrap;
        text-decoration: none; }
        .hoofdmenu ul li a.active, .hoofdmenu ul li a.is-active-trail {
          color: #D60521; }
        .hoofdmenu ul li a:hover {
          color: #00A1BD; }
      .hoofdmenu ul li:last-child a {
        padding-right: 0; }
      .hoofdmenu ul li ul {
        width: auto;
        display: none;
        position: absolute;
        left: 0;
        top: 44px;
        background: #fff; }
        .hoofdmenu ul li ul li {
          display: block;
          float: none;
          width: auto;
          white-space: nowrap;
          border: none; }
          .hoofdmenu ul li ul li a {
            border-radius: 0px !important;
            border: none;
            padding: 10px 15px;
            text-align: center;
            width: 100%;
            text-align: left; }

.mobile-menu {
  display: none; }

.mobile-menu-button {
  display: none;
  border: none;
  float: right;
  margin: 20px 0 0;
  line-height: 40px;
  background: #00A1BD;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  padding: 0; }
  .mobile-menu-button:after {
    content: '@';
    font-family: 'basis';
    font-size: 26px;
    color: #fff;
    margin: 0 auto;
    display: inline-block; }

.mobile-menu-button:hover {
  text-decoration: none; }

@media screen and (max-width: 767px) {
  .hoofdmenu {
    display: none; }
  .mobile-menu-button {
    display: block; }
  .mm-menu.mm-right {
    background: #fff !important; }
  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    margin: 0 !important;
    border-color: #EFEFEF !important; }
  .mm-list > li > a,
  .mm-list > li > span {
    color: #000 !important; }
    .mm-list > li > a.active,
    .mm-list > li > span.active {
      color: #00A1BD !important; }
  .search-mobile form {
    padding: 0 20px !important; }
  .search-mobile input[type='text'] {
    background: rgba(0, 0, 0, 0.15);
    border: none;
    color: #fff; }
  .search-mobile input[type='submit'] {
    position: absolute;
    top: 5px;
    right: 5px;
    font-family: 'basis';
    background: none;
    color: #fff;
    border: none;
    font-size: 24px; }
  .mm-list > li > a,
  .mm-list > li > span {
    color: #333; } }

.footer {
  margin: 20px 0 0;
  padding: 40px 0 50px;
  border-top: 1px solid #e6edf2;
  background: url("/sites/default/files/afbeeldingen/slider/footer-bg.gif") no-repeat;
  background-size: cover; }
  .footer .footerblok {
    vertical-align: bottom;
    display: inline-block;
    float: none; }
  .footer .footerblok1 {
    width: 13% !important;
    padding: 0 !important; }
    .footer .footerblok1 p {
      padding-top: 30px;
      text-align: center;
      padding-bottom: 0; }
  .footer .footerblok2 {
    width: 22% !important; }
    .footer .footerblok2 p:last-child {
      padding: 0; }
    .footer .footerblok2 h4 {
      font-size: 24px;
      margin-bottom: 0px; }
  .footer .footerblok3 {
    width: 38%;
    padding: 0 5% 0 10px !important; }
    .footer .footerblok3 form {
      margin: 0; }
    .footer .footerblok3 .webform-client-form .form-item {
      display: inline-block;
      width: 85%;
      margin-bottom: 0; }
      .footer .footerblok3 .webform-client-form .form-item input {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border: 0px; }
    .footer .footerblok3 .form-actions {
      display: inline-block;
      width: 15%; }
    .footer .footerblok3 .webform-client-form .btn, .footer .footerblok3 .webform-client-form .form-submit {
      margin: 0; }
    .footer .footerblok3 .btn, .footer .footerblok3 .form-submit {
      padding: 8px 10px;
      font-size: 14px; }
    .footer .footerblok3 .form-submit {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; }
    .footer .footerblok3 h3 {
      font-family: 'bpreplaybold';
      margin-bottom: 0;
      font-size: 24px; }
    .footer .footerblok3 .intro {
      font-size: 24px;
      padding-bottom: 15px !important; }
  .footer .footerblok4 {
    padding: 0 5px !important; }
    .footer .footerblok4 h2 {
      font-size: 24px;
      margin-bottom: 20px; }
    .footer .footerblok4 p {
      padding: 0; }
    .footer .footerblok4 a img {
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease; }
    .footer .footerblok4 a:hover img {
      transform: rotate(25deg); }
  @media screen and (max-width: 1023px) {
    .footer {
      background-size: cover !important; }
      .footer .footerblok1,
      .footer .footerblok2,
      .footer .footerblok3,
      .footer .footerblok4 {
        float: left !important;
        padding: 0 15px !important; }
        .footer .footerblok1 .columns,
        .footer .footerblok2 .columns,
        .footer .footerblok3 .columns,
        .footer .footerblok4 .columns {
          padding: 0 !important; }
      .footer .footerblok1 {
        width: 33.333% !important;
        float: left !important; }
      .footer .footerblok3 .webform-client-form .form-item {
        width: calc(100% - 53px); }
      .footer .footerblok3 .form-actions {
        width: 53px; }
      .footer .footerblok2,
      .footer .footerblok3,
      .footer .footerblok4 {
        width: 66.666% !important;
        float: right !important; } }
  @media screen and (max-width: 767px) {
    .footer {
      background: url("/sites/default/files/afbeeldingen/slider/footer-bg.gif"); }
      .footer .footerblok {
        padding: 0 30px !important;
        width: 100% !important; }
      .footer .footerblok3 .columns {
        padding: 0 !important; } }
  .footer a,
  .footer p {
    color: #fff;
    padding-bottom: 2px;
    font-weight: bold; }
  .footer a {
    padding: 0; }
  .footer h2,
  .footer h3,
  .footer h4,
  .footer h5,
  .footer h6 {
    color: #fff;
    font-size: 16px; }
  .footer ul {
    list-style-position: inside;
    list-style-type: none; }
    .footer ul li {
      color: #fff; }
      .footer ul li a {
        color: #fff; }
  .footer .social-media {
    margin: 0 0 20px;
    float: left;
    width: 100%; }
    .footer .social-media ul li a {
      float: left;
      margin: 0 0 5px; }
      .footer .social-media ul li a:before {
        font-family: "sima-europe";
        float: left;
        margin-right: 5px;
        font-size: 22px;
        line-height: 22px; }
      .footer .social-media ul li a.facebook:before {
        content: '\e007';
        color: #3b5998; }
      .footer .social-media ul li a.twitter:before {
        content: '\e018';
        color: #55acee; }
      .footer .social-media ul li a.linkedin:before {
        content: '\e005';
        color: #0077b5; }
      .footer .social-media ul li a.googleplus:before {
        content: '\e008';
        color: #dd4b39; }
      .footer .social-media ul li a.pinterest:before {
        content: '\e006';
        color: #cc2127; }
      .footer .social-media ul li a.youtube:before {
        content: '\e043';
        color: #cd201f; }

.footer-bottom {
  padding: 5px 0 10px; }
  .footer-bottom .block {
    margin: 0; }
  .footer-bottom ul {
    margin: 7px 0 0;
    list-style-position: inside; }
    .footer-bottom ul li {
      list-style-type: none;
      display: inline;
      margin-left: 15px;
      padding-left: 15px;
      border-left: 1px solid #73838f;
      line-height: 10px;
      display: block;
      float: left; }
      .footer-bottom ul li:first-child {
        border: 0;
        margin: 0;
        padding: 0; }
      .footer-bottom ul li a,
      .footer-bottom ul li a.active {
        color: #00A1BD;
        font-size: 10px; }

.front .footer {
  border: none; }

span.icon-rnm-react {
  font-size: 20px;
  float: left;
  margin: 0; }

.react-online {
  color: #73838f;
  font-size: 11px;
  float: right; }

@media screen and (max-width: 1023px) {
  .footer {
    background-size: auto; } }

@media screen and (max-width: 767px) {
  .footer h3,
  .footer h4 {
    padding-bottom: 0; }
  .footer .region-footerblok-1 ul li {
    width: 100%; }
  .footer .footerblok1,
  .footer .footerblok2,
  .footer .footerblok3,
  .footer .footerblok4 {
    width: 50%; }
  .footer .footerblok3 .columns {
    padding: 0; }
  .footer-bottom .columns.left {
    float: left; }
  .footer-bottom .columns.right {
    float: right; }
  .footer-bottom ul li {
    padding: 0;
    margin: 0 0 0 20px;
    border: 0; }
    .footer-bottom ul li a {
      line-height: 24px; }
  .react-online-media {
    text-indent: -9999px;
    line-height: 30px; }
  span.icon-rnm-react {
    text-indent: 0; } }

@media screen and (max-width: 424px) {
  .footer {
    padding-top: 20px;
    padding-bottom: 0; }
    .footer .footerblok1 {
      display: none; } }

/*Standaard styling Drupal overwrite*/
.footer-bottom .menu__item.is-leaf,
.footer-bottom ul li a {
  list-style-image: none;
  list-style-type: none; }

.content-top {
  margin-top: 0; }

.admin-menu .header {
  top: 59px; }

.header {
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  border-bottom: #f2f3f4 1px solid; }
  @media screen and (max-width: 767px) {
    .header .columns {
      width: 100%; } }
  .header .nine.columns {
    padding-top: 10px; }

.front .container.slider {
  padding-top: 170px; }

.not-front .container.content {
  padding-top: 170px; }

@media screen and (max-width: 767px) {
  .front .container.slider {
    padding-top: 103px; }
  .not-front .container.content {
    padding-top: 103px; } }

#logo img {
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  width: 150px; }
  @media screen and (max-width: 767px) {
    #logo img {
      width: 80px; } }

.block.phonenumber .infoblok {
  padding: 7px 15px; }
  .block.phonenumber .infoblok p a {
    font-size: 16px;
    line-height: 19px;
    color: #00A1BD; }
    .block.phonenumber .infoblok p a span:before {
      font-size: 16px;
      top: 2px;
      position: relative; }

.block.zoekveld #search-api-page-search-form-search {
  position: relative; }

.block.zoekveld input[type='text'] {
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 0;
  padding: 7px;
  padding-left: 10px; }

.block.zoekveld #edit-keys-1,
.block.zoekveld input.form-autocomplete {
  background: url("../images/ajax-loader.gif") right 50px no-repeat; }

.block.zoekveld #edit-keys-1.throbbing,
.block.zoekveld input.throbbing {
  background-position: 84% 10px; }

.block.zoekveld .form-item:after {
  content: "\e001";
  font-family: "sima-europe";
  position: absolute;
  right: 5px;
  z-index: 1;
  font-size: 24px;
  color: #496272;
  opacity: 0.52; }

.block.zoekveld input[type='submit'] {
  background: none;
  border: none;
  position: absolute;
  top: 1px;
  right: 1px;
  color: #666;
  font-size: 20px;
  padding: 4px 10px;
  margin: 0; }
  .block.zoekveld input[type='submit']:hover {
    cursor: pointer; }

.block.zoekveld .form-text {
  float: right;
  min-width: 152px; }

@media screen and (max-width: 768px) {
  .block.zoekveld {
    display: none; } }

#autocomplete {
  border: 1px solid #ddd;
  border-top: 0;
  width: 300px !important; }

#autocomplete ul li {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd; }

#autocomplete ul li:hover {
  background: #ddd;
  cursor: pointer; }

@media screen and (max-width: 767px) {
  .search-form {
    display: none; } }

.overzicht:hover a {
  opacity: 0.8; }

.overzicht a {
  display: block;
  margin-bottom: 30px; }
  .overzicht a img {
    transition: all 0.4s ease; }
  .overzicht a .views-field-field-afbeelding .field-content,
  .overzicht a .views-field-field-afbeeldingen .field-content {
    overflow: hidden;
    display: block;
    line-height: 0; }
  .overzicht a .views-field-title .field-content {
    color: #000;
    font-size: 18px;
    padding: 10px 0;
    display: block; }
  .overzicht a:hover {
    text-decoration: none;
    opacity: 1 !important; }
    .overzicht a:hover img {
      transform: scale(1.1); }

.infowrap {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 20px; }
  .infowrap .icoon {
    text-align: center;
    padding: 15px; }
    .infowrap .icoon .icon:before {
      display: inline-block;
      float: none;
      font-size: 34px;
      vertical-align: middle; }
      .large .infowrap .icoon .icon:before {
        margin: 0 0 10px;
        clear: both;
        line-height: initial;
        font-size: 86px; }
    .infowrap .icoon p {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 19px;
      line-height: 20px;
      vertical-align: middle; }
      .front .infowrap .icoon p {
        font-size: 22px;
        line-height: 30px; }
  .infowrap.border {
    border: 1px solid #C6C8CA; }
  .infowrap.imageleft .image {
    width: 30%;
    float: left;
    min-height: 150px;
    background-size: cover !important;
    background-position: center !important; }
  .infowrap.imageleft .infoblok {
    width: 70%;
    float: left; }
  .infowrap.imagetop .image {
    width: 100%;
    float: left;
    min-height: 170px;
    background-size: cover !important;
    background-position: center !important; }
  .infowrap.imagetop .infoblok {
    width: 100%;
    float: left; }
  .infowrap.linkwithbutton:after {
    content: '>';
    color: #fff;
    background: #a7ea7f;
    float: left;
    font-size: 22px;
    font-family: 'greendealscholen';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    transition: all 0.4s ease; }
  .infowrap.linkwithbutton:hover:after {
    background: #000; }
  .infowrap:hover {
    text-decoration: none !important; }

div.infoblok {
  float: left;
  width: 100%;
  padding: 30px;
  background-size: cover !important;
  background-position: center !important; }
  div.infoblok h2 {
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 25px; }
  div.infoblok p {
    padding-bottom: 0;
    font-size: 14px;
    /* font-weight: 400; */
    line-height: 20px; }
  div.infoblok a.btn {
    margin-bottom: 0; }
  div.infoblok.kleur-2ba6cb a,
  div.infoblok.kleur-2ba6cb blockquote,
  div.infoblok.kleur-2ba6cb blockquote p:before,
  div.infoblok.kleur-2ba6cb h1,
  div.infoblok.kleur-2ba6cb h2,
  div.infoblok.kleur-2ba6cb h3,
  div.infoblok.kleur-2ba6cb h4,
  div.infoblok.kleur-2ba6cb h5,
  div.infoblok.kleur-2ba6cb h6,
  div.infoblok.kleur-2ba6cb ol,
  div.infoblok.kleur-2ba6cb p,
  div.infoblok.kleur-2ba6cb ul, div.infoblok.kleur-e3e3e3 a,
  div.infoblok.kleur-e3e3e3 blockquote,
  div.infoblok.kleur-e3e3e3 blockquote p:before,
  div.infoblok.kleur-e3e3e3 h1,
  div.infoblok.kleur-e3e3e3 h2,
  div.infoblok.kleur-e3e3e3 h3,
  div.infoblok.kleur-e3e3e3 h4,
  div.infoblok.kleur-e3e3e3 h5,
  div.infoblok.kleur-e3e3e3 h6,
  div.infoblok.kleur-e3e3e3 ol,
  div.infoblok.kleur-e3e3e3 p,
  div.infoblok.kleur-e3e3e3 ul, div.infoblok.kleur-f2f2f2 a,
  div.infoblok.kleur-f2f2f2 blockquote,
  div.infoblok.kleur-f2f2f2 blockquote p:before,
  div.infoblok.kleur-f2f2f2 h1,
  div.infoblok.kleur-f2f2f2 h2,
  div.infoblok.kleur-f2f2f2 h3,
  div.infoblok.kleur-f2f2f2 h4,
  div.infoblok.kleur-f2f2f2 h5,
  div.infoblok.kleur-f2f2f2 h6,
  div.infoblok.kleur-f2f2f2 ol,
  div.infoblok.kleur-f2f2f2 p,
  div.infoblok.kleur-f2f2f2 ul {
    color: #fff; }
  div.infoblok.kleur-2ba6cb .btn, div.infoblok.kleur-e3e3e3 .btn, div.infoblok.kleur-f2f2f2 .btn {
    background: #fff;
    color: #D60521; }
  div.infoblok.kleur-2ba6cb .btn.rand, div.infoblok.kleur-e3e3e3 .btn.rand, div.infoblok.kleur-f2f2f2 .btn.rand {
    background: transparent;
    border: 2px solid #fff;
    color: #fff; }
  div.infoblok .color-e3e3e3 {
    background: #e3e3e3; }
  div.infoblok .color-f2f2f2 {
    background: #f2f2f2; }
  div.infoblok .color-2ba6cb {
    background: #2ba6cb; }

.bg-gray {
  background: rgba(73, 98, 114, 0.1); }
  .bg-gray .pane-references {
    padding: 0 15px; }

.homeblokken {
  overflow: hidden;
  margin-bottom: 30px !important;
  padding-bottom: 30px !important; }
  .homeblokken .columns {
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    z-index: 2; }
    .homeblokken .columns:hover {
      animation: bounce1 .7s alternate;
      animation-iteration-count: 2; }
    .homeblokken .columns:first-child {
      z-index: 3; }
    .homeblokken .columns:last-child {
      z-index: 1; }
  .homeblokken .infoblok {
    transition-property: margin-top, box-shadow;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    box-shadow: 2px 2px 10px rgba(73, 98, 114, 0.4);
    margin-top: 80px;
    padding: 20px 10px; }
    .homeblokken .infoblok.done,
    .homeblokken .infoblok.done .block-string {
      animation: none; }
    .homeblokken .infoblok:hover {
      box-shadow: 1px 1px 2px rgba(73, 98, 114, 0.4); }
    .homeblokken .infoblok .block-string {
      transition-property: top;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -40px; }
    .homeblokken .infoblok h3 {
      font-family: "black_coffee_shadowbold";
      font-size: 28px; }
    .homeblokken .infoblok p {
      font-size: 14px;
      line-height: 21px; }
      .homeblokken .infoblok p:last-child {
        padding: 10px; }
  @media screen and (max-width: 424px) {
    .homeblokken .columns {
      width: 100% !important; } }

.banner img {
  width: 100% !important; }

.icon:before {
  color: #fff;
  float: left;
  margin: 0 15px 0 0;
  line-height: 20px; }

.isotope-container,
.isotope-container .isotope-element {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s; }

.isotope-container {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width; }

.isotope-container .isotope-element {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity; }

/**** disabling Isotope CSS3 transitions ****/
.isotope .isotope-item.no-transition,
.isotope.no-transition,
.isotope.no-transition .isotope-item {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s; }

.isotope-options {
  margin: 0 !important; }

.isotope {
  margin: 20px 0 0;
  display: inline-block;
  width: 100%; }
  .isotope > .view-content {
    margin: 0 -15px; }

.isotope-container {
  margin: 0 !important;
  padding: 0 !important; }
  .isotope-container .isotope-element {
    width: 25%;
    padding: 0 15px; }
    .drie .isotope-container .isotope-element {
      width: 33.333333333333333%; }
    @media screen and (max-width: 767px) {
      .isotope-container .isotope-element {
        float: left !important;
        width: 50% !important; }
        .isotope-container .isotope-element.big, .isotope-container .isotope-element.middel {
          width: 100% !important; } }
    @media screen and (max-width: 424px) {
      .isotope-container .isotope-element {
        float: left !important;
        width: 100% !important; } }

.taxonomy-widget {
  padding: 30px;
  background: #efefef;
  margin-bottom: 30px; }
  .taxonomy-widget h2 {
    margin-top: 0;
    color: #001928;
    font-size: 20px; }
  .taxonomy-widget ul li {
    list-style-type: square; }
    .taxonomy-widget ul li a {
      color: #000;
      text-decoration: none; }
      .taxonomy-widget ul li a.active {
        font-weight: 600; }
      .taxonomy-widget ul li a:hover {
        opacity: 0.5; }

a.anchor {
  padding-top: 180px;
  margin-top: -180px;
  float: left;
  width: 100%;
  display: block !important;
  visibility: hidden !important; }

.messages {
  float: left;
  width: 100%;
  clear: both; }

.snelmailer-widget {
  float: left;
  width: 100%;
  margin: 15px 0; }
  .snelmailer-widget .btn {
    font-size: 12px; }
  .snelmailer-widget .container-error {
    display: none !important; }

.views-exposed-form label {
  text-align: center;
  font-size: 20px;
  font-weight: 400; }

.views-exposed-form .form-radios {
  text-align: center; }

.views-exposed-form .views-exposed-widget {
  width: 100%; }
  .views-exposed-form .views-exposed-widget input[type='radio'] {
    display: none; }
  .views-exposed-form .views-exposed-widget input[type=radio]:checked + label {
    background: #3778ba;
    color: #fff;
    border: 1px solid #3778ba; }
  .views-exposed-form .views-exposed-widget .form-item {
    float: none;
    display: inline-block; }
  .views-exposed-form .views-exposed-widget .option {
    float: left;
    padding: 8px 20px;
    min-width: 180px;
    background: #fff;
    border-radius: 5px;
    text-align: center;
    margin: 10px 10px 0;
    color: #80b8f1;
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    border: 1px solid #80b8f1; }
    .views-exposed-form .views-exposed-widget .option:hover {
      background: #80b8f1;
      color: #fff; }

.pager {
  margin: 30px 0 0 !important;
  padding: 20px 0; }
  .pager li {
    background: none !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: 0 !important; }
  .pager .pager-item {
    padding: 0 !important; }
  .pager .pager-current,
  .pager .pager-item a {
    padding: 0 !important;
    text-align: center;
    border-radius: 2px;
    height: 18px;
    width: 18px;
    display: inline-block;
    line-height: 17px;
    color: rgba(44, 98, 113, 0.4);
    font-size: 16px; }
  .pager .pager-current {
    font-weight: 400;
    color: #D60521; }
  .pager .pager-item a {
    color: rgba(44, 98, 113, 0.4); }
  .pager .pager-next a,
  .pager .pager-previous a {
    color: rgba(44, 98, 113, 0.4);
    font-size: 16px;
    position: relative;
    top: -1px; }

.homeblok {
  background: #f1f1f1;
  float: left;
  width: 100%;
  padding: 15px; }

x.sidebar {
  background: #e5e1d1;
  padding: 10px 20px; }

x.sidebar ul {
  list-style: inside; }

x.sidebar ul li {
  border-bottom: 1px solid rgba(73, 98, 114, 0.07);
  padding: 5px 10px;
  list-style-type: none; }

.ajax-progress {
  position: absolute;
  left: 50%;
  bottom: 0; }

.ajax-progress .throbber {
  background: url("../images/ajax-loader.gif");
  position: absolute;
  height: 11px;
  width: 16px;
  margin: 2px;
  z-index: 10; }

:focus {
  outline: none; }

/* Blauwe rand om menu weghalen*/
.anchor {
  display: block !important;
  content: " " !important;
  margin-top: -200px !important;
  height: 200px !important;
  visibility: hidden !important; }

a {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease; }

.media_embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden; }

.media_embed.widescreen {
  padding-bottom: 56.34%; }

.media_embed.vimeo {
  padding-top: 0; }

.media_embed embed,
.media_embed iframe,
.media_embed object,
.media_embed video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

img {
  height: auto; }

embed,
img,
object {
  max-width: 100%; }

img {
  -ms-interpolation-mode: bicubic; }

#map_canvas img,
.map_canvas img {
  max-width: none !important; }

.usp-list {
  border-top: 1px solid rgba(73, 98, 114, 0.07);
  border-bottom: 1px solid rgba(73, 98, 114, 0.07);
  padding: 30px 0 !important; }
  .usp-list h2 {
    margin-top: 0; }
  .usp-list .usp {
    text-transform: uppercase;
    margin-top: 20px;
    padding: 0 5px; }
    .usp-list .usp p a {
      color: rgba(44, 98, 113, 0.4); }
    .usp-list .usp .usp-icon:before {
      font-size: 120px; }
    .usp-list .usp p a,
    .usp-list .usp .usp-icon:before {
      transition-property: color;
      transition-duration: 0.3s;
      transition-timing-function: ease; }
    .usp-list .usp p:first-child a {
      transition-duration: 0s; }
    .usp-list .usp:hover p a,
    .usp-list .usp:hover .usp-icon:before {
      color: #00A1BD; }
    .usp-list .usp:hover .icon-usp-wish:before,
    .usp-list .usp:hover p a.usp-wish {
      color: #385591; }
    .usp-list .usp:hover .icon-usp-nature:before,
    .usp-list .usp:hover p a.usp-nature {
      color: #D60521; }
    .usp-list .usp:hover .icon-usp-unique:before,
    .usp-list .usp:hover p a.usp-unique {
      color: #00A1BD; }
    .usp-list .usp:hover .icon-usp-timing:before,
    .usp-list .usp:hover p a.usp-timing {
      color: #385591; }
    .usp-list .usp:hover .icon-usp-quality:before,
    .usp-list .usp:hover p a.usp-quality {
      color: #D60521; }
    .usp-list .usp:hover .icon-usp-communication:before,
    .usp-list .usp:hover p a.usp-communication {
      color: #00A1BD; }
  @media screen and (max-width: 1023px) {
    .usp-list .usp .usp-icon:before {
      font-size: 80px; } }
  @media screen and (max-width: 767px) {
    .usp-list .usp {
      width: 33.333%; } }
  @media screen and (max-width: 424px) {
    .usp-list .usp {
      width: 50% !important;
      float: right !important;
      min-height: 210px; }
      .usp-list .usp .usp-icon:before {
        font-size: 80px; } }

.page-node-122 .usp p .icon-usp-wish {
  color: #385591; }

.page-node-123 .usp p .icon-usp-nature {
  color: #D60521; }

.page-node-124 .usp p .icon-usp-unique {
  color: #00A1BD; }

.page-node-125 .usp p .icon-usp-timing {
  color: #385591; }

.page-node-126 .usp p .icon-usp-quality {
  color: #D60521; }

.page-node-127 .usp p .icon-usp-communication {
  color: #00A1BD; }

.usp-content {
  padding-top: 30px;
  padding-bottom: 30px; }

.reference {
  background: url("../images/reference-bg2.png") no-repeat;
  background-size: 100%;
  padding: 30px 15px 30px 15px;
  margin-bottom: 50px;
  overflow: hidden;
  min-height: 190px; }
  .reference:first-child {
    background: url("../images/reference-bg1.png") no-repeat;
    background-size: 100%; }
  .reference:last-child {
    background: url("../images/reference-bg3.png") no-repeat;
    background-size: 100%; }
  .reference .columns:first-child {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 0; }
  .reference .columns:last-child {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: 20px; }
  .reference .quote p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #00A1BD;
    padding-bottom: 10px; }
  .reference .referent {
    font-size: 14px;
    font-weight: bold;
    color: #00A1BD;
    display: block;
    text-align: center; }

.view-header h3 {
  margin-top: 30px;
  color: #385591;
  padding-left: 30px; }

@media screen and (max-width: 1023px) {
  .reference {
    background-size: 100%;
    padding: 30px 10px 20px 10px !important;
    margin-bottom: 50px;
    min-height: 140px; }
    .reference .columns:last-child {
      padding-top: 0px !important;
      padding-right: 15px !important; }
    .reference .quote p {
      font-size: 12px;
      line-height: 16px;
      color: #00A1BD; }
    .reference .referent {
      font-size: 10px;
      font-weight: 600;
      color: #00A1BD; } }

@media screen and (max-width: 767px) {
  .reference {
    padding-top: 10px !important;
    background-position: center center !important;
    background-size: cover !important; } }

@media screen and (max-width: 424px) {
  .reference {
    padding-top: 20px !important;
    width: 100% !important; }
    .reference .columns {
      display: inherit;
      float: left !important; }
      .reference .columns:first-child {
        width: 66.666% !important;
        padding-left: 15px !important;
        padding-right: 15px !important; }
      .reference .columns:last-child {
        width: 33.333% !important;
        padding-left: 15px !important;
        right: 0; } }

.node-type-usp .book-row {
  background: url("/sites/all/themes/front/images/approach_slider-usp_background.jpg");
  background-size: 100%;
  height: 710px;
  padding-top: 50px; }
  .node-type-usp .book-row .panels-flexible-row-inside-first {
    background: url("/sites/default/files/afbeeldingen/slider/book-bg2.png") no-repeat;
    background-size: 100%;
    height: 610px;
    width: 1024px;
    margin: 0 auto;
    overflow: hidden; }
    .node-type-usp .book-row .panels-flexible-row-inside-first .six:first-child {
      padding: 30px 50px 30px 60px; }
      .node-type-usp .book-row .panels-flexible-row-inside-first .six:first-child .usp-images {
        width: 60%;
        margin: 0 auto; }
        .node-type-usp .book-row .panels-flexible-row-inside-first .six:first-child .usp-images .even, .node-type-usp .book-row .panels-flexible-row-inside-first .six:first-child .usp-images .odd {
          border: 1px solid rgba(73, 98, 114, 0.07);
          padding: 10px 10px 30px 10px;
          transform: rotate(5deg);
          box-shadow: 0 0 3px rgba(44, 98, 113, 0.4); }
        .node-type-usp .book-row .panels-flexible-row-inside-first .six:first-child .usp-images .odd {
          margin-top: 30px;
          transform: rotate(-5deg); }
    .node-type-usp .book-row .panels-flexible-row-inside-first .six:last-child {
      padding: 50px 100px 50px 50px; }

@media screen and (max-width: 1023px) {
  .node-type-usp .book-row {
    height: 600px; }
    .node-type-usp .book-row .panels-flexible-row-inside-first {
      width: 100%;
      height: 450px; }
      .node-type-usp .book-row .panels-flexible-row-inside-first .icon-usp:before {
        font-size: 80px; }
      .node-type-usp .book-row .panels-flexible-row-inside-first .six:last-child {
        padding: 40px 70px 50px 30px; }
        .node-type-usp .book-row .panels-flexible-row-inside-first .six:last-child h1 {
          margin: 0 0 5px; }
        .node-type-usp .book-row .panels-flexible-row-inside-first .six:last-child p {
          font-size: 12px; } }

@media screen and (max-width: 767px) {
  .node-type-usp .book-row {
    height: auto; }
    .node-type-usp .book-row .pane-custom {
      text-align: center;
      background: #fff; }
    .node-type-usp .book-row .pane-node-field-usp-tekst {
      background: #fff;
      padding: 30px; }
    .node-type-usp .book-row .panels-flexible-row-inside-first {
      background: none;
      height: auto; }
      .node-type-usp .book-row .panels-flexible-row-inside-first .usp-images {
        display: none; }
      .node-type-usp .book-row .panels-flexible-row-inside-first .columns {
        width: 100% !important;
        padding: 0 30px !important; }
      .node-type-usp .book-row .panels-flexible-row-inside-first .six:first-child {
        display: none; }
      .node-type-usp .book-row .panels-flexible-row-inside-first .six:last-child p {
        font-size: 12px; } }

.back-to-overview {
  display: block;
  margin-top: 20px; }

.language-switcher {
  padding-top: 5px; }
  .language-switcher .language {
    position: relative;
    border-left: 2px solid rgba(44, 98, 113, 0.4);
    padding-left: 10px; }
    .language-switcher .language:first-child {
      border-left: 0; }
    .language-switcher .language:before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      background-size: 22px !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .language-switcher .language a {
      padding: 5px 10px 0 30px;
      font-size: 16px;
      line-height: 16px;
      display: inline-block; }
      .language-switcher .language a:hover, .language-switcher .language a.active {
        color: #D60521; }
  .language-switcher .en:before {
    background: url("/sites/all/themes/front/images/gb.png"); }
  .language-switcher .nl:before {
    background: url("/sites/all/themes/front/images/nl.png"); }
  .language-switcher .de:before {
    background: url("/sites/all/themes/front/images/de.png"); }
  .language-switcher .fr:before {
    background: url("/sites/all/themes/front/images/fr.png"); }

.front .breadcrumbs-wrapper {
  display: none; }

.breadcrumbs-wrapper {
  padding: 14px;
  background: #f2f3f4; }

.breadcrumbs a,
.breadcrumbs span {
  font-size: 14px;
  color: #476271; }

.easy-breadcrumb_segment-separator {
  padding: 0 5px; }

.front .breadcrumbs {
  display: none; }

.breadcrumbs {
  width: 1024px;
  min-width: 0;
  margin: 0 auto;
  max-width: 100%; }
  .breadcrumbs .easy-breadcrumb {
    padding: 0 15px; }

#sliding-popup.sliding-popup-bottom {
  background: rgba(0, 0, 0, 0.55) !important; }

#sliding-popup .popup-content {
  max-width: 1040px !important; }

#sliding-popup .popup-content #popup-text {
  margin: 10px 0 0 !important; }

#sliding-popup .popup-content #popup-buttons button {
  margin: 5px 0 !important; }

#sliding-popup .popup-content #popup-text p {
  padding: 0 0 0 10px !important;
  margin: 0 !important; }

@media screen and (max-width: 767px) {
  #sliding-popup .popup-content #popup-text {
    float: left !important;
    width: 75% !important; }
  #sliding-popup .popup-content #popup-buttons {
    float: right !important;
    width: 25% !important; }
  #sliding-popup .popup-content #popup-buttons button {
    width: 80px !important; } }

.flexslider,
.flexslider-external {
  margin: 0 0 20px;
  box-shadow: none;
  border: none;
  max-height: 600px;
  overflow: hidden; }
  .flexslider ol,
  .flexslider ul,
  .flexslider-external ol,
  .flexslider-external ul {
    margin: 0;
    padding: 0; }
  .flexslider li,
  .flexslider-external li {
    padding-left: 0;
    -webkit-backface-visibility: visible !important; }
  .flexslider .row,
  .flexslider-external .row {
    position: relative;
    margin: 0 auto;
    max-width: 1024px; }
  .flexslider .slides > li,
  .flexslider-external .slides > li {
    background-color: #696969; }
    .flexslider .slides > li img,
    .flexslider-external .slides > li img {
      opacity: .9; }
  .flexslider .flex-caption,
  .flexslider-external .flex-caption {
    position: absolute;
    border: none;
    bottom: 30px;
    background: rgba(255, 255, 255, 0.8);
    left: 20px;
    font-size: 24px;
    line-height: 32px;
    padding: 10px 20px;
    border-radius: 5px; }
    .flexslider .flex-caption.linksboven,
    .flexslider-external .flex-caption.linksboven {
      left: 20px;
      top: 30px;
      bottom: auto; }
    .flexslider .flex-caption.rechtsonder,
    .flexslider-external .flex-caption.rechtsonder {
      right: 20px;
      left: auto; }
    .flexslider .flex-caption.rechtsboven,
    .flexslider-external .flex-caption.rechtsboven {
      right: 20px;
      top: 30px;
      bottom: auto;
      left: auto; }
    .flexslider .flex-caption .caption-title,
    .flexslider-external .flex-caption .caption-title {
      font-size: 18px;
      line-height: 24px; }
    .flexslider .flex-caption .caption-text,
    .flexslider-external .flex-caption .caption-text {
      font-size: 12px;
      line-height: 16px; }
  .flexslider .flex-control-nav,
  .flexslider-external .flex-control-nav {
    z-index: 99;
    bottom: 20px; }
    .flexslider .flex-control-nav li,
    .flexslider-external .flex-control-nav li {
      margin: 0 4px; }
      .flexslider .flex-control-nav li a,
      .flexslider-external .flex-control-nav li a {
        width: 20px;
        height: 20px;
        background: #ccc; }
        .flexslider .flex-control-nav li a.flex-active,
        .flexslider-external .flex-control-nav li a.flex-active {
          background: #999999; }
  .flexslider .flex-direction-nav a,
  .flexslider-external .flex-direction-nav a {
    color: #fff;
    top: 0;
    height: 100%;
    margin: 0;
    width: 60px; }
    .flexslider .flex-direction-nav a:before,
    .flexslider-external .flex-direction-nav a:before {
      top: 50%;
      position: absolute;
      margin-top: -32px; }
  .flexslider .flex-direction-nav .flex-next,
  .flexslider-external .flex-direction-nav .flex-next {
    right: 0; }
    .flexslider .flex-direction-nav .flex-next:before,
    .flexslider-external .flex-direction-nav .flex-next:before {
      right: 0; }
  .flexslider .flex-direction-nav .flex-prev,
  .flexslider-external .flex-direction-nav .flex-prev {
    left: 0; }
    .flexslider .flex-direction-nav .flex-prev:before,
    .flexslider-external .flex-direction-nav .flex-prev:before {
      left: 0; }
  .flexslider .bg-caption,
  .flexslider .color-caption,
  .flexslider-external .bg-caption,
  .flexslider-external .color-caption {
    display: none; }

@media screen and (max-width: 1023px) {
  .flexslider .flex-caption {
    font-size: 20px;
    line-height: 20px;
    padding: 15px; } }

@media screen and (max-width: 767px) {
  .flexslider .flex-caption {
    width: 100%;
    left: auto;
    bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 15px;
    right: auto !important;
    left: auto !important;
    border-radius: 0 !important; }
  .flexslider .slides img {
    width: 100%;
    max-width: none; }
  .flexslider .flex-direction-nav a:before {
    top: 70%; } }

html body.admin-menu {
  margin-top: 59px !important; }

#user-login {
  max-width: 270px;
  margin: 50px auto;
  border: 1px solid #EAE9E9;
  padding: 40px;
  background: #FDFDFD; }

#user-login .description {
  display: none; }

#user-login .form-submit {
  float: none;
  margin-bottom: 0;
  width: 100%; }

#user-login .form-item {
  margin: 0; }

.panels-flexible-region-inside {
  padding: 0 !important; }

.admin-tabs ul {
  padding: 0;
  margin: 0;
  border: none !important;
  position: fixed;
  top: 29px;
  height: 30px;
  background: #333;
  width: 100%;
  z-index: 999; }

.not-logged-in.section-user {
  padding-top: 30px; }

.not-logged-in .admin-tabs ul {
  top: 0; }

#admin-menu-icon a.active-trail {
  background: none !important;
  border-radius: 0 !important; }

#admin-menu-icon a:hover {
  background-color: #444 !important; }

.admin-tabs .tabs {
  border: none; }

.admin-tabs ul li a {
  height: 22px !important;
  line-height: 19px;
  color: #fafafa;
  padding: 2px 10px 3px;
  letter-spacing: 0;
  font-size: 13px;
  margin: 4px 6px;
  border-radius: 10px !important; }

.admin-tabs ul li a:hover {
  text-decoration: none;
  border-radius: 10px; }

.admin-tabs ul li a:active,
.admin-tabs ul li a:focus {
  color: #fff;
  font-weight: normal;
  border-radius: 10px; }

#admin-menu {
  background: #55a514;
  box-shadow: none; }

#admin-menu li.admin-menu-account > a {
  background: none; }

#admin-menu-wrapper {
  padding: 5px 10px 0; }

#admin-menu > div > .dropdown > li > a {
  border-radius: 0 !important;
  margin: 0;
  padding: 2px 10px 7px; }

#admin-menu-account .admin-menu-account,
#admin-menu-search,
#admin-menu-users,
.shortcut-toggle {
  display: none; }

#admin-menu .dropdown .admin-menu-toolbar-category > a.active-trail {
  background: #6BB92A; }

#edit-body-format,
.ckeditor_links {
  display: none; }

#admin-menu .dropdown .admin-menu-icon > a {
  padding: 2px 10px 8px; }

.admin-tabs .tabs-primary,
.admin-tabs .tabs-secondary {
  background-image: none; }

.admin-tabs .tabs-primary__tab,
.admin-tabs .tabs-primary__tab.is-active {
  border-radius: 0;
  text-shadow: none;
  border: none; }

.admin-tabs a.tabs-primary__tab-link.is-active,
.admin-tabs a.tabs-primary__tab-link:active {
  border-radius: 0;
  background-image: none;
  border: none; }

.admin-tab a.tabs-primary__tab-link.is-active,
.admin-tabs a.tabs-primary__tab-link {
  background: none;
  border: none;
  color: #fafafa;
  letter-spacing: 0; }

.admin-tabs .tabs-primary__tab.is-active a,
.admin-tabs ul li a:hover {
  background: #999 !important;
  padding: 2px 10px 3px;
  border-radius: 10px; }

.admin-tabs h2 {
  display: none; }

#admin-menu .dropdown li li a[href='/admin/structure/block/list/seven_override'] {
  display: none; }

#admin-menu .dropdown .admin-menu-icon > a span,
#admin-menu .dropdown .admin-menu-icon > a span {
  background: url("../images/react-logo.png");
  height: 14px;
  width: 14px; }

#admin-menu {
  background: #55a514;
  box-shadow: none; }

#admin-menu li.admin-menu-account > a {
  background: none; }

#admin-menu-account .admin-menu-account,
#admin-menu-search,
#admin-menu-users {
  display: none; }

#admin-menu * {
  font: 12px 'Lucida Grande', 'Lucida Sans Unicode', sans-serif; }

.agenda-overzicht .item {
  padding: 20px 0 0;
  margin: 20px 0 0;
  border-top: 1px solid #EFEFEF; }
  .agenda-overzicht .item h2 {
    margin-top: 0; }

.agenda-detail .datum,
.agenda-overzicht .datum {
  margin: 0 0 10px; }

.agenda-detail .datum .datum-label,
.agenda-overzicht .datum .datum-label {
  font-weight: bold; }

.agenda-widget .widget-item {
  margin: 0 0 20px; }

.agenda-widget .agenda-title {
  font-weight: bold; }

/*================================================
=== BLOG --=======================================
================================================*/
.home-news {
  padding-bottom: 50px; }
  .home-news .blog-link:hover .title a {
    color: #D60521; }
  .home-news .blog-item {
    border: 1px solid rgba(73, 98, 114, 0.07);
    padding-bottom: 20px; }
    .home-news .blog-item .title a {
      color: #00A1BD;
      font-size: 24px;
      line-height: 27px;
      padding: 25px;
      display: block; }
      .home-news .blog-item .title a:hover {
        color: #D60521; }
    .home-news .blog-item p {
      font-size: 14px;
      line-height: 21px;
      padding: 0 25px; }
      .home-news .blog-item p:after {
        content: ">"; }
    .home-news .blog-item img {
      width: 100%; }
  .home-news .six.columns:first-child {
    padding: 0 20px 0 0; }
  .home-news .six.columns:last-child {
    padding: 0 0 0 20px; }
  @media screen and (max-width: 424px) {
    .home-news .six {
      width: 100%; }
    .home-news .six.columns:first-child {
      padding: 0 0 20px !important; }
    .home-news .six.columns:last-child {
      padding: 0 0 20px !important; } }

.news-items .three {
  padding-left: 0 !important; }

@media screen and (max-width: 767px) {
  .news-items .columns {
    width: 100% !important;
    float: left !important; } }

/* Blog overview */
.blog-overview .blog-item {
  overflow: hidden;
  position: relative;
  float: left;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 30px;
  z-index: 1; }
  .blog-overview .blog-item .blog-hanger {
    box-shadow: 2px 2px 10px rgba(73, 98, 114, 0.4);
    margin-top: 80px;
    margin-bottom: 5px;
    padding: 20px 10px 30px;
    position: relative; }
    .blog-overview .blog-item .blog-hanger .title,
    .blog-overview .blog-item .blog-hanger .datum,
    .blog-overview .blog-item .blog-hanger .content p,
    .blog-overview .blog-item .blog-hanger .lees-meer a {
      padding-left: 15px;
      padding-right: 15px;
      line-height: 16px; }
    .blog-overview .blog-item .blog-hanger .title {
      padding-top: 20px; }
    .blog-overview .blog-item .blog-hanger .block-string {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -120px; }
  .blog-overview .blog-item:hover .blog-hanger {
    box-shadow: 1px 1px 2px rgba(73, 98, 114, 0.4); }
  .blog-overview .blog-item:hover .blog-hanger,
  .blog-overview .blog-item.bounce .blog-hanger {
    animation: bounce1 .7s alternate;
    animation-iteration-count: 2;
    z-index: 100; }
  .blog-overview .blog-item .title {
    display: inline-block;
    margin-top: 5px;
    padding-top: 10px; }
    .blog-overview .blog-item .title a {
      color: #00A1BD;
      font-size: 18px;
      line-height: 21px;
      display: block; }
      .blog-overview .blog-item .title a:hover {
        color: #D60521; }
  .blog-overview .blog-item .datum {
    display: block;
    margin: 5px 0;
    padding: 0 0 10px 0; }
    .blog-overview .blog-item .datum .date-display-single {
      color: #385591;
      font-size: 11px; }
  .blog-overview .blog-item .thumb-persoon {
    float: left;
    position: relative;
    width: 70px;
    height: 70px; }
  .blog-overview .blog-item .lees-meer {
    position: absolute;
    right: 25px;
    bottom: 25px; }
  .blog-overview .blog-item .lees-meer a,
  .blog-overview .blog-item .node-readmore a {
    text-decoration: none;
    font-weight: normal;
    color: rgba(44, 98, 113, 0.4); }
    .blog-overview .blog-item .lees-meer a:hover,
    .blog-overview .blog-item .node-readmore a:hover {
      color: #D60521; }
  .blog-overview .blog-item .icon-font {
    font-family: 'basis';
    font-size: 15px;
    font-weight: normal;
    float: left;
    padding-right: 5px; }

@media screen and (max-width: 768px) {
  .blog-overview .columns {
    width: 50% !important;
    float: left !important; } }

@media screen and (max-width: 767px) {
  .blog-overview .columns {
    width: 33.3333% !important;
    float: left !important; }
  .blog-overview .blog-item {
    padding: 0 !important; }
    .blog-overview .blog-item img {
      text-align: center;
      display: block;
      margin: 0 auto; } }

@media screen and (max-width: 424px) {
  .blog-overview .columns {
    width: 100% !important;
    float: left !important; } }

.np-left {
  padding-left: 0 !important; }

.news-filter {
  background: url("/sites/default/files/afbeeldingen/slider/news-filter-bg.png") no-repeat;
  background-size: 100%;
  min-height: 175px;
  padding: 10px 40px;
  margin-top: 80px;
  overflow: auto; }
  .news-filter .view-header h3 {
    padding-left: 0;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #385591;
    opacity: 1; }
  .news-filter .categorie a {
    display: block;
    color: #496272;
    padding-bottom: 10px; }
    .news-filter .categorie a.active {
      font-weight: bold; }

@media screen and (max-width: 1023px) {
  .news-filter {
    padding: 10px 20px;
    margin-top: 50px;
    background-size: cover;
    background-position: center center; }
    .news-filter h3 {
      margin-top: 10px; } }

@media screen and (max-width: 767px) {
  .news-filter {
    padding: 10px 20px;
    margin-top: 30px;
    text-align: center;
    display: inline-block;
    width: 100%; }
    .news-filter h3 {
      margin-top: 30px; } }

.view-tags a.active {
  background: #333;
  color: #fff; }

.view-categorie a.active {
  font-weight: bold; }

/* Blog overview met afbeelding*/
.blog-item.has-image .col {
  width: 65%;
  display: inline-block; }

.blog-item.has-image .thumb.col {
  float: left;
  width: 30%;
  margin-right: 5%; }

/* Blog Detail */
.blog-detail {
  margin: 20px 0 0; }
  .blog-detail .blog-item {
    border: none;
    margin: 0 !important;
    padding-bottom: 10px;
    float: left;
    width: 100%; }
    .blog-detail .blog-item h1 {
      margin-top: 0; }
    .blog-detail .blog-item .thumb {
      margin-top: 10px;
      float: left;
      width: 100%; }
      .blog-detail .blog-item .thumb img {
        width: 100%; }
    .blog-detail .blog-item .title {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(73, 98, 114, 0.07);
      margin-bottom: 8px;
      margin-top: 0; }
      .blog-detail .blog-item .title a {
        font-size: 21px;
        line-height: 25px; }
    .blog-detail .blog-item .content {
      margin-top: 0;
      padding-top: 0;
      border: 0; }
    .blog-detail .blog-item .datum {
      margin-top: 25px;
      margin-right: 20px;
      color: #999; }
  .blog-detail .auteur {
    margin-left: 10px; }
  .blog-detail .sharethis-wrapper {
    float: right; }
    .blog-detail .sharethis-wrapper .st_xing_custom,
    .blog-detail .sharethis-wrapper .st_twitter_custom,
    .blog-detail .sharethis-wrapper .st_facebook_custom,
    .blog-detail .sharethis-wrapper .st_linkedin_custom,
    .blog-detail .sharethis-wrapper .st_pinterest_custom {
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      height: 30px;
      width: 30px;
      display: inline-block;
      cursor: pointer; }
      .blog-detail .sharethis-wrapper .st_xing_custom:hover,
      .blog-detail .sharethis-wrapper .st_twitter_custom:hover,
      .blog-detail .sharethis-wrapper .st_facebook_custom:hover,
      .blog-detail .sharethis-wrapper .st_linkedin_custom:hover,
      .blog-detail .sharethis-wrapper .st_pinterest_custom:hover {
        transform: rotate(25deg); }
    .blog-detail .sharethis-wrapper .st_xing_custom {
      background: url("/sites/default/files/afbeeldingen/content/xing.svg") no-repeat 100%; }
    .blog-detail .sharethis-wrapper .st_twitter_custom {
      background: url("/sites/default/files/afbeeldingen/content/twitter.svg") no-repeat 100%; }
    .blog-detail .sharethis-wrapper .st_facebook_custom {
      background: url("/sites/default/files/afbeeldingen/content/facebook.svg") no-repeat 100%; }
    .blog-detail .sharethis-wrapper .st_linkedin_custom {
      background: url("/sites/default/files/afbeeldingen/content/linkedin.svg") no-repeat 100%; }
    .blog-detail .sharethis-wrapper .st_pinterest_custom {
      background: url("/sites/default/files/afbeeldingen/content/pinterest.svg") no-repeat 100%; }
  .blog-detail .back-to-overview {
    float: left;
    width: 100%;
    border-top: 0;
    padding-top: 10px; }

/* MASONRY */
.view-blog.view-id-blog.view-display-id-block_5,
.view-id-cases {
  margin: -10px -10px 50px; }

/*================================================
=== Comments
================================================*/
.comment-form,
.comments,
.comment,
.comment-add {
  display: none !important; }

/* Blog overview */
.case-item {
  float: left;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px; }
  .case-item .col {
    width: 100%;
    float: right; }
  .case-item .titel {
    padding-bottom: 10px;
    border-top: 1px solid #f1f1f1;
    padding-top: 10px;
    margin-top: 8px; }
    .case-item .titel a {
      font-size: 21px;
      line-height: 25px; }
  .case-item .auteur,
  .case-item .categorie {
    font-size: 11px; }
  .case-item .klant {
    border-top: 1px solid #f1f1f1;
    padding-top: 10px; }
  .case-item .tags {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 10px; }
  .case-item .lees-meer a {
    float: right;
    margin-top: 10px; }

/* Blog overview met afbeelding*/
.case-item.has-image .col {
  width: 30%; }

.case-item.has-image .thumb.col {
  float: left;
  width: 65%;
  margin-right: 5%; }

.case-detail .case-item {
  margin: 0 !important;
  border: none; }
  .case-detail .case-item .lees-meer a {
    margin-bottom: 10px; }
  .case-detail .case-item.has-image .col {
    width: 100%; }

.vacature-overzicht .sidebar {
  margin-top: 20px; }

.vacature-overzicht .blog-item .meta a {
  color: #000; }

.vacature-overzicht .blog-item .lees-meer {
  margin-top: 20px; }

.vacature-overzicht .blog-item .content-wrap {
  width: 100%; }

.vacature-overzicht .block {
  padding: 40px;
  background: #fff;
  margin: 0 0 30px; }

.vacature-homepage .blog-item .meta a {
  color: #000; }

.vacature-homepage .blog-item .lees-meer {
  margin-top: 20px; }

.vacature-homepage .blog-item .content-wrap {
  width: 100%; }

@media screen and (max-width: 1023px) {
  .vacature-detail .content .first,
  .vacature-detail .content .last {
    width: 50% !important; } }

@media screen and (max-width: 767px) {
  .vacature-detail .content .first,
  .vacature-detail .content .last {
    width: 100% !important; } }

.vacature-detail .sidebar h1,
.vacature-detail .sidebar h2,
.vacature-detail .sidebar h3,
.vacature-detail .sidebar h4,
.vacature-detail .sidebar h5,
.vacature-detail .sidebar h6 {
  font-size: 20px;
  line-height: 20px;
  margin-top: 0; }

.vacature-detail .meta-info {
  font-weight: bold;
  color: #000; }

.vacature-detail form {
  margin: 0; }

.vacature-detail .persoon.info {
  background: #efefef;
  padding: 30px;
  float: left;
  width: 100%;
  margin: 0; }
  .vacature-detail .persoon.info .view-content {
    margin: 10px 0 0;
    padding: 0 0 30px; }
  .vacature-detail .persoon.info .view-header {
    padding: 0; }
    .vacature-detail .persoon.info .view-header p {
      font-size: inherit;
      font-weight: inherit; }
  .vacature-detail .persoon.info .titel {
    font-weight: bold;
    color: #000; }

.vacature-detail .koppeling-persoon {
  padding: 30px 30px 0;
  float: left;
  width: 100%;
  background: #efefef; }

.vacature-detail .field-name-field-tekst {
  background: #565656;
  padding: 30px;
  float: left;
  width: 100%;
  margin: 0 0 30px; }
  .vacature-detail .field-name-field-tekst ol,
  .vacature-detail .field-name-field-tekst ul {
    margin-bottom: 0; }
  .vacature-detail .field-name-field-tekst * {
    color: #fff; }

.vacature-detail .field-name-field-webformulier-invoegen {
  background: #efefef;
  padding: 0 30px 30px;
  float: left;
  width: 100%;
  margin: 0; }

.vacature-detail .container.content {
  background: #fff !important; }
  .vacature-detail .container.content h1 {
    margin-top: 0; }

.vacature-detail .content-top {
  padding-top: 0; }

.vacature-detail .meta {
  background: #efefef;
  padding: 15px;
  color: #fff;
  float: left; }
  .vacature-detail .meta div {
    color: #fff;
    float: left;
    width: 100%;
    font-weight: bold;
    margin-bottom: 3px; }
    .vacature-detail .meta div .views-label {
      float: left;
      margin-right: 3px; }
    .vacature-detail .meta div .field-content {
      float: left;
      width: auto; }
  .vacature-detail .meta .views-field-title span {
    font-size: 18px;
    margin-bottom: 5px; }
  .vacature-detail .meta .views-field-field-evt-extra-opmerking div {
    font-weight: normal; }

.vacature-detail .event-foto {
  float: left;
  width: 100%;
  margin-bottom: 40px; }
  .vacature-detail .event-foto img {
    float: left;
    width: 100%; }

.vragen-overzicht .vraag {
  font-weight: bold; }

.vragen-overzicht .views-field-body p {
  color: #444; }

.vragen-overzicht .ui-accordion-content {
  height: auto !important;
  background: #fff;
  border: 0;
  padding: 10px 10px 10px 15px; }

.vragen-overzicht .ui-accordion-header {
  background: none; }

.vragen-overzicht .ui-icon-triangle-1-e {
  background-position: -37px -15px; }

.vragen-overzicht .ui-icon-triangle-1-s {
  background-position: -68px -15px; }

.vragen-overzicht .ui-accordion .ui-accordion-icons {
  padding-left: 15px; }

.vragen-overzicht .ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  top: 47% !important;
  left: 0; }

.vragen-overzicht .ui-state-default,
.vragen-overzicht .ui-widget-content .ui-state-default,
.vragen-overzicht .ui-widget-header .ui-state-default {
  border: 0; }

/*OVERVIEW PAGINA*/
.fotoalbum-overview {
  border-top: 1px solid #f1f1f1;
  padding-top: 30px;
  float: left;
  width: 100%; }
  .fotoalbum-overview ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .fotoalbum-overview ul li {
      padding: 0 15px;
      float: left;
      margin-bottom: 30px;
      width: 25%;
      list-style: none; }
      .fotoalbum-overview ul li a {
        overflow: hidden; }
      .fotoalbum-overview ul li img {
        width: 100%;
        transition: all 0.4s ease; }
      .fotoalbum-overview ul li:hover img {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1); }

@media screen and (max-width: 1023px) {
  .fotoalbum-overview ul li,
  .fotoalbum-sectie ul li {
    width: 50%; } }

@media screen and (max-width: 767px) {
  .fotoalbum-sectie ul li:nth-child(odd) {
    padding-left: 0px !important; }
  .fotoalbum-sectie ul li:nth-child(even) {
    padding-right: 0px !important; }
  .sectie-item .fotoalbum .content {
    width: 100% !important; }
  .sectie-item .formulier .content {
    width: 100% !important; }
  .sectie-item .webform-component-fieldset {
    width: 100% !important; }
  .sectie-item .view-fotoalbum {
    width: 100%; } }

/* OVERVIEW */
.persoon {
  margin: 0 -15px; }
  .persoon .view-header {
    padding: 0 15px; }
    .persoon .view-header p {
      font-size: 16px;
      font-weight: bold; }
  .persoon .columns {
    float: left !important; }
    .persoon .columns:nth-child(1) .person {
      background: url("../images/teambg1.jpg") no-repeat top center;
      background-size: 100%; }
    .persoon .columns:nth-child(2) .person {
      background: url("../images/teambg2.jpg") no-repeat top center;
      background-size: 100%; }
    .persoon .columns:nth-child(3) .person {
      background: url("../images/teambg3.jpg") no-repeat top center;
      background-size: 100%; }
    .persoon .columns:nth-child(4) .person {
      background: url("../images/teambg4.jpg") no-repeat top center;
      background-size: 100%; }
    .persoon .columns:nth-child(5) .person {
      background: url("../images/teambg5.jpg") no-repeat top center;
      background-size: 100%; }
  .persoon .person {
    min-height: 350px;
    padding: 100px 60px; }
    .persoon .person .thumb {
      float: left;
      width: 100%;
      text-align: center; }
      .persoon .person .thumb img {
        width: inherit; }
    .persoon .person .title a {
      color: #00A1BD;
      font-size: 18px;
      line-height: 21px;
      padding: 15px 15px;
      display: block;
      text-align: center;
      font-family: 'black_coffee_shadowbold';
      font-size: 23px;
      color: #385591; }
    .persoon .person:hover .title a {
      color: #D60521; }
  @media screen and (max-width: 1023px) {
    .persoon .person {
      min-height: 270px;
      padding: 60px 40px 30px 40px; } }
  @media screen and (max-width: 767px) {
    .persoon .columns {
      width: 33.333%; }
    .persoon .person {
      min-height: 250px;
      padding: 50px 40px 30px; } }
  @media screen and (max-width: 424px) {
    .persoon .columns {
      width: 100% !important; }
      .persoon .columns .person {
        background-size: 90% !important; }
    .persoon .person {
      min-height: 300px;
      padding: 60px 40px 30px; } }
  @media screen and (max-width: 320px) {
    .persoon .columns .person {
      background-size: 100% !important; } }

/* DETAIL */
.personen-detail .profile-img {
  min-height: 1px; }

.personen-detail .person-info {
  min-height: 375px;
  background: url("/sites/all/themes/front/images/about-us_background_slider.jpg") no-repeat;
  background-size: 100%;
  position: relative; }
  .personen-detail .person-info .inside.panels-flexible-row-inside-first {
    position: absolute;
    top: 50px;
    left: 50%;
    min-height: 265px;
    transform: translateX(-50%);
    width: 1024px;
    margin: 0 auto;
    background: url("/sites/default/files/afbeeldingen/slider/person-info-bg.png") no-repeat;
    background-size: 100%;
    padding: 10px; }
  .personen-detail .person-info .profile-picture {
    background: url("/sites/default/files/afbeeldingen/slider/profile-picture-bg.png") no-repeat;
    background-size: 100%;
    min-height: 250px;
    padding: 75px 40px;
    top: -40px;
    position: absolute;
    width: 100%; }
    .personen-detail .person-info .profile-picture img {
      width: 100%; }
  .personen-detail .person-info .persoon.info {
    margin-top: 20px;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px; }
    .personen-detail .person-info .persoon.info .title {
      font-size: 24px;
      font-family: 'bpreplayregular';
      margin-bottom: 0; }
    .personen-detail .person-info .persoon.info .department {
      color: #00A1BD;
      font-size: 13px;
      font-family: 'bpreplayregular';
      margin-bottom: 15px;
      display: block;
      text-transform: uppercase; }
  .personen-detail .person-info .social-media-list ul {
    margin: 0 !important; }
    .personen-detail .person-info .social-media-list ul .social-media {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 0px !important; }
      .personen-detail .person-info .social-media-list ul .social-media img {
        width: 30px;
        height: 30px; }
  @media screen and (max-width: 767px) {
    .personen-detail .person-info .social-media-list ul .social-media {
      margin-bottom: 10px !important; } }
  .personen-detail .person-info .contact-info {
    margin-top: 20px;
    text-align: center; }

.personen-detail .contact-info .email,
.personen-detail .contact-info .slogan,
.personen-detail .contact-info .phonenumber {
  display: block;
  color: #D60521;
  font-family: "alpine_scriptmedium";
  font-size: 21px; }

.personen-detail .contact-info .slogan,
.personen-detail .contact-info .phonenumber {
  font-size: 14px; }

.personen-detail .persoon {
  margin-top: 50px; }
  .personen-detail .persoon .person {
    padding: 60px 20px;
    min-height: 180px;
    text-align: center; }
    .personen-detail .persoon .person img {
      width: auto; }
    .personen-detail .persoon .person .title a {
      font-size: 18px; }

@media screen and (max-width: 1023px) {
  .personen-detail .person-info {
    min-height: 375px;
    background-size: auto; }
    .personen-detail .person-info .inside.panels-flexible-row-inside-first {
      width: 90%;
      background-size: auto; }
    .personen-detail .person-info .persoon.info {
      padding-right: 10px; }
      .personen-detail .person-info .persoon.info .email {
        font-size: 16px; }
  .personen-detail .persoon {
    margin-top: 20px; }
    .personen-detail .persoon .person {
      min-height: 210px;
      padding: 40px 25px 30px; }
      .personen-detail .persoon .person .thumb img {
        width: 75%; }
      .personen-detail .persoon .person .title a {
        padding: 15px 0; } }

@media screen and (max-width: 767px) {
  .personen-detail .person-info {
    min-height: 500px;
    background-size: auto; }
    .personen-detail .person-info .columns {
      width: 100% !important; }
    .personen-detail .person-info .inside.panels-flexible-row-inside-first {
      width: 95%;
      margin: 0 auto;
      padding: 0;
      background: #fff;
      background-size: auto;
      top: 20px; }
    .personen-detail .person-info .profile-picture {
      width: 30%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      padding: 0;
      min-height: 0px;
      top: 0px;
      background: none; }
    .personen-detail .person-info .persoon.info {
      margin-top: 0;
      padding-bottom: 10px;
      text-align: center; }
      .personen-detail .person-info .persoon.info h1 {
        margin-top: 0; }
    .personen-detail .person-info .contact-info {
      margin-top: 0px; }
  .personen-detail .persoon {
    margin-top: 20px; }
    .personen-detail .persoon .columns {
      width: 33.3333%; }
    .personen-detail .persoon .person {
      min-height: 250px;
      padding: 50px 25px 30px; }
      .personen-detail .persoon .person .title a {
        padding: 15px 0; } }

@media screen and (max-width: 424px) {
  .personen-detail .persoon {
    margin-top: 20px; }
    .personen-detail .persoon .person {
      min-height: 250px;
      padding: 50px 25px 30px;
      background-size: 70%; }
      .personen-detail .persoon .person .title a {
        padding: 15px 0; } }

/*Product overview*/
.producten-row {
  border-top: 1px solid rgba(73, 98, 114, 0.07);
  overflow: hidden; }
  .producten-row .pane-title {
    margin: 10px 0 0;
    float: left;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgba(44, 98, 113, 0.4); }
    .producten-row .pane-title:after {
      content: "\e008";
      font-family: "sima-europe";
      position: relative;
      font-size: 10px;
      left: 5px;
      margin-right: 5px; }
  .producten-row .collectie-filter,
  .producten-row .soort-filter {
    position: absolute;
    top: 95px;
    width: 100%;
    padding: 20px;
    padding-right: 50px;
    animation: bounce1 .7s alternate;
    animation-iteration-count: 2;
    z-index: 100; }
  .producten-row .soort-filter {
    top: 130px; }
  .producten-row .product-collection-filter ul,
  .producten-row .product-soort-filter ul {
    margin: 0;
    border-top: 1px solid rgba(73, 98, 114, 0.07);
    border-bottom: 1px solid rgba(73, 98, 114, 0.07);
    padding: 10px 0; }
    .producten-row .product-collection-filter ul li,
    .producten-row .product-soort-filter ul li {
      display: inline-block;
      margin-bottom: 0 !important;
      border-right: 1px solid #385591; }
      .producten-row .product-collection-filter ul li.last,
      .producten-row .product-soort-filter ul li.last {
        border-right: none; }
      .producten-row .product-collection-filter ul li .filterbutton,
      .producten-row .product-collection-filter ul li .categorie,
      .producten-row .product-soort-filter ul li .filterbutton,
      .producten-row .product-soort-filter ul li .categorie {
        color: #385591;
        text-transform: uppercase;
        display: block;
        font-size: 14px;
        line-height: 20px; }
        .producten-row .product-collection-filter ul li .filterbutton:hover,
        .producten-row .product-collection-filter ul li .categorie:hover,
        .producten-row .product-soort-filter ul li .filterbutton:hover,
        .producten-row .product-soort-filter ul li .categorie:hover {
          color: #00A1BD; }
          .producten-row .product-collection-filter ul li .filterbutton:hover .categorie,
          .producten-row .product-collection-filter ul li .categorie:hover .categorie,
          .producten-row .product-soort-filter ul li .filterbutton:hover .categorie,
          .producten-row .product-soort-filter ul li .categorie:hover .categorie {
            color: #00A1BD; }
        .producten-row .product-collection-filter ul li .filterbutton.selected,
        .producten-row .product-collection-filter ul li .categorie.selected,
        .producten-row .product-soort-filter ul li .filterbutton.selected,
        .producten-row .product-soort-filter ul li .categorie.selected {
          color: #D60521; }
          .producten-row .product-collection-filter ul li .filterbutton.selected .categorie,
          .producten-row .product-collection-filter ul li .categorie.selected .categorie,
          .producten-row .product-soort-filter ul li .filterbutton.selected .categorie,
          .producten-row .product-soort-filter ul li .categorie.selected .categorie {
            color: #D60521; }
  .producten-row .product-soort-filter ul {
    border-top: none; }
  .producten-row .product-overview {
    animation: bounce1 .7s alternate;
    animation-iteration-count: 2;
    margin-top: 80px;
    box-shadow: 1px 1px 5px rgba(73, 98, 114, 0.5);
    margin-bottom: 10px;
    padding: 80px 0px;
    position: relative; }
    .producten-row .product-overview .product-titel {
      position: absolute;
      bottom: 0px;
      left: 50%;
      text-transform: uppercase;
      padding: 10px 20px;
      background: #fff;
      min-width: 130px;
      text-align: center;
      transform: translateX(-50%); }
    .producten-row .product-overview .block-string {
      animation-iteration-count: 2;
      position: absolute;
      top: -120px; }
      .producten-row .product-overview .block-string.left {
        left: 10%;
        transform: translateX(-10%); }
      .producten-row .product-overview .block-string.right {
        right: 10%;
        transform: translateX(-10%); }
  @media screen and (max-width: 767px) {
    .producten-row .pane-title {
      font-size: 10px; }
    .producten-row .product-collection-filter ul li .filterbutton,
    .producten-row .product-collection-filter ul li .categorie,
    .producten-row .product-soort-filter ul li .filterbutton,
    .producten-row .product-soort-filter ul li .categorie {
      font-size: 10px; } }

.product-overview .row {
  margin-bottom: 50px; }

.product-overview [class*='column'] + [class*='column']:last-child {
  float: left; }

.product-item .col {
  float: left;
  width: 100%; }

.product-item .thumb img {
  width: 100%; }

.product-item .lees-meer {
  border-top: 1px solid rgba(73, 98, 114, 0.07);
  padding-top: 5px;
  margin-top: 5px; }
  .product-item .lees-meer a {
    float: right; }

/*Product detail*/
.product-detail {
  margin: 0 -15px; }
  .product-detail .col {
    width: 50%;
    float: left;
    padding: 0 15px; }
  .product-detail .thumb img {
    width: 100%; }
  .product-detail .prijs {
    font-size: 30px;
    line-height: 40px;
    border-top: 1px solid rgba(73, 98, 114, 0.07);
    padding-top: 7px;
    margin-top: 10px;
    text-align: right; }

.isotope-container {
  height: auto !important; }

.masonry-processed {
  height: auto !important; }

.isotope-element {
  transition-property: top;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition: top .2s, position .2s; }

.masonry-item,
.isotope-element {
  width: 21% !important;
  margin: 2% !important;
  padding: 0 !important;
  background: none;
  float: left;
  border: none;
  position: relative !important;
  left: auto !important;
  top: auto !important;
  min-height: 215px; }
  .masonry-item.middel,
  .isotope-element.middel {
    width: 46% !important;
    height: 206px;
    overflow: hidden; }
  .masonry-item.big,
  .isotope-element.big {
    width: 46% !important; }
  .masonry-item a,
  .isotope-element a {
    line-height: 0;
    display: inherit; }

.masonry-item.extended {
  width: 66.6%; }
  .masonry-item.extended .item-inner {
    background: #fff; }
  .masonry-item.extended .datum {
    color: #fff; }
  .masonry-item.extended .tags li {
    color: #fff;
    border: 1px solid #fff; }

.masonry-item.quotes .item-inner {
  background: #2ccefd; }

.masonry-item.quotes .content {
  font-size: 22px;
  line-height: 25px;
  font-weight: 100;
  color: #fff;
  padding: 20px 40px; }

.masonry-item.quotes .quote-klant {
  float: right;
  clear: both;
  font-size: 18px;
  font-weight: 100;
  color: #fff;
  padding: 0 40px; }

.masonry-item.quotes .quote-functie {
  float: right;
  clear: both;
  font-weight: 100;
  font-size: 13px;
  padding-top: 3px;
  color: #fff;
  padding: 5px 40px 20px; }

@media screen and (max-width: 1023px) {
  .masonry-item,
  .masonry-item.big,
  .masonry-item.middel,
  .isotope-element,
  .isotope-element.big,
  .isotope-element.middel {
    width: 46% !important;
    height: 100%; }
  .masonry-item.extended {
    width: 100%; } }

@media screen and (max-width: 767px) {
  .masonry-item,
  .masonry-item.big,
  .masonry-item.middel,
  .isotope-element,
  .isotope-element.big,
  .isotope-element.middel {
    width: 100% !important;
    margin: 0 !important;
    padding: 10px !important; }
  .masonry-item.extended {
    width: 100%; } }

.search-results {
  margin: 0 !important; }

.search-info {
  display: none !important; }

.search-results .search-snippet-info {
  padding-left: 0; }

.section-zoeken h1,
#search-api-page-search-form,
.search-api-page-results {
  width: 1024px;
  margin: 0 auto;
  padding: 0 15px; }
  @media screen and (max-width: 767px) {
    .section-zoeken h1,
    #search-api-page-search-form,
    .search-api-page-results {
      width: 100%; } }

.section-zoeken h1 {
  margin-top: 30px; }

.search-result {
  background: #f2f3f4;
  padding: 15px !important; }
  .search-result h1 {
    padding: 0;
    margin-top: 15px;
    font-size: 20px;
    color: #496272; }
  .search-result .title {
    margin-top: 0; }
  .search-result .rtecenter {
    text-align: left !important; }

.tweets .tweets-item {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee; }
  .tweets .tweets-item .datum {
    color: #999;
    font-size: 11px;
    display: block; }
  .tweets .tweets-item .content a {
    text-decoration: underline; }

.webform-progressbar {
  margin-left: 17px;
  margin-bottom: 20px;
  margin-top: 30px;
  display: inline-block; }
  .webform-progressbar .webform-progressbar-inner {
    border-color: #2b2b2b;
    background-color: #2b2b2b; }
  .webform-progressbar .webform-progressbar-page {
    border: 1px solid #2b2b2b; }
  .webform-progressbar .webform-progressbar-page.current {
    background-color: #2b2b2b; }
  .webform-progressbar .webform-progressbar-page.completed {
    background-color: #2b2b2b; }
  .webform-progressbar .webform-progressbar-outer {
    border: 1px solid #2b2b2b; }

.webform-client-form input[type=date],
.webform-client-form input[type=datetime],
.webform-client-form input[type=email],
.webform-client-form input[type=number],
.webform-client-form input[type=password],
.webform-client-form input[type=search],
.webform-client-form input[type=tel],
.webform-client-form input[type=text],
.webform-client-form input[type=time],
.webform-client-form input[type=url],
.webform-client-form textarea {
  margin: 0; }

.webform-client-form .form-item {
  margin: 0 0 10px;
  float: left;
  width: 100%; }
  .webform-client-form .form-item .form-item {
    margin: 0; }
  .webform-client-form .form-item label {
    font-weight: 600;
    margin-bottom: 0; }

.webform-client-form fieldset .form-item {
  padding: 0; }

.webform-client-form.preview .webform-page {
  border-bottom: 1px solid #efefef;
  padding-bottom: 40px;
  text-indent: -9999px;
  display: none; }

.webform-client-form .webform-component-radios.inline .form-type-radio {
  float: left;
  width: auto;
  margin: 0 15px 0 0; }

.webform-client-form .webform-component-radios .form-type-radio label {
  font-weight: normal; }

.webform-client-form .webform-component-checkboxes .form-type-checkbox label {
  font-weight: normal; }

.webform-client-form .webform-component-file .description {
  float: left;
  width: 100%;
  margin: 10px 0 0;
  color: #707A80; }

.webform-client-form .webform-component-file input[type='submit'] {
  float: left;
  width: 106px;
  clear: both;
  background: #707A80;
  margin: 10px 0 0; }

.webform-client-form .webform-layout-box {
  margin: 0 -15px;
  clear: both; }
  .webform-client-form .webform-layout-box > .form-item {
    padding: 0 15px; }
    @media screen and (max-width: 767px) {
      .webform-client-form .webform-layout-box > .form-item {
        width: auto;
        float: none; } }

.webform-client-form .webform-container-inline {
  /*
		input, select, .form-textarea-wrapper {
			width:60%;
			float: left;
			display: block;

			@include breakpoint(medium) {
				float: none;
				width:100%;
			}
		}*/ }
  .webform-client-form .webform-container-inline > label {
    margin: 0;
    width: 40%;
    float: left;
    padding-right: 20px; }
  .webform-client-form .webform-container-inline.webform-component-textfield input {
    width: 60%;
    float: right; }
  .webform-client-form .webform-container-inline > .form-radios,
  .webform-client-form .webform-container-inline > .form-textarea-wrapper,
  .webform-client-form .webform-container-inline > input,
  .webform-client-form .webform-container-inline > .form-checkboxes {
    width: 60%;
    float: right; }
  .webform-client-form .webform-container-inline div.description {
    display: block;
    float: left;
    width: 100%; }
  .webform-client-form .webform-container-inline.beschrijving-links div.description {
    width: 40%;
    line-height: 20px; }

.webform-client-form .webform-component-fieldset {
  width: 50%;
  float: left;
  padding: 0 15px;
  border: none;
  margin: 0; }
  .webform-client-form .webform-component-fieldset .fieldset-legend {
    display: none; }
  .webform-client-form .webform-component-fieldset.links, .webform-client-form .webform-component-fieldset.webform-component--kolom-verdeling-1, .webform-client-form .webform-component-fieldset.webform-component--links, .webform-client-form .webform-component-fieldset.webform-component--links1 {
    padding-left: 0px !important; }
  .webform-client-form .webform-component-fieldset.rechts, .webform-client-form .webform-component-fieldset.webform-component--kolom-verdeling, .webform-client-form .webform-component-fieldset.webform-component--rechts, .webform-client-form .webform-component-fieldset.webform-component--rechts1 {
    padding-right: 0px !important; }
  @media screen and (max-width: 767px) {
    .webform-client-form .webform-component-fieldset {
      padding: 0 !important;
      width: auto;
      float: none; } }

.webform-client-form .btn,
.webform-client-form .form-submit {
  float: right;
  margin: 15px 0 0;
  -webkit-appearance: none;
  clear: none; }
  .webform-client-form .btn.webform-next,
  .webform-client-form .form-submit.webform-next {
    float: right;
    clear: none; }
  .webform-client-form .btn.webform-previous,
  .webform-client-form .form-submit.webform-previous {
    float: left;
    clear: none;
    background: #E9EAED; }

.webform-client-form .webform-confirmation {
  padding: 0 15px; }

.webform-client-form .ajax-progress {
  margin-left: 15px; }

.webform-client-form .throbber {
  background: url("../images/ajax-loader.gif");
  position: absolute;
  bottom: 0;
  height: 11px;
  width: 16px;
  margin: 2px;
  z-index: 10; }

.webform-client-form .error input,
.webform-client-form .error input:focus,
.webform-client-form .error textarea,
.webform-client-form .error textarea:focus,
.webform-client-form .error:focus,
.webform-client-form input.error,
.webform-client-form input.error:focus,
.webform-client-form textarea.error {
  background: #fff !important;
  border-color: #db0f48 !important; }

.block-webform .links,
.webform-client-form .links {
  float: left;
  padding: 0 15px; }

.node-type-webform .webform-confirmation {
  padding: 0 !important; }

.webform-client-form-44 {
  margin: 0; }
  .webform-client-form-44 .webform-component-markup h2 {
    margin: 30px 0 20px; }
  @media screen and (max-width: 767px) {
    .webform-client-form-44 .form-item {
      padding: 0; } }

.sectie-item {
  float: left;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px; }
  .sectie-item .row {
    width: 1024px;
    max-width: 100%;
    margin: 0 auto; }
    .sectie-item .row .column,
    .sectie-item .row .columns {
      padding: 0 15px !important; }
      @media screen and (max-width: 767px) {
        .sectie-item .row .column,
        .sectie-item .row .columns {
          padding: 0 30px !important; } }
  .sectie-item .row-usp {
    width: 650px;
    max-width: 100%;
    margin: 0 auto; }
    .sectie-item .row-usp .column,
    .sectie-item .row-usp .columns {
      padding: 0 15px !important; }
      @media screen and (max-width: 767px) {
        .sectie-item .row-usp .column,
        .sectie-item .row-usp .columns {
          padding: 0 30px !important; } }
      .sectie-item .row-usp .column h2,
      .sectie-item .row-usp .columns h2 {
        font-size: 24px; }
      .sectie-item .row-usp .column p,
      .sectie-item .row-usp .columns p {
        line-height: 24px; }
  .sectie-item img {
    height: auto !important; }
  .sectie-item.achtergrond-afbeelding:first-child {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .sectie-item.achtergrond-afbeelding h1 {
    margin-top: 40px; }
  .sectie-item.geenmarge {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sectie-item.margetop {
    padding-bottom: 0 !important; }
  .sectie-item.margebottom {
    padding-top: 0 !important; }
  .sectie-item.border, .sectie-item.border-bottom, .sectie-item.border-top {
    border-color: #efefef;
    border-style: solid;
    border-width: 0; }
  .sectie-item.border {
    border-top-width: 1px !important;
    border-bottom-width: 1px !important; }
  .sectie-item.border-top {
    border-top-width: 1px !important; }
  .sectie-item.border-bottom {
    border-bottom-width: 1px !important; }

.color-2ba6cb a,
.color-2ba6cb blockquote,
.color-2ba6cb blockquote p:before,
.color-2ba6cb h1,
.color-2ba6cb h2,
.color-2ba6cb h3,
.color-2ba6cb h4,
.color-2ba6cb h5,
.color-2ba6cb h6,
.color-2ba6cb ol,
.color-2ba6cb p,
.color-2ba6cb ul {
  color: #fff; }

.color-2ba6cb .btn {
  background: #fff;
  color: #000; }

.color-2ba6cb .btn.rand {
  background: transparent;
  border: 2px solid #fff;
  color: #fff; }

.color-f2f2f2 {
  background: rgba(73, 98, 114, 0.1); }

.color-e3e3e3 {
  background: #e3e3e3; }

.color-2ba6cb {
  background: #2ba6cb; }

.page-node-82 .sectie-item.formulier .block {
  float: left;
  margin: 20px 0;
  width: 100%;
  padding: 20px 30px;
  background: #2ba6cb; }
  .page-node-82 .sectie-item.formulier .block * {
    color: #fff !important; }
  .page-node-82 .sectie-item.formulier .block .btn {
    background: #fff !important;
    color: #2ba6cb !important; }

.fotoalbum-sectie {
  margin-top: 25px; }
  @media screen and (max-width: 767px) {
    .fotoalbum-sectie {
      width: 100%; } }
  .fotoalbum-sectie ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .fotoalbum-sectie ul li {
      padding: 0 10px;
      float: left;
      margin-bottom: 15px;
      width: 25%;
      list-style-type: none; }
      @media screen and (max-width: 1023px) {
        .fotoalbum-sectie ul li {
          width: 50%; } }
      @media screen and (max-width: 767px) {
        .fotoalbum-sectie ul li:nth-child(odd) {
          padding-left: 0px !important; }
        .fotoalbum-sectie ul li:nth-child(even) {
          padding-right: 0px !important; } }
      .fotoalbum-sectie ul li img {
        width: 100%; }
  .fotoalbum-sectie .foto-titel {
    float: left;
    width: 100%;
    text-align: center; }

.sectie-item .fotoalbum .content {
  width: 50% !important; }

.sectie-item .fotoalbum .content.col.nr1 {
  float: left; }

.sectie-item .view-fotoalbum .col {
  padding: 0; }

@media screen and (max-width: 767px) {
  .sectie-item .fotoalbum .content {
    width: 100% !important; } }

.section-approach .sectie-item .four {
  padding: 10px 40px !important; }

.entity-paragraphs-item {
  padding: 40px 0; }

.paragraphs-item-afbeeldingen .field-item {
  width: 50%;
  float: left;
  padding: 0 20px 20px; }
  .paragraphs-item-afbeeldingen .field-item img {
    float: left;
    width: 100%; }

/**
 * @file
 * Print styling
 *
 * We provide some sane print styling for Drupal using Zen's layout method.
 */
/**
 * By importing this CSS file as media 'all', we allow this print file to be
 * aggregated with other stylesheets, for improved front-end performance.
 */
@media print {
  a {
    /* Underline all links. */
    /* Don't underline header. */ }
    a:link, a:visited {
      text-decoration: underline !important; }
    a:link.header__site-link, a:visited.header__site-link {
      text-decoration: none !important; }
  /* Add visible URL after links. */
  #content {
    /* Add visible title after abbreviations. */
    /* Un-float the content. */
    float: none !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    color: #000;
    background-color: transparent !important;
    background-image: none !important; }
    #content a {
      /* Only display useful links. */ }
      #content a[href]:after {
        content: " (" attr(href) ")";
        font-weight: normal;
        font-size: 16px; }
      #content a[href^='#']:after, #content a[href^='javascript:']:after {
        content: ''; }
    #content abbr[title]:after {
      content: " (" attr(title) ")"; }
  /* Turn off any background colors or images. */
  #main,
  #page,
  body {
    color: #000;
    background-color: transparent !important;
    background-image: none !important; }
  /* Hide sidebars and nav elements. */
  #footer,
  #navigation,
  #skip-link,
  #toolbar,
  .action-links,
  .book-navigation,
  .breadcrumb,
  .feed-icons,
  .forum-topic-navigation,
  .links,
  .pager,
  .region-sidebar-first,
  .region-sidebar-second,
  .tabs {
    visibility: hidden;
    display: none; } }

html {
  -webkit-font-smoothing: antialiased; }

body {
  font-size: 100%;
  margin: 0;
  background: #fff; }

body,
caption,
div,
p,
td,
th {
  color: #496272;
  font-family: "bpreplayregular", "Open Sans", "sans-serif", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  -webkit-font-smoothing: antialiased; }

strong {
  font-weight: 600; }

hr {
  border: 0;
  border-bottom: 1px solid rgba(73, 98, 114, 0.07);
  margin: 30px 0; }

p {
  padding: 0 0 15px;
  margin: 0; }
  p img[style*='left'] {
    margin: 0 20px 20px 0; }
  p img[style*='right'] {
    margin: 0 0 20px 20px; }
  p .rtecenter {
    text-align: center; }
  @media screen and (max-width: 767px) {
    p {
      font-size: 12px;
      line-height: 1.5; } }

.front .pane-node-body p {
  padding: 0 0 25px; }

p.intro {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px; }
  p.intro.blauw {
    color: #385591; }
  @media screen and (max-width: 767px) {
    p.intro {
      font-size: 15px;
      line-height: 20px; } }

em {
  font-style: italic; }

a {
  color: #D60521;
  text-decoration: none;
  cursor: pointer; }
  a:hover {
    color: #D60521; }

.content img {
  height: auto !important; }

.content ul {
  list-style-type: none;
  list-style-position: outside;
  margin: 0 0 10px 18px; }
  .content ul li {
    list-style-type: square;
    margin: 0; }
  .content ul p {
    display: inline; }

.content .item-list > ul li {
  list-style: none;
  margin-bottom: 15px;
  padding: 0 7.5px; }

.content ol {
  list-style-position: outside;
  margin: 0 0 10px 18px;
  list-style: decimal; }
  .content ol p {
    display: inline; }

.content table {
  width: 100% !important; }
  .content table th {
    text-align: left;
    padding: 0 0 5px 10px;
    font-weight: bold; }
  .content table tbody tr td {
    padding: 5px 7px;
    border: 1px solid rgba(73, 98, 114, 0.07); }

span.icon-usp:before {
  font-family: "sima-europe";
  font-size: 180px; }

span.icon-wish:before {
  content: "\e007";
  color: #385591; }

span.icon-nature:before {
  content: "\e003";
  color: #D60521; }

span.icon-unique:before {
  content: "\e004";
  color: #00A1BD; }

span.icon-timing:before {
  content: "\e005";
  color: #385591; }

span.icon-quality:before {
  content: "\e006";
  color: #D60521; }

span.icon-communication:before {
  content: "\e002";
  color: #00A1BD; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "bpreplayregular", "Open Sans", "sans-serif", Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin: 15px 0;
  line-height: 1.4; }
  h1,
  h1 a,
  h2,
  h2 a,
  h3,
  h3 a,
  h4,
  h4 a,
  h5,
  h5 a,
  h6,
  h6 a {
    color: #496272; }

h1 {
  line-height: 36px; }

h2 {
  line-height: 24px; }

h1, h1 a {
  color: #00A1BD; }

h2, h2 a {
  color: #385591; }

h3, h3 a {
  color: rgba(44, 98, 113, 0.4); }

h4, h4 a {
  color: #385591; }

h5, h5 a {
  color: #385591; }

h6, h6 a {
  color: #385591; }

h1 {
  font-size: 30px; }
  @media screen and (max-width: 424px) {
    h1 {
      font-size: 20px; } }

h2 {
  font-size: 18px; }
  @media screen and (max-width: 424px) {
    h2 {
      font-size: 16px; } }

h3 {
  font-size: 21px; }
  @media screen and (max-width: 424px) {
    h3 {
      font-size: 14px; } }

h4 {
  font-size: 16px; }
  @media screen and (max-width: 424px) {
    h4 {
      font-size: 12px; } }

h5 {
  font-size: 16px; }
  @media screen and (max-width: 424px) {
    h5 {
      font-size: 12px; } }

h6 {
  font-size: 16px; }
  @media screen and (max-width: 424px) {
    h6 {
      font-size: 12px; } }

span.red, span.red a {
  color: #D60521 !important; }

span.blue-d, span.blue-d a {
  color: #385591 !important; }

span.blue-l, span.blue-l a {
  color: #00A1BD !important; }

span.gray, span.gray a {
  color: #909090 !important; }

blockquote {
  position: relative;
  padding: 0 60px;
  width: 100%;
  float: left;
  border-color: #909090;
  margin: 10px 0; }
  blockquote p {
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    font-style: italic;
    margin: 0;
    padding: 0; }
    blockquote p:after, blockquote p:before {
      font-size: 70px;
      line-height: 70px;
      width: 60px;
      font-weight: 700;
      position: absolute;
      font-family: 'Times New Roman', Times, serif;
      font-style: italic; }
    blockquote p:before {
      content: '\201C';
      float: left;
      left: 0;
      top: -6px; }
    blockquote p:after {
      content: '\201D';
      float: right;
      right: 0;
      bottom: -32px; }

#user-login .form-submit,
.btn,
.form-submit {
  background: #385591;
  font-size: 17px;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 5px;
  border: none; }
  .rteright #user-login .form-submit, .rteright
  .btn, .rteright
  .form-submit {
    float: right; }
  .rteleft #user-login .form-submit, .rteleft
  .btn, .rteleft
  .form-submit {
    float: left; }
  .rtecenter #user-login .form-submit, .rtecenter
  .btn, .rtecenter
  .form-submit {
    float: none;
    display: inline-block; }
  #user-login .form-submit:hover,
  .btn:hover,
  .form-submit:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer; }
  #user-login .form-submit.rand,
  .btn.rand,
  .form-submit.rand {
    background: transparent;
    border: 2px solid #385591;
    color: #385591; }

.form-submit {
  border-radius: 5px; }

.fotoalbum-overview .thumb.col a,
.fotoalbum-sectie .thumb.col a,
a.popup {
  position: relative;
  display: block;
  line-height: 0; }
  .fotoalbum-overview .thumb.col a:hover,
  .fotoalbum-sectie .thumb.col a:hover,
  a.popup:hover {
    opacity: .8; }
  .fotoalbum-overview .thumb.col a img,
  .fotoalbum-sectie .thumb.col a img,
  a.popup img {
    position: relative;
    z-index: 0; }
  .fotoalbum-overview .thumb.col a span.icon,
  .fotoalbum-sectie .thumb.col a span.icon,
  a.popup span.icon {
    background: url("../images/enlarge_icon_crop.png") no-repeat;
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: block;
    z-index: 1;
    height: 12px;
    width: 13px; }

p.popup {
  padding: 0;
  line-height: 0; }

.cke_editable,
.cke_editable_themed {
  padding: 10px !important; }
