//================================================
//=== HEADER =====================================
//================================================
.content-top {
  margin-top: 0;
}
.admin-menu {
  .header {
    top:59px;
  }
}
.header {
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: $white;
  border-bottom: #f2f3f4 1px solid;

  @include breakpoint(medium){
    .columns{
      width: 100%;
    }
  }

  .nine.columns {
    padding-top: 10px;
  }
}
.front .container.slider {padding-top: 170px;}
.not-front .container.content {padding-top: 170px;}

@include breakpoint(medium){
  .front .container.slider {padding-top: 103px;}
  .not-front .container.content {padding-top: 103px;}
}

#logo{
  img{
    @include transition(width);
    width: 150px;
    @include breakpoint(medium){
      width: 80px;
    }
  }
}

// Phonenumber infoblok
.block.phonenumber{
  .infoblok{
    padding: 7px 15px;

    p a{
      font-size: 16px;
      line-height: 19px;
      color: $blue-light;

      span{
        &:before{
          font-size: 16px;
          top: 2px;
          position: relative;
        }
      }
    }
  }
}

// Zoekveld
.block.zoekveld {
  #search-api-page-search-form-search {
    position: relative;
  }

  input[type='text'] {
    border: 1px solid #ddd;
    box-shadow: none;
    border-radius: 0;
    padding: 7px;
    padding-left: 10px;
  }

  #edit-keys-1,
  input.form-autocomplete {
    background: url('../images/ajax-loader.gif') right 50px no-repeat;
  }

  #edit-keys-1.throbbing,
  input.throbbing {
    background-position: 84% 10px;
  }

  .form-item{
    &:after{
      content: "\e001";
      font-family: $icon-font;
      position: absolute;
      right: 5px;
      // top: 10px;
      z-index: 1;
      font-size: 24px;
      color: $gray-dark;
      opacity: 0.52;
    }
  }
  input[type='submit'] {
    background: none;
    border: none;
    position: absolute;
    top: 1px;
    right: 1px;
    color: #666;
    font-size: 20px;
    // background: $white;
    padding: 4px 10px;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .form-text {
    float: right;
    min-width: 152px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

#autocomplete {
  border: 1px solid #ddd;
  border-top: 0;
  width: 300px!important;
}

#autocomplete ul li {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
}

#autocomplete ul li:hover {
  background: #ddd;
  cursor: pointer;
}
@include breakpoint(medium) {
  .search-form {
    display: none;
  }
}
