/* Generated by Font Squirrel (https://www.fontsquirrel.com) on November 7, 2016 */

@font-face {
    font-family: 'alpine_scriptmedium';
    src: url('fonts/alpine_script.woff2') format('woff2'),
         url('fonts/alpine_script.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'black_coffee_shadowbold';
    src: url('fonts/black-coffee-shadow.woff2') format('woff2'),
         url('fonts/black-coffee-shadow.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'black_coffee_regularbold';
    src: url('fonts/black-coffee.woff2') format('woff2'),
         url('fonts/black-coffee.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bpreplayregular';
    src: url('fonts/bpreplay.woff2') format('woff2'),
         url('fonts/bpreplay.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bpreplaybold';
    src: url('fonts/bpreplaybold.woff2') format('woff2'),
         url('fonts/bpreplaybold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bpreplaybold_italic';
    src: url('fonts/bpreplaybolditalics-webfont.woff2') format('woff2'),
         url('fonts/bpreplaybolditalics-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bpreplayitalic';
    src: url('fonts/bpreplayitalics-webfont.woff2') format('woff2'),
         url('fonts/bpreplayitalics-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'fh_inkregular';
    src: url('fonts/fh_ink-webfont.woff2') format('woff2'),
         url('fonts/fh_ink-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'hammers.and.stringsmedium';
    src: url('fonts/hammers.and.strings.woff2') format('woff2'),
         url('fonts/hammers.and.strings.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'taramdaregular';
    src: url('fonts/taramda.woff2') format('woff2'),
         url('fonts/taramda.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
