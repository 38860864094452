.content {
  @extend %clearfix;
}

//========================================
// Overzichten
//========================================
.overzicht {
  &:hover {
    a {
      opacity: 0.8;
    }
  }

  a {
    display: block;
    margin-bottom: 30px;

    img {
      transition: all 0.4s ease;
    }

    .views-field-field-afbeelding .field-content,
    .views-field-field-afbeeldingen .field-content {
      overflow: hidden;
      display: block;
      line-height: 0;
    }

    .views-field-title .field-content {
      color: $black;
      font-size: 18px;
      padding: 10px 0;
      display: block;
    }

    &:hover {
      text-decoration: none;

      opacity: 1 !important;

      img {
        transform: scale(1.1);
      }
    }
  }
}

//========================================
// infoblok
//=====================================//===
.infowrap {

  display: block;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 20px;

  &:hover {
    // opacity: 0.8;
  }

  .icoon {
    text-align: center;
    padding: 15px;

    .icon {
      &:before {
        display: inline-block;
        float: none;
        font-size: 34px;
        vertical-align: middle;

        .large & {
          margin: 0 0 10px;
          clear: both;
          line-height: initial;
          font-size: 86px;

        }
      }
    }

    p {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 19px;
      line-height: 20px;
      vertical-align: middle;

      .front & {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }

  &.border {
    border: 1px solid #C6C8CA;
  }

  &.imageleft {
    .image {
      width: 30%;
      float: left;
      min-height: 150px;
      background-size: cover!important;
      background-position: center!important;
    }

    .infoblok {
      width: 70%;
      float: left;
    }
  }

  &.imagetop {
    .image {
      width: 100%;
      float: left;
      min-height: 170px;
      background-size: cover!important;
      background-position: center!important;
    }

    .infoblok {
      width: 100%;
      float: left;
    }
  }

  &.linkwithbutton {
    &:after {
      content: '>';
      color: $white;
      background: $green-light;
      float: left;
      font-size: 22px;
      font-family: 'greendealscholen';
      position: absolute;
      bottom: 0;
      right: 0;
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      transition: all 0.4s ease;

    }

    &:hover {
      &:after {
        background: $black;
      }
    }
  }

  &:hover {
    text-decoration: none!important;

  }
}

div.infoblok {
  float: left;
  width: 100%;
  padding: 30px;
  background-size: cover!important;
  background-position: center!important;

  h2 {
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 25px;

  }

  p {
    padding-bottom: 0;
    font-size: 14px;
    /* font-weight: 400; */
    line-height: 20px;
  }

  a.btn {
    margin-bottom: 0;
  }

  &.kleur-2ba6cb,
  &.kleur-e3e3e3,
  &.kleur-f2f2f2 {
    a,
    blockquote,
    blockquote p:before,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    ul {
      color: $white;
    }

    blockquote {}

    .btn {
      background: $white;
      color: $red;
    }

    .btn.rand {
      background: transparent;
      border: 2px solid $white;
      color: $white;
    }
  }

  .color-e3e3e3 {
    background: #e3e3e3;
  }

  .color-f2f2f2 {
    background: #f2f2f2;
  }

  .color-2ba6cb {
    background: #2ba6cb;
  }

}

.bg-gray {
  background: rgba(73,98,114, 0.1);
  .pane-references{
    padding: 0 15px;
  }
}

// Homeblokken
.homeblokken{
  overflow: hidden;
  margin-bottom: 30px !important;
  padding-bottom: 30px !important;

  .columns {
    @include transition(transform);
    &:hover{
      animation: bounce1 .7s alternate;
      animation-iteration-count: 2;
    }
    // &.animated {
    //   transform: translateY(30px);
    // }

    z-index: 2;
    &:first-child {
      z-index: 3;
    }
    &:last-child {
      z-index: 1;
    }
  }

  .infoblok{
    @include transition(margin-top, box-shadow);
    // animation: bounce1 .7s alternate;
    box-shadow: 2px 2px 10px rgba(73, 98, 114, 0.4);
    margin-top: 80px;
    padding: 20px 10px;

    &.done {
      &,
      .block-string {
        animation: none;
      }
    }

    &:hover{
      box-shadow: 1px 1px 2px rgba(73, 98, 114, 0.4);
      // animation: bounce1 .7s alternate;
      .block-string{
        // animation: bounce1 .7s alternate;
      }
    }

    .block-string{
      @include transition(top);
      // animation: bounce1 .7s alternate;
      position: absolute;
      left:50%;
      transform: translateX(-50%);
      top:-40px;
    }

    h3{
      font-family: "black_coffee_shadowbold";
      font-size: 28px;
    }

    p{
      font-size: 14px;
      line-height: 21px;
      &:last-child{
        padding: 10px;
      }
    }

  }


  @include breakpoint(small) {
    .columns{
      width: 100% !important;
    }
  }
}

//
.banner {
  img {
    width: 100% !important;
  }
}


//========================================
// Iconen
//========================================
.icon {
  &:before {
    color: $white;
    float: left;
    margin: 0 15px 0 0;
    line-height: 20px;
  }
}

//========================================
// Isotope
//========================================
.isotope-container,
.isotope-container .isotope-element {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.isotope-container {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}

.isotope-container .isotope-element {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.isotope .isotope-item.no-transition,
.isotope.no-transition,
.isotope.no-transition .isotope-item {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}

.isotope-options {
  margin: 0 !important;
}

.isotope {
  margin: 20px 0 0;
  display: inline-block;
  width: 100%;

  > .view-content {
    margin: 0 -15px;
  }

}

.isotope-container {
  margin: 0 !important;
  padding: 0 !important;

  .isotope-element {
    width: 25%;
    padding: 0 15px;

    .drie & {
      width: 33.333333333333333%;
    }
    @include breakpoint(medium) {
      float: left!important;
      width: 50%!important;
      &.big,
      &.middel{
        width: 100%!important;
      }
    }
    @include breakpoint(small) {
      float: left!important;
      width: 100%!important;
      &.big,
      &.middel{

      }
    }
  }

}

//========================================
// Taxonomy
//========================================
.taxonomy-widget {

  padding: 30px;
  background: #efefef;
  margin-bottom: 30px;

  h2 {
    margin-top: 0;
    color: #001928;
    font-size: 20px;
  }

  ul li {
    list-style-type: square;

    a {
      color: #000;
      text-decoration: none;

      &.active {
        font-weight: 600;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

//========================================
// Anchor: correctie fixed header
//========================================
a.anchor {
  padding-top: 180px;
  margin-top: -180px;
  float: left;
  width: 100%;
  display: block!important;
  visibility: hidden!important;
}

//========================================
// Website berichten
//========================================
.messages {
  float: left;
  width: 100%;
  clear: both;
}

//========================================
// Snelmailer
//========================================
.snelmailer-widget {
  float: left;
  width: 100%;
  margin: 15px 0;

  .btn {
    font-size: 12px;
  }

  .container-error {
    display: none!important;
  }
}

//========================================
// Exposed form
//========================================
.views-exposed-form {
  label {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  }

  .form-radios {
    text-align: center;
  }

  .views-exposed-widget {
    width: 100%;

    input[type='radio'] {
      display: none;
    }

    input[type=radio]:checked + label {
      background: #3778ba;
      color: $white;
      border: 1px solid #3778ba;
    }

    .form-item {
      float: none;
      display: inline-block;
    }

    .option {
      float: left;
      padding: 8px 20px;
      min-width: 180px;
      background: $white;
      border-radius: 5px;
      text-align: center;
      margin: 10px 10px 0;
      color: #80b8f1;
      font-size: 15px;
      font-weight: 400;
      display: inline-block;
      border: 1px solid #80b8f1;

      &:hover {
        background: #80b8f1;
        color: $white;
      }
    }
  }

}

//========================================
// Paginering
//========================================
.pager {
  // background: $gray-dark;
  margin: 30px 0 0 !important;
  padding: 20px 0;

  li {
    background: none!important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: 0 !important;
    // margin-left: 7px !important;
  }
  .pager-item{
    padding: 0 !important;
  }

  .pager-current,
  .pager-item a {
    // background: $white!important;
    padding: 0 !important;
    text-align: center;
    border-radius: 2px;
    height: 18px;
    width: 18px;
    display: inline-block;
    line-height: 17px;
    color: $gray-light;
    font-size: 16px;
  }
  .pager-current{
    font-weight: 400;
    color:$red;
  }

  .pager-item a {
    color: $gray-light;
    // background: $gray-dark!important;
  }


  .pager-next a,
  .pager-previous a {
    color: $gray-light;
    font-size: 16px;
    position: relative;
    top:-1px;
    // font-weight: bold;
  }
}

//========================================
// Homeblokken
//========================================
.homeblok {
  background: #f1f1f1;
  float: left;
  width: 100%;
  padding: 15px;
}

//========================================
// Sitebar
//=====================================//===
x.sidebar {
  background: #e5e1d1;
  padding: 10px 20px;
}

x.sidebar ul {
  list-style: inside;
}

x.sidebar ul li {
  border-bottom:  1px solid rgba(73, 98, 114, 0.07);
  padding: 5px 10px;
  list-style-type: none;
}

//========================================
// Diversen
//========================================
.ajax-progress {
  position: absolute;
  left: 50%;
  bottom: 0;
}

.ajax-progress .throbber {
  background: url('../images/ajax-loader.gif');
  position: absolute;
  height: 11px;
  width: 16px;
  margin: 2px;
  z-index: 10;
}

:focus {
  outline: none;
}
/* Blauwe rand om menu weghalen*/
.anchor {
  display: block!important;
  content: ' '!important;
  margin-top: -200px !important;
  height: 200px!important;
  visibility: hidden!important;
}

a {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

//========================================
// Media embed
//========================================
.media_embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden;
}

.media_embed.widescreen {
  padding-bottom: 56.34%;
}

.media_embed.vimeo {
  padding-top: 0;
}

.media_embed embed,
.media_embed iframe,
.media_embed object,
.media_embed video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

img {
  height: auto;
}

embed,
img,
object {
  max-width: 100%;
}

img {
  -ms-interpolation-mode: bicubic;
}

#map_canvas img,
.map_canvas img {
  max-width: none!important;
}

.usp-list{
  border-top:  1px solid rgba(73, 98, 114, 0.07);
  border-bottom: 1px solid rgba(73, 98, 114, 0.07);
  padding: 30px 0 !important;
  h2{
    margin-top: 0;
  }
  .usp{
    text-transform: uppercase;
    margin-top: 20px;
    padding: 0 5px;
    p a{
      color: $gray-light;
    }
    .usp-icon{
      &:before{
        font-size: 120px;
      }
    }
    p a,
    .usp-icon:before {
      @include transition(color);
    }
    p:first-child {
      a {
        transition-duration: 0s;
      }
    }

    &:hover{
      p a,
      .usp-icon:before{
        color: $blue-light;
      }
      .icon-usp-wish:before,
      p a.usp-wish {color: $blue-dark;}
      .icon-usp-nature:before,
      p a.usp-nature {color: $red;}
      .icon-usp-unique:before,
      p a.usp-unique {color: $blue-light;}
      .icon-usp-timing:before,
      p a.usp-timing {color: $blue-dark;}
      .icon-usp-quality:before,
      p a.usp-quality {color: $red;}
      .icon-usp-communication:before,
      p a.usp-communication {color: $blue-light;}
    }
  }
  @include breakpoint(large) {
    .usp{
      .usp-icon{
        &:before{
          font-size: 80px;
        }
      }
    }
  }
  @include breakpoint(medium) {
    .usp{
      width: 33.333%;
    }
  }
  @include breakpoint(small) {
    .usp{
      width: 50% !important;
      float: right !important;
      min-height: 210px;
      .usp-icon{
        &:before{
          font-size: 80px;
        }
      }
    }
  }
}
.page-node-122 .usp p .icon-usp-wish{color:$blue-dark;}
.page-node-123 .usp p .icon-usp-nature{color:$red;}
.page-node-124 .usp p .icon-usp-unique{color:$blue-light;}
.page-node-125 .usp p .icon-usp-timing{color:$blue-dark;}
.page-node-126 .usp p .icon-usp-quality{color:$red;}
.page-node-127 .usp p .icon-usp-communication{color:$blue-light;}
.usp-content{
  padding-top:30px;
  padding-bottom:30px;
}

.reference{
  background: url('../images/reference-bg2.png') no-repeat;
  background-size: 100%;
  // height: 190px;
  padding: 30px 15px 30px 15px;
  margin-bottom: 50px;
  overflow: hidden;
  min-height: 190px;
  &:first-child{
    background: url('../images/reference-bg1.png') no-repeat;
    background-size: 100%;
  }
  &:last-child{
    background: url('../images/reference-bg3.png') no-repeat;
    background-size: 100%;
  }
  .columns:first-child{
    // padding-top: 0px !important;
    // padding-right: 0 !important;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 0;
  }
  .columns:last-child{
    // padding-top: 0px !important;
    // padding-left: 0 !important;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: 20px;
  }

  .quote p{
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $blue-light;
    padding-bottom: 10px;
  }
  .referent{
    font-size: 14px;
    font-weight: bold;
    color: $blue-light;
    display: block;
    text-align: center;
  }
}

.view-header{
  h3{
    margin-top: $column-gutter;
    color: $blue-dark;
    // opacity: .4;
    padding-left: 30px;
  }
}

// .container.content{
//   margin-bottom: 50px;
// }
@include breakpoint(large) {
  .reference{
    // background: url('../images/reference2.png') no-repeat;
    background-size: 100%;
    // height: 190px;
    padding: 30px 10px 20px 10px !important;
    margin-bottom: 50px;
    min-height: 140px;

    .columns:last-child{
      padding-top: 0px !important;
      padding-right: 15px !important;

    }

    .quote p{
      font-size: 12px;
      line-height: 16px;
      color: $blue-light;
    }
    .referent{
      font-size: 10px;
      font-weight: 600;
      color: $blue-light;
    }
  }
}

@include breakpoint(medium) {
  .reference{
    padding-top: 10px !important;

    background-position: center center !important;
    background-size: cover !important;
  }
}

@include breakpoint(small) {
  .reference{
    padding-top: 20px !important;
    width: 100% !important;
    .columns{
      // position: relative !important;
      // width: 50% !important;
      display: inherit;
      float: left !important;

      &:first-child {
        width: 66.666% !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      &:last-child {
        width: 33.333% !important;
        padding-left: 15px !important;
        right: 0;
      }
    }
  }
}


.node-type-usp{
  .book-row{
    background: url('/sites/all/themes/front/images/approach_slider-usp_background.jpg');
    background-size: 100%;
    height: 710px;
    padding-top: 50px;


    .panels-flexible-row-inside-first{
      background: url('/sites/default/files/afbeeldingen/slider/book-bg2.png') no-repeat;
      background-size: 100%;
      height: 610px;
      width: $row-width;
      margin: 0 auto;
      overflow: hidden;
      .six:first-child{
        padding: 30px 50px 30px 60px;

        .usp-images{
          width: 60%;
          margin: 0 auto;
          .even, .odd{
            border:  1px solid rgba(73, 98, 114, 0.07);
            padding: 10px 10px 30px 10px;
            transform: rotate(5deg);
            box-shadow: 0 0 3px $gray-light;
          }
          .odd{
            margin-top: 30px;
            transform: rotate(-5deg);
          }
        }
      }
      .six:last-child{
        padding: 50px 100px 50px 50px;
      }
    }
  }
  @include breakpoint(large) {
    .book-row{
      height: 600px;
      .panels-flexible-row-inside-first{
        width: 100%;
        height: 450px;

        .icon-usp:before{
          font-size: 80px;
        }
        .six:last-child{
          padding: 40px 70px 50px 30px;
          h1{
            margin: 0 0 5px;
          }
          p{
            font-size: 12px;
          }
        }
      }
    }
  }
  @include breakpoint(medium) {
    .book-row{
      height: auto;
      // background: none;
      .pane-custom{
        text-align: center;
        background: $white;
      }
      .pane-node-field-usp-tekst{
        background: $white;
        padding: 30px;
      }
      .panels-flexible-row-inside-first{
        background:none;
        height: auto;
        .usp-images{
          display: none;
        }

        .columns{
          width: 100% !important;
          padding: 0 30px !important;
        }
        .six:first-child{
          display: none;
        }
        .six:last-child{

          p{
            font-size: 12px;
          }
        }
      }
    }
  }
}


.back-to-overview{
  display: block;
  margin-top: 20px;
}


//language switcher
 .language-switcher {
   padding-top: 5px;
   .language {
     position: relative;
     border-left: 2px solid $gray-light;
     padding-left: 10px;
     &:first-child {
       border-left: 0;
     }
    &:before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      background-size: 22px !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    a {
      padding: 5px 10px 0 30px;
      font-size: 16px;
      line-height: 16px;
      display: inline-block;
      &:hover,
      &.active {
        color: $red;
      }
    }
    // &.active {
    //   a {
    //     color: $red;
    //   }
    // }
   }
   .en {
    &:before {
      background: url('/sites/all/themes/front/images/gb.png');
    }
   }
   .nl {
     &:before {
      background: url('/sites/all/themes/front/images/nl.png');
     }
   }
   .de {
     &:before {
      background: url('/sites/all/themes/front/images/de.png');
     }
   }
   .fr {
     &:before {
      background: url('/sites/all/themes/front/images/fr.png');
     }
   }
 }
