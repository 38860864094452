// Grid
$row-width: 1024px !default;
$column-gutter: 30px !default;
$total-columns: 12 !default;
$mobile-total-columns: 4 !default;
$header-height: 170px;


// Colors
$white: #fff !default;
$black: #000 !default;

$red: #D60521 !default;
$red-dark: #9e0d0d !default;
$red-light: #f05151 !default;

$yellow: #ebe41a !default;
$yellow-dark: #a6ab13 !default;
$yellow-light: #eaf044 !default;

$blue: #1b71dc !default;
$blue-dark: #385591 !default;
$blue-light: #00A1BD !default;

$purple: #761dda !default;
$purple-dark: #3d077b !default;
$purple-light: #994eee !default;

$green: #55a514 !default;
$green-dark: #3e8e0e !default;
$green-light: #a7ea7f !default;

$blue-gray: #476271 !default;

$gray: #909090 !default;
$gray-dark: #496272 !default;
$gray-light: rgba(44,98,113, .4) !default;
$gray-light2: #f2f3f4 !default;

$alert-color: #c60f13 !default;
$success-color: #5da423 !default;
$text-color: $gray-dark !default;
$highlight-color: #ff9 !default;


// Typography
$base-font-size: 14px;
$base-line-height: 1.5;

$header-size: (
  h1: 30,
  h2: 18,
  h3: 21,
  h4: 16,
  h5: 16,
  h6: 16,
);
$small-header-size: (
  h1: 20,
  h2: 16,
  h3: 14,
  h4: 12,
  h5: 12,
  h6: 12,
);

$icon-font: 'sima-europe';
$aleternative-icon-font: 'basis';
$default-font:'bpreplayregular', 'Open Sans', 'sans-serif', Arial, Helvetica, sans-serif !default;
$alternative-font:'bpreplayregular', 'Open Sans', 'sans-serif', Arial, Helvetica, sans-serif !default;

$header-font-family: $alternative-font !default;
$header-font-weight: 400 !default;
$header-font-style: normal !default;
$header-font-color: $text-color !default;

$body-font-family: $default-font !default;
$body-font-weight: 400 !default;
$body-font-style: normal !default;
$body-font-color: $text-color !default;


// Media Queries
//  use media queries with the mixin `breakpoint(small)` or other size
// Tablet Portrait = own MQ:
$screen-small: 424px !default;
$screen-medium: 767px !default;
$screen-large: 1023px !default;
$breakpoints: (
  small:  $screen-small,
  medium: $screen-medium,
  large:  $screen-large,
) !default;

// Tablet Portrait = Mobile:
// $screen-small:  767px !default;
// $screen-medium: 1023px !default;
// $screen-large:  1200px !default;
// $breakpoints: (
//   small:  $screen-small,
//   medium: $screen-medium,
//   large:  $screen-large,
// ) !default;

$breakpoint-classes: (small medium large) !default;


// Text Direction
$text-direction: ltr !default; // Controls default global text direction, 'rtl' or 'ltr'
$default-float: left !default;
$default-opposite: right !default;
@if $text-direction != ltr {
  $default-float: right;
  $default-opposite: left;
}


// Forms
$form-spacing: 16px !default;
$label-font-weight: 500 !default;
$label-font-color: $gray-dark !default;
$label-bottom-margin: 3px !default;
$input-font-color: $gray-dark !default;
$input-font-size: 14px !default;
$input-bg-color: #fff !default;
$input-focus-bg-color: darken($white, 2%) !default;
$input-border-color: darken($white, 20%) !default;
$input-focus-border-color: darken($white, 30%) !default;
$input-border-style: solid !default;
$input-border-width: 1px !default;
$input-border-radius: 0 !default;
$fieldset-border-radius: 3px !default;


// Transitions
$transition-duration: .3s !default;
$transition-easing: ease !default;
