@charset "UTF-8";

@font-face {
  font-family: "sima-europe";
  src:url("fonts/sima-europe.eot");
  src:url("fonts/sima-europe.eot?#iefix") format("embedded-opentype"),
    url("fonts/sima-europe.woff") format("woff"),
    url("fonts/sima-europe.ttf") format("truetype"),
    url("fonts/sima-europe.svg#sima-europe") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "sima-europe" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "sima-europe" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e000";
}
.icon-search:before {
  content: "\e001";
}
.icon-usp-communication:before {
  content: "\e002";
}
.icon-usp-nature:before {
  content: "\e003";
}
.icon-usp-unique:before {
  content: "\e004";
}
.icon-usp-timing:before {
  content: "\e005";
}
.icon-usp-quality:before {
  content: "\e006";
}
.icon-usp-wish:before {
  content: "\e007";
}
.icon-arrow-right:before {
  content: "\e008";
}
