//================================================
//=== VACATURES ==================================
//================================================

body.section-plaats,
body.section-vacatures,
body.section-werkveld {
  .container.content {
    //background:#e9f7ff;
  }

  .content-top {
    //padding-top: 20px;
  }
}

.vacature-overzicht {
  .sidebar {
    margin-top: 20px;
  }

  .blog-item {
    .meta {
      a {
        color: $black;
      }
    }

    .lees-meer {
      margin-top: 20px;
    }

    .content-wrap {
      width: 100%;
    }
  }

  .block {
    padding: 40px;
    background: $white;
    margin: 0 0 30px;
  }

}

.vacature-homepage {
  .blog-item {
    .meta {
      a {
        color: $black;
      }
    }

    .lees-meer {
      margin-top: 20px;
    }

    .content-wrap {
      width: 100%;
    }
  }
}

// OVERVIEW PAGINA
.vacature-detail {
  @include breakpoint(large) {
    .content {
      .first,
      .last {
        width: 50% !important;
      }
    }
  }

  @include breakpoint(medium) {
    .content {
      .first,
      .last {
        width: 100% !important;
      }
    }
  }

  .sidebar {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 20px;
      line-height: 20px;
      margin-top: 0;
    }
  }

  .meta-info {
    font-weight: bold;
    color: $black;
  }

  form {
    margin: 0;

  }

  .persoon.info {
    background: #efefef;
    padding: 30px;
    float: left;
    width: 100%;
    margin: 0;

    .view-content {
      margin: 10px 0 0;
      //border-top: 1px solid #D3E2EF;
      padding: 0 0 30px;
      //border-bottom: 1px solid rgb(210, 210, 210);
    }

    .view-header {

      padding: 0;

      p {
        font-size: inherit;
        font-weight: inherit;
      }
    }

    .titel {
      font-weight: bold;
      color: $black;
    }
  }

  .koppeling-persoon {
    padding: 30px 30px 0;
    float: left;
    width: 100%;
    background: #efefef;

  }

  .field-name-field-tekst {
    background: #565656;
    padding: 30px;
    float: left;
    width: 100%;
    margin: 0 0 30px;

    ol,
    ul {
      margin-bottom: 0;
    }

    * {
      color: $white;
    }
  }

  .field-name-field-webformulier-invoegen {
    background: #efefef;
    padding: 0 30px 30px;
    float: left;
    width: 100%;
    margin: 0;
  }

  .container.content {
    background: $white!important;

    h1 {
      margin-top: 0;
    }
  }

  .content-top {
    padding-top: 0;
  }

  .meta {
    background: #efefef;
    padding: 15px;
    color: $white;
    float: left;

    div {
      color: $white;
      float: left;
      width: 100%;
      font-weight: bold;
      margin-bottom: 3px;

      .views-label {
        float: left;
        margin-right: 3px;
      }

      .field-content {
        float: left;
        width: auto;
      }
    }

    .views-field-title {
      span {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }

    .views-field-field-evt-extra-opmerking {
      div {
        font-weight: normal;
      }
    }

  }

  .event-foto {
    float: left;
    width: 100%;
    margin-bottom: 40px;

    img {
      float: left;
      width: 100%;
    }
  }
}
