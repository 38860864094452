//================================================
//=== PRODUCTEN ==================================
//================================================
/*Product overview*/
.producten-row{
  border-top: 1px solid rgba(73, 98, 114, 0.07);
  overflow: hidden;

  .pane-title{
    margin: 10px 0 0;
    float: left;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: $gray-light;
    &:after{
      content: "\e008";
      font-family: $icon-font;
      position: relative;
      font-size: 10px;
      // top:1px;
      left:5px;
      margin-right: 5px;
    }
  }

  .collectie-filter,
  .soort-filter{
    position: absolute;
    top:95px;
    width: 100%;
    padding: 20px;
    padding-right: 50px;
    // animation: bounce3 1.5s;
    animation: bounce1 .7s alternate;
    animation-iteration-count: 2;
    z-index: 100;
  }
  .soort-filter{
    // padding-top:0;
    top:130px;
    // animation: bounce4 1.5s;
  }


  .product-collection-filter,
  .product-soort-filter {

    ul{
      margin: 0;
      border-top: 1px solid rgba(73, 98, 114, 0.07);
      border-bottom: 1px solid rgba(73, 98, 114, 0.07);
      padding: 10px 0;
      li{
        display: inline-block;
        margin-bottom: 0 !important;
        border-right: 1px solid $blue-dark;
        &.last{
          border-right: none;
        }
        .filterbutton,
        .categorie{
          color: $blue-dark;
          text-transform: uppercase;
          display: block;
          // padding-top: 2px;
          font-size: 14px;
          line-height: 20px;
          &:hover{
            color: $blue-light;
            .categorie{
              color:$blue-light;
            }
          }
          &.selected{
            color: $red;
            .categorie{
              color:$red;
            }
          }
        }
      }
    }
  }
  .product-soort-filter{
    ul{
      border-top:none;
    }
  }

  .product-overview{
    animation: bounce1 .7s alternate;
    animation-iteration-count: 2;
    margin-top: 80px;
    box-shadow: 1px 1px 5px rgba(73, 98, 114, 0.5);
    // overflow: hidden;
    margin-bottom: 10px;
    padding: 80px 0px;
    position: relative;

    .product-titel{
      position: absolute;
      bottom: 0px;
      left: 50%;
      text-transform: uppercase;
      padding: 10px 20px;
      background: #fff;
      min-width: 130px;
      text-align: center;
      transform: translateX(-50%);
    }

    .block-string{
      // animation: bounce1 .7s alternate;
      animation-iteration-count: 2;
      position: absolute;
      top:-120px;
      &.left{
        left:10%;
        transform: translateX(-10%);
      }
      &.right{
        right:10%;
        transform: translateX(-10%);
      }
    }

  }

  @include breakpoint(medium){
    .pane-title{
      font-size: 10px;
    }
    .product-collection-filter,
    .product-soort-filter {

      ul li .filterbutton,
      ul li .categorie {
        font-size: 10px;
      }
    }
  }
}



.product-overview .row {
  margin-bottom: 50px;
}

.product-overview [class*='column']+[class*='column']:last-child {
  float: left;
}

.product-item {
  .col {
    float: left;
    width: 100%;
  }

  .thumb img {
    width: 100%;
  }

  .lees-meer {
    border-top:  1px solid rgba(73, 98, 114, 0.07);
    padding-top: 5px;
    margin-top: 5px;

    a {
      float: right;
    }
  }
}

/*Product detail*/
.product-detail {
  margin: 0 -15px;

  .col {
    width: 50%;
    float: left;
    padding: 0 15px;
  }

  .thumb img {
    width: 100%;
  }

  .prijs {
    font-size: 30px;
    line-height: 40px;
    border-top:  1px solid rgba(73, 98, 114, 0.07);
    padding-top: 7px;
    margin-top: 10px;
    text-align: right;
  }
}

.isotope-container{
  height: auto !important;
}

.masonry-processed{
  height: auto !important;
}

.isotope-element{
  @include transition(top);
  transition: top .2s, position .2s;
  // transition: display;
}

.masonry-item,
.isotope-element {
  width: 21% !important;
  margin: 2% !important;
  padding: 0 !important;
  background: none;
  float: left;
  border: none;
  position: relative !important;
  left: auto !important;
  top: auto !important;
  min-height: 215px;
  // background-color: #696969;

  img {
    // opacity: .9;
  }

  &.middel{
    width: 46% !important;
    height: 206px;
    overflow: hidden;
  }
  &.big{
    width: 46% !important;
  }

  a{
    line-height: 0;
    display: inherit;
  }
}

.masonry-item.extended {
  width: 66.6%;

  .item-inner {
    background: $white;
  }

  .datum {
    color: $white;
  }

  .tags li {
    color: $white;
    border: 1px solid $white;
  }
}

.masonry-item.quotes {
  .item-inner {
    background: #2ccefd;
  }

  .content {
    font-size: 22px;
    line-height: 25px;
    font-weight: 100;
    color: $white;
    padding: 20px 40px;
  }

  .quote-klant {
    float: right;
    clear: both;
    font-size: 18px;
    font-weight: 100;
    color: $white;
    padding: 0 40px;
  }

  .quote-functie {
    float: right;
    clear: both;
    font-weight: 100;
    font-size: 13px;
    padding-top: 3px;
    color: $white;
    padding: 5px 40px 20px;
  }
}

@include breakpoint(large) {
  .masonry-item,
  .masonry-item.big,
  .masonry-item.middel,
  .isotope-element,
  .isotope-element.big,
  .isotope-element.middel {
    width: 46% !important;
    height: 100%;
  }

  .masonry-item.extended {
    width: 100%;
  }
}
@include breakpoint(medium) {
  .masonry-item,
  .masonry-item.big,
  .masonry-item.middel,
  .isotope-element,
  .isotope-element.big,
  .isotope-element.middel  {
    width: 100% !important;
    margin: 0 !important;
    padding: 10px !important;
  }

  .masonry-item.extended {
    width: 100%;
  }
}
