//================================================
//=== ADMIN ======================================
//================================================
html body.admin-menu {
  margin-top: 59px !important;
}

#user-login {
  max-width: 270px;
  margin: 50px auto;
  border: 1px solid #EAE9E9;
  padding: 40px;
  background: #FDFDFD;
}

#user-login .description {
  display: none;
}

#user-login .form-submit {
  float: none;
  margin-bottom: 0;
  width: 100%;
}

#user-login .form-item {
  margin: 0;
}

.panels-flexible-region-inside {
  padding: 0 !important;
}

.admin-tabs ul {
  padding: 0;
  margin: 0;
  border: none!important;
  position: fixed;
  top: 29px;
  height: 30px;
  background: #333;
  width: 100%;
  z-index: 999;

  // li {
  //   float: left;
  // }
}

.not-logged-in.section-user {
  padding-top: 30px;
}

.not-logged-in .admin-tabs ul {
  top: 0;
}

#admin-menu-icon a.active-trail {
  background: none!important;
  border-radius: 0!important;
}

#admin-menu-icon a:hover {
  background-color: #444!important;
}

.admin-tabs .tabs {
  border: none;
}

.admin-tabs ul li a {
  height: 22px!important;
  line-height: 19px;
  color: #fafafa;
  padding: 2px 10px 3px;
  letter-spacing: 0;
  font-size: 13px;
  margin: 4px 6px;
  border-radius: 10px!important;
}

.admin-tabs ul li a:hover {
  text-decoration: none;
  border-radius: 10px;
}

.admin-tabs ul li a:active,
.admin-tabs ul li a:focus {
  color: $white;
  font-weight: normal;
  border-radius: 10px;
}

#admin-menu {
  background: $green;
  box-shadow: none;
}

#admin-menu li.admin-menu-account > a {
  background: none;
}

#admin-menu-wrapper {
  padding: 5px 10px 0;
}

#admin-menu > div > .dropdown > li > a {
  border-radius: 0!important;
  margin: 0;
  padding: 2px 10px 7px;
}

#admin-menu-account .admin-menu-account,
#admin-menu-search,
#admin-menu-users,
.shortcut-toggle {
  display: none;
}

#admin-menu .dropdown .admin-menu-toolbar-category > a.active-trail {
  background: #6BB92A;
}

#admin-menu .dropdown li li a {}

#edit-body-format,
.ckeditor_links {
  display: none;
}

#admin-menu .dropdown .admin-menu-icon > a {
  padding: 2px 10px 8px;
}

.admin-tabs .tabs-primary,
.admin-tabs .tabs-secondary {
  background-image: none;
}

.admin-tabs .tabs-primary__tab,
.admin-tabs .tabs-primary__tab.is-active {
  border-radius: 0;
  text-shadow: none;
  border: none;
}

.admin-tabs a.tabs-primary__tab-link.is-active,
.admin-tabs a.tabs-primary__tab-link:active {
  border-radius: 0;
  background-image: none;
  border: none;
}

.admin-tab a.tabs-primary__tab-link.is-active,
.admin-tabs a.tabs-primary__tab-link {
  background: none;
  border: none;
  color: #fafafa;
  letter-spacing: 0;
}

.admin-tabs .tabs-primary__tab.is-active a,
.admin-tabs ul li a:hover {
  background: #999!important;
  padding: 2px 10px 3px;
  border-radius: 10px;
}

.admin-tabs h2 {
  display: none;
}

#admin-menu .dropdown li li a[href='/admin/structure/block/list/seven_override'] {
  display: none;
}

#admin-menu .dropdown .admin-menu-icon > a span,
#admin-menu .dropdown .admin-menu-icon > a span {
  background: url('../images/react-logo.png');
  height: 14px;
  width: 14px;
}

#admin-menu {
  background: $green;
  box-shadow: none;
}

#admin-menu li.admin-menu-account > a {
  background: none;
}

#admin-menu-account .admin-menu-account,
#admin-menu-search,
#admin-menu-users {
  display: none;
}

#admin-menu * {
  font: 12px 'Lucida Grande', 'Lucida Sans Unicode', sans-serif;
}
