@import 'settings';
@import 'utils/mixins';

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 100%;
  margin: 0;
  background: $white;
}

body,
caption,
div,
p,
td,
th {
  color: $body-font-color;
  font-family: $body-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: $body-font-weight;
  -webkit-font-smoothing: antialiased;
}

strong {
  font-weight: 600;
}

hr {
  border: 0;
  border-bottom:  1px solid rgba(73, 98, 114, 0.07);
  margin: 30px 0;
}

p {
  padding: 0 0 15px;
  margin: 0;

  img[style*='left'] {
    margin: 0 20px 20px 0;
  }

  img[style*='right'] {
    margin: 0 0 20px 20px;
  }

  .rtecenter {
    text-align: center;
  }
  @include breakpoint(medium) {
    font-size: 12px;
    line-height: $base-line-height;
  }
}
.front{
  .pane-node-body{
    p{
      padding: 0 0 25px;
    }
  }
}

// Intro paragraaf
p.intro {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  &.blauw {
    color: $blue-dark;
  }
  @include breakpoint(medium) {
    font-size: 15px;
    line-height: 20px;
  }
}

em {
  font-style: italic;
}

a {
  color: $red;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $red;
    // text-decoration: underline;
  }
}

// CONTENT STIJLEN
.content {
  img {
    height: auto !important;
  }

  ul {
    list-style-type: none;
    list-style-position: outside;
    margin: 0 0 10px 18px;

    li {
      list-style-type: square;
      margin: 0;
    }

    p {
      display: inline;
    }
  }

  .item-list > ul li {
    list-style: none;
    margin-bottom: $column-gutter / 2;
    padding: 0 $column-gutter / 4;
  }

  ol {
    list-style-position: outside;
    margin: 0 0 10px 18px;
    list-style: decimal;

    p {
      display: inline;
    }
  }

  table {
    width: 100% !important;

    th {
      text-align: left;
      padding: 0 0 5px 10px;
      font-weight: bold;
    }

    tbody tr td {
      padding: 5px 7px;
      border:  1px solid rgba(73, 98, 114, 0.07);
    }
  }
}
span.icon-usp:before{
  font-family: "sima-europe";
  font-size: 180px;
}
span.icon-wish:before{content:"\e007"; color: $blue-dark;}
span.icon-nature:before{content:"\e003"; color: $red;}
span.icon-unique:before{content:"\e004"; color: $blue-light;}
span.icon-timing:before{content:"\e005"; color: $blue-dark;}
span.icon-quality:before{content:"\e006"; color: $red;}
span.icon-communication:before{content:"\e002"; color: $blue-light;}

// Headers
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  font-weight: $header-font-weight;
  margin: 15px 0;
  line-height: 1.4;

  &,
  & a {
    color: $header-font-color;
  }
}
h1{  line-height: 36px;}
h2{  line-height: 24px;}

h1, h1 a { color: $blue-light; }
h2, h2 a { color: $blue-dark; }
h3, h3 a { color: $gray-light; }
h4, h4 a { color: $blue-dark; }
h5, h5 a { color: $blue-dark; }
h6, h6 a { color: $blue-dark; }

// Set headers size (h1-h6)
// See _settings.scss for sizes
@for $i from 1 through length($header-size) {
  $_el: h#{$i};
  $_size: map-get($header-size, $_el);
  $_size-small: map-get($small-header-size, $_el);

  #{$_el} {
    font-size: #{$_size}px;
    @include breakpoint(small) {
      font-size: #{$_size-small}px;
    }
  }
}

span.red, span.red a{color:$red !important;}
span.blue-d, span.blue-d a{color:$blue-dark !important;}
span.blue-l, span.blue-l a{color:$blue-light !important;}
span.gray, span.gray a{color:$gray !important;}


// Blockquote
blockquote {
  position: relative;
  padding: 0 60px;
  width: 100%;
  float: left;
  border-color: $gray;
  margin: 10px 0;

  p {
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    font-style: italic;
    margin: 0;
    padding: 0;

    &:after,
    &:before {
      font-size: 70px;
      line-height: 70px;
      width: 60px;
      font-weight: 700;
      position: absolute;
      font-family: 'Times New Roman', Times, serif;
      font-style: italic;
    }

    &:before {
      content: '\201C';
      float: left;
      left: 0;
      top: -6px;
    }

    &:after {
      content: '\201D';
      float: right;
      right: 0;
      bottom: -32px;
    }

  }
}

// ul.paddingFooter {
//   margin: 43px 0 15px;
//   @include breakpoint(medium) {
//     margin-top: 0;
//   }
// }

// Buttonsront
#user-login .form-submit,
.btn,
.form-submit {
  background: $blue-dark;
  font-size: 17px;
  clear: both;
  font-weight: 400;
  color: $white;
  text-decoration: none;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 5px;

  //float: left;
  border: none;

  .rteright & {
    float: right;
  }

  .rteleft & {
    float: left;
  }

  .rtecenter & {
    float: none;
    display: inline-block;
  }

  &:hover {
    color: $white;
    text-decoration: none;
    // opacity: 0.7;
    cursor: pointer;
  }

  &.rand {
    background: transparent;
    border: 2px solid $blue-dark;
    color: $blue-dark;
  }
}

.form-submit {
  border-radius: 5px;
}

.fotoalbum-overview .thumb.col a,
.fotoalbum-sectie .thumb.col a,
a.popup {
  position: relative;
  display: block;
  line-height: 0;

  &:hover {
    opacity: .8;
  }

  img {
    position: relative;
    z-index: 0;
  }

  span.icon {
    background: url('../images/enlarge_icon_crop.png') no-repeat;
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: block;
    z-index: 1;
    height: 12px;
    width: 13px;
  }

}

p.popup {
  padding: 0;
  line-height: 0;
}

// Diversen
.cke_editable,
.cke_editable_themed {
  padding: 10px !important;
}
