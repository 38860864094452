@charset 'UTF-8';

@font-face {
  font-family: 'basis';
  src:url('fonts/basis.eot');
  src:url('fonts/basis.eot?#iefix') format('embedded-opentype'),
    url('fonts/basis.woff') format('woff'),
    url('fonts/basis.ttf') format('truetype'),
    url('fonts/basis.svg#basis') format('svg');
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: 'basis' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'basis' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fontawesome-webfont:before {
  content: 'a';
}
.icon-fontawesome-webfont-1:before {
  content: 'b';
}
.icon-fontawesome-webfont-2:before {
  content: 'c';
}
.icon-fontawesome-webfont-3:before {
  content: 'd';
}
.icon-fontawesome-webfont-4:before {
  content: 'e';
}
.icon-fontawesome-webfont-5:before {
  content: 'f';
}
.icon-fontawesome-webfont-6:before {
  content: 'g';
}
.icon-fontawesome-webfont-7:before {
  content: 'h';
}
.icon-fontawesome-webfont-8:before {
  content: 'i';
}
.icon-fontawesome-webfont-9:before {
  content: 'j';
}
.icon-fontawesome-webfont-10:before {
  content: 'k';
}
.icon-fontawesome-webfont-11:before {
  content: 'l';
}
.icon-fontawesome-webfont-12:before {
  content: 'm';
}
.icon-fontawesome-webfont-14:before {
  content: 'o';
}
.icon-fontawesome-webfont-15:before {
  content: 'p';
}
.icon-fontawesome-webfont-16:before {
  content: 'q';
}
.icon-fontawesome-webfont-17:before {
  content: 'r';
}
.icon-fontawesome-webfont-19:before {
  content: 't';
}
.icon-fontawesome-webfont-20:before {
  content: 'u';
}
.icon-fontawesome-webfont-21:before {
  content: 'v';
}
.icon-fontawesome-webfont-22:before {
  content: 'w';
}
.icon-fontawesome-webfont-23:before {
  content: 'x';
}
.icon-fontawesome-webfont-24:before {
  content: 'y';
}
.icon-fontawesome-webfont-25:before {
  content: 'z';
}
.icon-fontawesome-webfont-26:before {
  content: 'A';
}
.icon-fontawesome-webfont-27:before {
  content: 'B';
}
.icon-fontawesome-webfont-28:before {
  content: 'C';
}
.icon-fontawesome-webfont-29:before {
  content: 'D';
}
.icon-fontawesome-webfont-37:before {
  content: 'L';
}
.icon-fontawesome-webfont-38:before {
  content: 'M';
}
.icon-fontawesome-webfont-39:before {
  content: 'N';
}
.icon-fontawesome-webfont-40:before {
  content: 'O';
}
.icon-fontawesome-webfont-41:before {
  content: 'P';
}
.icon-fontawesome-webfont-42:before {
  content: 'Q';
}
.icon-fontawesome-webfont-43:before {
  content: 'R';
}
.icon-fontawesome-webfont-44:before {
  content: 'S';
}
.icon-fontawesome-webfont-45:before {
  content: 'T';
}
.icon-fontawesome-webfont-46:before {
  content: 'U';
}
.icon-fontawesome-webfont-47:before {
  content: 'V';
}
.icon-fontawesome-webfont-48:before {
  content: 'W';
}
.icon-fontawesome-webfont-49:before {
  content: 'X';
}
.icon-fontawesome-webfont-50:before {
  content: 'Y';
}
.icon-fontawesome-webfont-51:before {
  content: 'Z';
}
.icon-fontawesome-webfont-53:before {
  content: '1';
}
.icon-fontawesome-webfont-54:before {
  content: '2';
}
.icon-fontawesome-webfont-55:before {
  content: '3';
}
.icon-fontawesome-webfont-56:before {
  content: '4';
}
.icon-fontawesome-webfont-57:before {
  content: '5';
}
.icon-fontawesome-webfont-60:before {
  content: '8';
}
.icon-fontawesome-webfont-61:before {
  content: '9';
}
.icon-fontawesome-webfont-63:before {
  content: '\'';
}
.icon-fontawesome-webfont-64:before {
  content: '#';
}
.icon-fontawesome-webfont-65:before {
  content: '$';
}
.icon-fontawesome-webfont-66:before {
  content: '%';
}
.icon-fontawesome-webfont-67:before {
  content: '&';
}
.icon-fontawesome-webfont-68:before {
  content: '\'';
}
.icon-fontawesome-webfont-69:before {
  content: '(';
}
.icon-fontawesome-webfont-70:before {
  content: ')';
}
.icon-fontawesome-webfont-71:before {
  content: '*';
}
.icon-fontawesome-webfont-72:before {
  content: '+';
}
.icon-fontawesome-webfont-74:before {
  content: '-';
}
.icon-fontawesome-webfont-75:before {
  content: '.';
}
.icon-fontawesome-webfont-76:before {
  content: '/';
}
.icon-fontawesome-webfont-77:before {
  content: ':';
}
.icon-fontawesome-webfont-78:before {
  content: ';';
}
.icon-fontawesome-webfont-79:before {
  content: '<';
}
.icon-fontawesome-webfont-80:before {
  content: '=';
}
.icon-fontawesome-webfont-81:before {
  content: '>';
}
.icon-fontawesome-webfont-82:before {
  content: '?';
}
.icon-fontawesome-webfont-86:before {
  content: '^';
}
.icon-fontawesome-webfont-87:before {
  content: '_';
}
.icon-fontawesome-webfont-88:before {
  content: '`';
}
.icon-fontawesome-webfont-89:before {
  content: '{';
}
.icon-fontawesome-webfont-90:before {
  content: '|';
}
.icon-fontawesome-webfont-91:before {
  content: '}';
}
.icon-fontawesome-webfont-92:before {
  content: '~';
}
/*.icon-fontawesome-webfont-93:before {
  content: '\';
}*/
.icon-fontawesome-webfont-94:before {
  content: '\e000';
}
.icon-fontawesome-webfont-95:before {
  content: '\e001';
}
.icon-fontawesome-webfont-96:before {
  content: '\e002';
}
.icon-fontawesome-webfont-97:before {
  content: '\e003';
}
.icon-fontawesome-webfont-98:before {
  content: '\e004';
}
.icon-linkedin-circled:before {
  content: '\e005';
}
.icon-pinterest-circled:before {
  content: '\e006';
}
.icon-gplus-circled:before {
  content: '\e008';
}
.icon-gplus:before {
  content: '\e009';
}
.icon-cog:before {
  content: '\e00a';
}
.icon-code:before {
  content: '\e00b';
}
.icon-chart-line:before {
  content: '\e00c';
}
.icon-chart-bar:before {
  content: '\e00d';
}
.icon-check:before {
  content: '\e00e';
}
.icon-link:before {
  content: '\e00f';
}
.icon-pinterest:before {
  content: '\e010';
}
.icon-linkedin:before {
  content: '\e011';
}
.icon-skype:before {
  content: '\e012';
}
.icon-vimeo-circled:before {
  content: '\e013';
}
.icon-vimeo:before {
  content: '\e014';
}
.icon-vkontakte:before {
  content: '\e015';
}
.icon-user-add:before {
  content: '\e016';
}
.icon-twitter:before {
  content: '\e017';
}
.icon-twitter-circled:before {
  content: '\e018';
}
.icon-rss:before {
  content: '\e019';
}
.icon-facebook-places:before {
  content: '\e01a';
}
.icon-drupal:before {
  content: '\e01b';
}
.icon-wordpress:before {
  content: '\e01c';
}
.icon-rnm-react:before {
  content: '\e01d';
}
.icon-facebook-flat:before {
  content: '\e01e';
}
.icon-googleplus-flat:before {
  content: '\e01f';
}
.icon-linkedin-flat:before {
  content: '\e020';
}
.icon-twitter-flat:before {
  content: '\e021';
}
.icon-check-1:before {
  content: '\e022';
}
.icon-widget:before {
  content: '\e023';
}
.icon-fontawesome-webfont-13:before {
  content: 'n';
}
.icon-fontawesome-webfont-30:before {
  content: 'E';
}
.icon-fontawesome-webfont-31:before {
  content: 'F';
}
.icon-fontawesome-webfont-34:before {
  content: 'I';
}
.icon-fontawesome-webfont-99:before {
  content: '\e024';
}
.icon-fontawesome-webfont-100:before {
  content: '\e025';
}
.icon-fontawesome-webfont-101:before {
  content: '\e026';
}
.icon-fontawesome-webfont-102:before {
  content: '\e027';
}
.icon-fontawesome-webfont-103:before {
  content: '\e028';
}
.icon-fontawesome-webfont-104:before {
  content: '\e029';
}
.icon-fontawesome-webfont-105:before {
  content: '\e02a';
}
.icon-fontawesome-webfont-106:before {
  content: '\e02b';
}
.icon-fontawesome-webfont-107:before {
  content: '\e02c';
}
.icon-fontawesome-webfont-108:before {
  content: '\e02d';
}
.icon-fontawesome-webfont-109:before {
  content: '\e02e';
}
.icon-fontawesome-webfont-110:before {
  content: '\e02f';
}
.icon-fontawesome-webfont-112:before {
  content: '\e031';
}
.icon-fontawesome-webfont-113:before {
  content: '\e032';
}
.icon-fontawesome-webfont-114:before {
  content: '\e033';
}
.icon-fontawesome-webfont-115:before {
  content: '\e034';
}
.icon-fontawesome-webfont-116:before {
  content: '\e035';
}
.icon-fontawesome-webfont-117:before {
  content: '\e036';
}
.icon-fontawesome-webfont-118:before {
  content: '\e037';
}
.icon-fontawesome-webfont-119:before {
  content: '\e038';
}
.icon-fontawesome-webfont-83:before {
  content: '@';
}
.icon-fontawesome-webfont-84:before {
  content: '[';
}
.icon-fontawesome-webfont-120:before {
  content: '\e039';
}
.icon-chart-pie:before {
  content: '\e03a';
}
.icon-cancel-squared:before {
  content: '\e03b';
}
.icon-cancel-circled:before {
  content: '\e03c';
}
.icon-comment:before {
  content: '\e03d';
}
.icon-heart-empty:before {
  content: '\e03e';
}
.icon-heart:before {
  content: '\e03f';
}
.icon-help-circled:before {
  content: '\e040';
}
.icon-info-circled:before {
  content: '\e041';
}
.icon-share:before {
  content: '\e042';
}
.icon-youtube-alt:before {
  content: '\e043';
}
.icon-fontawesome-webfont-18:before {
  content: 's';
}
.icon-info:before {
  content: 'G';
}
.icon-erase:before {
  content: 'H';
}
.icon-export:before {
  content: 'J';
}
.icon-globe:before {
  content: 'K';
}
.icon-magnifying-glass:before {
  content: '0';
}
.icon-social-apple:before {
  content: '6';
}
.icon-social-android:before {
  content: '7';
}
.icon-attention:before {
  content: '!';
}
.icon-back:before {
  content: ',';
}
.icon-facebook:before {
  content: ']';
}
.icon-facebook-circled:before {
  content: '\e007';
}
.icon-facebook-squared:before {
  content: '\e030';
}
.icon-bubble:before {
  content: '\e044';
}
.icon-fontawesome-webfont-32:before {
  content: '\e045';
}
.icon-fontawesome-webfont-33:before {
  content: '\e046';
}
.icon-fontawesome-webfont-35:before {
  content: '\e047';
}
.icon-fontawesome-webfont-36:before {
  content: '\e048';
}
.icon-star:before {
  content: '\e049';
}
.icon-fontawesome-webfont-52:before {
  content: '\e04a';
}
