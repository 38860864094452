/* Requires
   globals.css */

/* Standard Forms ---------------------- */

form {
  margin: 0 0 ($form-spacing);
}

.row form .row {
  margin: 0 ($form-spacing / -2);

  .column,
  .columns {
    padding: 0 ($form-spacing / 2);
  }

  &.collapse {
    margin: 0;

    .column,
    .columns {
      padding: 0;
    }
  }
}

label {
  font-size: ms(0);
  color: $label-font-color;
  cursor: pointer;
  display: block;
  padding: 5px 0;
  font-weight: $label-font-weight;
  margin-bottom: $label-bottom-margin;

  &.right {
    float: none;
    text-align: right;
  }

  &.inline {
    line-height: (ms(0) + ($form-spacing * 1.5));
    margin: 0 0 $form-spacing;
  }
}

.postfix,
.prefix {
  display: block;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  height: (ms(0) + ($form-spacing * 1.5));
  line-height: (ms(0) + ($form-spacing * 1.5)) - 1;
}

a.button.postfix,
a.button.prefix {
  padding-#{$default-float}: 0;
  padding-#{$default-opposite}: 0;
  text-align: center;
}

span.postfix,
span.prefix {
  background: darken($white, 5%);
  border: 1px solid darken($white, 20%);
}

.prefix {
  #{$default-float}: 2px;
  border-top-#{$default-float}-radius: 2px;
  border-bottom-#{$default-float}-radius: 2px;
  overflow: hidden;
}

.postfix {
  #{$default-opposite}: 2px;
  border-top-#{$default-opposite}-radius: 2px;
  border-bottom-#{$default-opposite}-radius: 2px;
}

input[type='date'],
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
textarea {
  background-color: $input-bg-color;
  font-family: inherit;
  border: $input-border-width $input-border-style $input-border-color;
  border-radius: $input-border-radius;
  color: $input-font-color;
  display: block;
  font-size: $input-font-size;
  margin: 0 0 $form-spacing;
  padding: ($form-spacing / 2);
  height: (ms(0) + ($form-spacing * 1.5));
  width: 100%;
  transition: all 0.15s linear;

  &.oversize {
    font-size: ms(1);
    padding: (($form-spacing - 4) / 2) ($form-spacing / 2);
  }

  &:focus {
    background: $input-focus-bg-color;
    border-color: $input-focus-border-color;
  }

  &[disabled] {
    background-color: #ddd;
  }
}

textarea {
  height: auto;
}

select {
  width: 100%;
}

// Fieldsets
fieldset {
  border: solid 1px #ddd;
  border-radius: $fieldset-border-radius;
  padding: 12px;
  margin: 18px 0;

  legend {
    font-weight: bold;
    background: $white;
    padding: 0 3px;
    margin: 0;
    margin-#{$default-float}: -3px;
  }
}

/* Errors */
.error input,
.error input:focus,
.error textarea,
.error textarea:focus,
input.error,
input.error:focus,
textarea.error,
textarea.error:focus {
  border-color: $alert-color;
  background-color: rgba($alert-color, 0.1);
}

.error input:focus,
.error textarea:focus,
input.error:focus,
textarea.error:focus {
  outline-color: lighten($alert-color, 30%);
}

.error label,
label.error {
  color: $alert-color;
}

.error small,
small.error {
  display: block;
  padding: 6px 4px;
  margin-top: -($form-spacing) - 1;
  margin-bottom: $form-spacing;
  background: $alert-color;
  color: $white;
  font-size: ms(0) - 2;
  font-weight: bold;
  border-bottom-#{$default-float}-radius: $input-border-radius;
  border-bottom-#{$default-opposite}-radius: $input-border-radius;
}

/* Correct FF custom dropdown height */
@-moz-document url-prefix() {
  form.custom div.custom.dropdown a.selector {
    height: 28px;
  }
}

.lt-ie9 form.custom div.custom.dropdown a.selector {
  height: 28px;
}
