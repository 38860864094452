//================================================
//=== SEARCH RESULTS =============================
//================================================



.search-results {
  margin: 0 !important;
}

.search-info {
  display: none !important;
}



.search-results .search-snippet-info {
  padding-left: 0;
}

.section-zoeken h1,
#search-api-page-search-form,
.search-api-page-results {
  // float: left;
  width: $row-width;
  margin: 0 auto;
  padding: 0 15px;
  @include breakpoint(medium){
    width: 100%;
  }
}
.section-zoeken h1{
  margin-top: 30px;
}

.search-result {
  // padding: 0 !important;
  background: #f2f3f4;
  padding: 15px !important;
  h1 {
    padding: 0;
    margin-top: 15px;
    font-size: 20px;
    color: $text-color;
  }
  .title{
    margin-top: 0;
  }
  .rtecenter {
    text-align: left !important;
  }
}
