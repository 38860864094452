//================================================
//=== AGENDA =======================================
//================================================
.agenda-overzicht .item {
  padding: 20px 0 0;
  margin: 20px 0 0;
  border-top: 1px solid #EFEFEF;

  .item:first-child {}

  h2 {
    margin-top: 0;
  }
}

.agenda-detail .datum,
.agenda-overzicht .datum {
  margin: 0 0 10px;
}

.agenda-detail .datum .datum-label,
.agenda-overzicht .datum .datum-label {
  font-weight: bold;
}

.agenda-widget {
  .widget-item {
    margin: 0 0 20px;
  }

  .agenda-title {
    font-weight: bold;
  }
}
