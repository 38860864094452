@mixin lees-meer {
  color: $white;
  font-size: 18px;
  font-family: 'basis';
  text-align: center;
  position: absolute;
  right: 30px;
  bottom: 30px;
}

@mixin breakpoint($value) {
  $point: map-get($breakpoints, $value);

  @if $point == '' {
    // If $point is still an empty string, no media query is needed
    @content;
  } @else {
    // Otherwise, wrap the content in a media query
    @media screen and (max-width: #{$point}) {
      @content;
    }
  }
}

@function convert-number-to-word($num) {
  $count: 1;
  @each $word in one, two, three, four, five, six, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen, eighteen, nineteen, twenty, twentyone, twentytwo, twentythree, twentyfour {
    @if ($count == $num) {
      @return $word;
    }
    $count: $count + 1;
  }
  @return 'invalid';
}

@mixin column($columns: $columns, $behavior: false) {
  @if $behavior == center {
    @extend %fl-n;
    @extend %c-base;

    margin-left: auto;
    margin-right: auto;
    width: grid-calc($columns, $total-columns);

    @include breakpoint(small) {
      float: none;
      width: auto;
    }
  } @else if $behavior == collapse {
    @extend %fl-l;
    @extend %c-base;

    padding: 0;
    width: grid-calc($columns, $total-columns);

    @include breakpoint(small) {
      float: none;
      width: auto;
    }
  } @else {
    @extend %fl-l;
    @extend %c-base;

    width: grid-calc($columns, $total-columns);

    @include breakpoint(small) {
      float: none;
      width: auto;
    }
  }
}

@mixin respond-to($media) {
  @if $media == smallScreen {
    @media only screen and (max-width: $screen-small - 1) {
      @content;
    }
  } @else if $media == mediumScreen {
    @media only screen and (max-width: $screen-medium) and (min-width: $screen-small) {
      @content;
    }
  } @else if $media == largeScreen {
    @media only screen and (min-width: $screenXlarge) {
      @content;
    }
  }
}

// @mixin clearfix() {
//   zoom: 1;
//
//   &:after,
//   &:before {
//     content: ' ';
//     display: table;
//   }
//
//   &:after {
//     clear: both;
//   }
// }

// Grid Calculation for Percentages
@function grid-calc($colNumber, $total-columns) {
  @return percentage($colNumber / $total-columns);
}

// Create CSS Triangles in a Breeze
@mixin cssTriangle($triangleSize, $triangleColor, $triangleDirection) {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: solid $triangleSize;
  @if ($triangleDirection == top) {
    border-color: $triangleColor transparent transparent transparent;
  }
  @if ($triangleDirection == bottom) {
    border-color: transparent transparent $triangleColor transparent;
  }
  @if ($triangleDirection == left) {
    border-color: transparent transparent transparent $triangleColor;
  }
  @if ($triangleDirection == right) {
    border-color: transparent $triangleColor transparent transparent;
  }
  // .example { @include cssTriangle(5px, $white, bottom); }
}

@mixin transition($properties...) {
  transition-property: $properties;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-easing;
}
